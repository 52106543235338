import React, { Component } from 'react'
import { Input, Button, Image, Tag, Modal, 
     message, Steps } from 'antd'
import { jobDetail, closeJob, openJob } from '../../../api'
import { SET_USER } from '../../../utils/constant'
import Qrcode from 'qrcode-react'


import "./index.less"

//let createHistory = require('history').createBrowserHistory
const { confirm } = Modal

let buttons = ''

//const history = createHistory()
const logo = 'http://img.fanwoon.com/qr_logo.png'
const jobPic = 'https://img.fanwoon.com/guide_new.png'
const avatar = 'http://img.fanwoon.com/avatar.png'
class JobDetail extends Component {

    constructor () {
        super(...arguments)
        this.state = {
            job: {}, //职位信息
            visible: false, //是否显示分享弹框
            sharePath: '',
            user: {}
        }
    }

    componentWillMount() {
        this.init()
    }


    //打开分享弹框
    onOpenShare (id) {
        let path = `https://qr.tueneitui.com/jobId?jobId=${id}`
        this.setState({ visible: true, sharePath: path })
    }

    //关闭分享弹框
    handleCancel = () => {
        this.setState({
          visible: false,
          sharePath: ''
        })
    }

    async init () {
        let { id } = this.props.match.params
        let res = await jobDetail({data: id})
        if (res.data.recommendRewardRole) {
            res.data.recommendRewardRole = JSON.parse(res.data.recommendRewardRole)
        }
        if (res.data.highlights) {
            res.data.highlights = JSON.parse(res.data.highlights)
        }
        this.setState({ job: res.data})
        this.toolbar(res.data)
       this.onOther()
    }


    onOther () {
        let res = sessionStorage.getItem(SET_USER)
        if (res) {
            res = JSON.parse(res)
            this.setState({user: res})
        }
    }
    
    //返回
    onBack() {
        window.location.href = '/job'
    }

    //打开关闭职位弹框
    handleOpenCloseModal = (id) => {
        confirm({
            title: '关闭职位后，将无法接受投递的简历',
            content: '已经收到的简历将不受影响，你还要继续吗？',
            cancelText: '取消',
            okText: '确认',
            onOk: () => {
                this.handleCloseJob(id)
            }
        })
    }

    //关闭职位
    async handleCloseJob (id) {
        let { code } = await closeJob({data: id})
        if (code === 0) {
            message.success('职位关闭成功!') 
            let timeout = setTimeout(() => {
                clearTimeout(timeout)
                timeout = null
                this.onBack() 
            }, 1000)
        } else {
            message.error('职位关闭失败!')
        }
    }

    //编辑页面
    onEditeJob (id) {
        window.location.href = `/publish/${id}`
    }

    async handleOpenJob (id) {
        let { code } = await openJob({data: id})
        if (code === 0) {
            message.success('职位开启成功!') 
            let timeout = setTimeout(() => {
                clearTimeout(timeout)
                timeout = null
                this.onBack() 
            }, 1000)
        } else {
            message.error('职位开启失败!')
        }
    }

    handleOpenJobModal = () => {
        let { job } = this.state
        confirm({
            title: '开启职位后，求职者将可以投递简历',
            cancelText: '取消',
            okText: '确认',
            onOk: () => {
               this.handleOpenJob(job.id)
            }
        })
    }

    toolbar (job) {
        let localUser = sessionStorage.getItem(SET_USER)
        localUser = JSON.parse(localUser)
        
        if (localUser.system && job.status === 1) { //管理员招聘中
            buttons = (
                <div>
                        <Button onClick={this.onOpenShare.bind(this, job.id)}>分享职位</Button>
                        <Button className='ml10' onClick={this.onEditeJob.bind(this, job.id)}>编辑</Button>
                        <Button className='ml10' onClick={this.handleOpenCloseModal.bind(this, job.id)}>关闭</Button>
                        <Button className='ml10' onClick={this.onBack}>返回</Button>
                </div>
            )
        } else if (localUser.system && job.status === 0) { //管理员已关闭
            buttons = (
                <div>
                         <Button className='ml10'onClick={this.handleOpenJobModal.bind(this)}>开启</Button>
                        <Button className='ml10' onClick={this.onBack}>返回</Button>
                </div>
            )
        } else if (!localUser.system && job.status === 1 && localUser.id === job.publishId){
            buttons = (
                <div>
                        <Button onClick={this.onOpenShare.bind(this, job.id)}>分享职位</Button>
                        <Button className='ml10' onClick={this.onEditeJob.bind(this, job.id)}>编辑</Button>
                        <Button className='ml10' onClick={this.handleOpenCloseModal.bind(this, job.id)}>关闭</Button>
                        <Button className='ml10' onClick={this.onBack}>返回</Button>
                </div>
            )
        } else if (!localUser.system && job.status === 1 && localUser.id !== job.publishId){
            buttons = (
                <div>
                    <Button onClick={this.onOpenShare.bind(this, job.id)}>分享职位</Button>
                    <Button className='ml10' onClick={this.onBack}>返回</Button>
                </div>
            )
        } else if (!localUser.system && job.status === 0 && localUser.id === job.publishId){
            buttons = (
                <div>
                     <Button className='ml10' onClick={this.handleOpenJobModal.bind(this)}>开启</Button>
                     <Button className='ml10' onClick={this.onBack}>返回</Button>
                </div>
            )
        } else if (!localUser.system && job.status === 0 && localUser.id !== job.publishId){
            buttons = (
                <div>
                     <Button className='ml10' onClick={this.onBack}>返回</Button>
                </div>
            )
        }
    }

    moneys (money , money2) {
        if (money && money2)  return Number(money) + Number(money2)
        if (!money2) return 0
        return Number(money2)
    } 

    render() {
        const { job, visible, sharePath, user } = this.state
    
        return (
           <div className="job-detail-box">
               <div className='job-detail-header'>
                    <div className='left-box'>
                        <div>
                            <span className='job-name'>{job.name}</span>
                            <span className="job-money">{job.salaryMin}k-{job.salaryMax}K</span>
                            {
                                !job.recommendReward ? <span className="push-box">{job.recommendReward ? '有内推奖金' : '无内推奖金'}</span> :
                                (
                                    <span className="tooltip-box">
                                        <span className="push-box">有内推奖金</span>
                                        <div className="tooltip-content-box">
                                            <div className="title">每成功推荐一名应聘者:</div>
                                            <div className="push-money-box">
                                                <Steps size="small" current={4} >
                                                    <Steps.Step title="待筛选简历" description={`￥${!job.recommendRewardRole[0] ? 0 : job.recommendRewardRole[0]}`} />
                                                    <Steps.Step title="面试中" description={`￥${!job.recommendRewardRole[200] ? 0 : job.recommendRewardRole[200]}`} />
                                                    {/* <Steps.Step title="接受offer" description={`￥${!job.recommendRewardRole[300] ? 0 : job.recommendRewardRole[300]}`} /> */}
                                                    <Steps.Step title="入职报到" description={`￥${this.moneys(job.recommendRewardRole[300], job.recommendRewardRole[400])}`} />
                                                </Steps>
                                            </div>
                                            <div className="important-font">内推奖金规则由您所在的企业制定，且奖金由企业发放，与平台无关</div>
                                        </div>
                                    </span>
                                )
                            }
                        </div>
                        <div className="mt10">
                            <Tag>{job.city}-{job.district}</Tag>
                            <Tag>{job.education === '不限' ? '学历不限' : job.education}</Tag>
                            <Tag>{job.workExperienceName === '不限' ? '经验不限' : job.workExperienceName}</Tag>
                            <span className={job.status === 1 ? 'state-green' : 'state-gray'}></span>
                            <span>{job.status === 1 ? '招聘中' : '已关闭'}</span>
                            <span className="pushlish-time-box">发布时间:{job.createTime}</span>
                        </div>
                        <div className="mtb10">
                            <span className="label-mark">职位类别：</span>
                            <span>{job.function}</span>
                        </div>
                        <div>
                            <span className="label-mark">提成比例：</span>
                            <span>{!job.commission ? '暂无' : job.commission}</span>
                        </div>
                    </div>
                    <div className='right-box'>
                        {
                            !job.talentInfo ? null : 
                            (
                                <div className='left-item'>
                                    <div>发布人：</div>
                                    <div className='publish-info-box'>
                                        <Image className='pic' src={!job.talentInfo.imgUrl ? avatar : job.talentInfo.imgUrl} />
                                        <div>
                                            <div>{job.talentInfo.name}</div>
                                            <div>{job.talentInfo.intro}</div>
                                        </div>
                                    </div>
                                </div>
                            ) 
                        }
                        
                        <div className='right-item'>
                            {buttons}
                        </div>
                    </div>
               </div>
               <div className='job-detail-content'>
                    <div >
                            <span className="label-mark">职位亮点：</span>
                            {
                                !job.highlights ? '暂无' : job.highlights.map((item, index) => {
                                    return <Tag key={index}>{item}</Tag>
                                })
                            }
                            
                        </div>
                        <div className="mt30">
                            <div className="label-mark">职位描述</div>
                            <div>
                                <Input.TextArea value={job.description} disabled className="des-box" bordered={false} />
                            </div>
                        </div>
               </div>

               <Modal
                    title="将职位分享同事，让他们帮你内推吧！"
                    visible={visible}
                    footer={null}
                    onCancel={this.handleCancel.bind(this)}
                    >
                    <div className='share-box'>
                        <div>
                            <Qrcode
                                size={150}
                                value={sharePath}
                                logo={logo}
                                logoWidth={50}
                                logoHeight={50}
                            />
                            <div>步骤一：打开微信扫一扫</div>
                        </div>
                        <div>
                            <Image className='job-pic' src={jobPic} />
                            <div>在你的公司主页，点击</div>
                        </div>
                    </div>
                </Modal>

           </div>
        )
    }
}

export default JobDetail