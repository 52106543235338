import React, { Component } from 'react'
import { Tabs, Button, Input, Checkbox, Pagination, Modal, Image } from 'antd'
import JobList from '../../components/JobList'
import { listOpenLikeName, listCloseLikeName } from '../../api'
import { SET_USER } from '../../utils/constant'
import Qrcode from 'qrcode-react'

import './index.less'

const logo = 'https://img.fanwoon.com/new_logo_small.png'
const job = 'https://img.fanwoon.com/guide_new.png'
const company = 'https://img.fanwoon.com/guide_new.png'

const { TabPane } = Tabs
const { Search } = Input

class Job extends Component {

    constructor(props) {
        super(props)
        this.state = {
            jobList: [],
            searchName: "",// 招聘中搜索名称
            onself: false, //是否只看自己
            closeJobList: [],
            defaultTabKey: 1,
            register: {
                pageNum: 1,
                pageSize: 10,
                totalPage: 0,
                totalRecord: 0
            },
            visible: false,
            visible2: false,//企业
            sharePath: 'https://qr.tueneitui.com',
            noAnything: false, //是否标示招聘中的职位是点击按钮查询不到数据的
        }
        this.handleListOpen = this.handleListOpen.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount() {
        this.handleListOpen()
    }

    handleChangeTabs = (index) => {
        Number(index) === 1 ? this.handleListOpen() : this.handleListCloseLikeName()
        this.setState({ 
            defaultTabKey: Number(index), 
            onself: false,
            register: {
                pageNum: 1,
                pageSize: 10,
                totalPage: 0,
                totalRecord: 0
            }
         })
    }

    handleGoPublish = () => {
        this.props.history.push({ pathname: '/publish' })
    }

    //招聘中分页查询
    handleChange(current, pageSize) {
        window.scrollTo(0,0)
        let { searchName, onself, defaultTabKey } = this.state

        let params = {}
        if (searchName) {
            params["name"] = searchName
        }
        if (onself) {
            params["oneself"] = 1
        }
        defaultTabKey === 1 ? this.handleListOpen(pageSize, (current - 1) * pageSize, params) :
        this.handleListCloseLikeName(pageSize, (current - 1) * pageSize, params)
    }

    //已关闭职位
    async handleListCloseLikeName(limit = 10, start = 0, params = {}) {
        let pageNum = window.localStorage.getItem('pageNum')
        
        if (pageNum) {
            start = (Number(pageNum) - 1) * 10;
        }
        let res = await listCloseLikeName({ limit, start, data: params });

        if (pageNum) {
            window.localStorage.removeItem('pageNum')
        }

        this.setState({ 
            closeJobList: res.data,
            register: {
                pageNum: res.pageNum,
                pageSize: res.pageSize,
                totalPage: res.totalPage,
                totalRecord: res.totalRecord
            }
         })
    }

    async handleListOpen(limit = 10, start = 0, params = {}) {
        let pageNum = window.localStorage.getItem('pageNum')
        
        if (pageNum) {
            start = (Number(pageNum) - 1) * 10
        }

        let res = await listOpenLikeName({ limit, start, data: params })

        if (pageNum) {
            window.localStorage.removeItem('pageNum')
        }

        this.setState({
            jobList: res.data,
            register: {
                pageNum: res.pageNum,
                pageSize: res.pageSize,
                totalPage: res.totalPage,
                totalRecord: res.totalRecord
            }
        })
    }

    //关闭分享弹框
    handleCancel = () => {
        this.setState({
          visible: false,
          sharePath: 'https://qr.tueneitui.com'
        })
    }

    handleCancel2() {
        this.setState({
            visible2: false,
            sharePath: 'https://qr.tueneitui.com'
          })
    }

    //打开分享弹框
    onOpenShare (id) {
        //let path = `${sharePath}&action=${id}` //+ id 
        let path = 'https://qr.tueneitui.com/jobId?jobId=' + id //`https://qr.tueneitui.com/jobId?jobId=${id}`
        this.setState({ visible: true, sharePath: path })
    }

    //打开分享公司二维码
    onOpenShareCompany() {
        let user = sessionStorage.getItem(SET_USER)
        user = JSON.parse(user)
        //let path = `${sharePath}&action=${id}` //+ id
        let path = `https://qr.tueneitui.com/companyId?companyId=${user.companyId}` //`https://qr.tueneitui.com/companyId?companyId=${user.companyId}&ux=${user.id}`
        this.setState({ visible2: true, sharePath: path })
    }

    //招聘中职位搜索
    onSearch (v) {
        let { onself, defaultTabKey } = this.state
        let params = { name:  v}
        if (onself) {
            params['oneself'] = 1
        }

        defaultTabKey === 1 ? this.setState({noAnything: true}) : this.setState({noAnything: false})

        defaultTabKey === 1 ? this.handleListOpen(10, 0, params) : 
        this.handleListCloseLikeName(10, 0, params)
    }

    //只看自己招聘的职位 - 招聘中
    onSelfList(e) {
        let { checked } = e.target
        this.setState({onself: checked})

        let { searchName, defaultTabKey } = this.state 
        let param =  { }
        if (checked) {
            param["oneself"] = 1
        }
        if (searchName) {
            param["name"] = searchName
        }

        defaultTabKey === 1 ? this.handleListOpen(10, 0, param) :
        this.handleListCloseLikeName(10, 0, param)
    }

    //改变输入框的值
    onChangeSearch(e) {
        this.setState({ searchName:  e.target.value})
    }

    render() {
        const { jobList, defaultTabKey, closeJobList, register,
              visible, visible2, sharePath, noAnything } = this.state
        return (
            <div className='job-page'>
                <div className='push-job-btn'>
                    <Button type='primary' onClick={this.handleGoPublish}>发布职位</Button>
                </div>

                <Tabs defaultActiveKey="1" onChange={this.handleChangeTabs}>
                    <TabPane tab="招聘中" key="1"></TabPane>
                    <TabPane tab="已关闭" key="2"></TabPane>
                </Tabs>
                <div className='job-tool-bar'>
                    {
                        defaultTabKey === 1 ?  <div><Button onClick={this.onOpenShareCompany.bind(this)}>分享职位</Button></div> : null
                    }
                    
                     <div className="search-input-box">
                             <Search
                                placeholder="请输入职位名称查询"
                                enterButton="查询"
                                onBlur={this.onChangeSearch.bind(this)}
                                onSearch={this.onSearch.bind(this)}
                             />
                        </div>
                     <div>
                         <Checkbox onChange={this.onSelfList.bind(this)}>只看我创建的职位</Checkbox>
                     </div>
                </div>
                {
                    defaultTabKey === 1 ? 
                    (
                        <div>
                           
                             <div>
                                {
                                    jobList.length >= 1 ?
                                        jobList.map((item, index) => {
                                            return <JobList parent={this} data={item} key={index} defaultTabKey={defaultTabKey} />
                                        })
                                        :
                                        noAnything && jobList.length === 0 ? (
                                            <div className="close-job-none-box">
                                                <Image className="pic" src="http://img.fanwoon.com/nojob.png" />
                                                <div>暂无数据</div>
                                            </div>
                                        ) :(
                                            <div className='no-job-list-box'>
                                                <div className='no-job-font'>您公司还未发布任何职位</div>
                                                <Button type='primary' onClick={this.handleGoPublish}>+ 发布岗位 快速招聘</Button>
                                            </div>
                                        )
                                        
                                }
                               

                            </div>
                        </div>
                    ) : (
                        <div>
                            {
                                closeJobList.length >= 1 ?
                                    closeJobList.map((item, index) => {
                                        return <JobList parent={this} data={item} key={index} defaultTabKey={defaultTabKey} />
                                    })
                                    : (
                                        <div className="close-job-none-box">
                                            <Image className="pic" src="http://img.fanwoon.com/nojob.png" />
                                            <div>暂无数据</div>
                                        </div>
                                    )
                            }
                        </div>
                    )
                }

                
                        {
                                    register.totalRecord > 10 ?
                                        <div className='pagination-box'>
                                            <Pagination
                                                total={register.totalRecord}
                                                showSizeChanger
                                                showQuickJumper
                                                showTotal={total => `共计 ${total} 条数据`}
                                                onChange={this.handleChange}
                                                current={register.pageNum}
                                            />
                                        </div> : null
                                }

                        

                <Modal
                    title="将职位分享同事，让他们帮你内推吧！"
                    visible={visible}
                    footer={null}
                    onCancel={this.handleCancel.bind(this)}
                    >
                    <div className='share-box'>
                        <div>
                            <Qrcode
                                size={150}
                                value={sharePath}
                                logo={logo}
                                logoWidth={50}
                                logoHeight={50}
                            />
                            <div>步骤一：打开微信扫一扫</div>
                        </div>
                        <div>
                            <Image className='job-pic' src={job} />
                            <div>第二步，点击「邀请朋友」</div>
                        </div>
                    </div>
                </Modal>

                <Modal
                    title="将职位分享同事，让他们帮你内推吧！"
                    visible={visible2}
                    footer={null}
                    onCancel={this.handleCancel2.bind(this)}
                    >
                    <div className='share-box'>
                        <div>
                            <Qrcode
                                size={150}
                                value={sharePath}
                                logo={logo}
                                logoWidth={50}
                                logoHeight={50}
                            />
                            <div>步骤一：打开微信扫一扫</div>
                        </div>
                        <div>
                            <Image className='job-pic' src={company} />
                            <div>第二步，点击「邀请朋友应聘」</div>
                        </div>
                    </div>
                </Modal>

            </div>
        )
    }
}

export default Job