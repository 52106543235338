import React, { Component } from 'react'
import { Form, Input, Button, message, Modal, Image } from 'antd'
import { accountLogin, accountSmsLogin, checkRegisterPhone } from '../../api'
import Register from '../../components/Register'
import Vertico from '../../components/Vertico'
import { SET_USER } from '../../utils/constant'
import { ExclamationCircleOutlined } from '@ant-design/icons'

const { confirm } = Modal


class Login extends Component {

    formRef = React.createRef()

    constructor(props) {
        super(props)
        //!@#123QWEasd
        this.state = {
            companyList: [],
            switchPwdInput: false,
            visible: false,
            showInput: false,
            showVertico: false, //显示图像验证码
            phone: '', //临时存储用户输入的手机号
            from: 0, // 0 默认登录弹框，1注册弹框
            timer: 30,
            showCode: true,
            showCode2: true,
        }
        this.handleSwitchPwdInput = this.handleSwitchPwdInput.bind(this)
        this.onFinish = this.onFinish.bind(this)
        this.handleShowImgCode = this.handleShowImgCode.bind(this)
        this.handleHideImgCode = this.handleHideImgCode.bind(this)
    }

    handleTimes () {
        let { visible } = this.state 
        let siv = window.setInterval(() => {
            this.setState({
                timer: (this.state.timer -1)
            }, () => {
                if (this.state.timer === 0) {
                    window.clearInterval(siv)
                    siv = null
                    let obj = { timer: 30 }
                    visible ? obj.showCode2 = true : obj.showCode = true

                    this.setState(obj)
                }
            })
        }, 1000)
    }

    handleSwitchPwdInput() {
        this.setState({
            switchPwdInput: !this.state.switchPwdInput
        })
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = e => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = () => {
        this.setState({ visible: false })
    }

    onFinish(values) {
        let { switchPwdInput } = this.state
        !switchPwdInput ? this.handleLoginByPhone(values) :
            this.handleAccountLogin(values)
    }

    showConfirm () {
        confirm({
            title: '该手机还未注册',
            icon: <ExclamationCircleOutlined />,
            content: '是否需要去注册该账号？',
            okText: '免费注册',
            cancelText: '取消',
            onOk: () => {
                this.showModal()
            }
          })
    }

    /**
     * @description 手机 + 短信验证码登录
     */
    async handleLoginByPhone(values) {
        let res = await accountSmsLogin(values)
        if (res.code !== 0) {
            message.error(res.message)
            return
        }
        message.success('登陆成功!')
            sessionStorage.setItem(SET_USER, JSON.stringify(res.data))
            let timeout = setTimeout(() => {
                clearTimeout(timeout)
                timeout = null
                window.location.href = '/job'
            }, 1000)
    }

    /**
     * @description 账号 + 密码登录
     */
    async handleAccountLogin(values) {
        
        //检测是否注册
        let res = await checkRegisterPhone({ data: values.account })
        if (res.data === 0 ) {
            this.showConfirm()
            return
        }
        let { data } = await accountLogin(values)
        if (!data) {
            message.error('账号或密码有误')
            return
        }
        message.success('登陆成功!')
        sessionStorage.setItem(SET_USER, JSON.stringify(data))
        let timeout = setTimeout(() => {
            clearTimeout(timeout)
            timeout = null
            window.location.href = '/job'
        }, 1000)
    }

    handleShowImgCode() {
        let phone = this.formRef.current.getFieldValue('phone')
        if (!phone) {
            message.error('请输入手机号!')
            return
        }

        this.handleCheckRegisterPhone(phone)

       // 
    }

    //检测手机号是否注册
    async handleCheckRegisterPhone(e) {
        let res = await checkRegisterPhone({ data: e })
        //let { code, message, data } = await checkRegisterPhone({ data: e.target.value })
        if (res.data === 0 ) {
            this.showConfirm()
            return
        }
        if (res.code !== 0) {
            return message.error(res.message)
        }
        if (res.data === 1) {
            this.setState({showVertico: true, e, phone: e})
        }
        
    }



    
    handleHideImgCode() {
        this.setState({showVertico: false, phone: ''})
    }

    handleShowCodeModal (num, phone) {
        this.setState({showVertico: true, from: num, phone })
    }
    handleRegi () {
        this.handleTimes()
    }

    render() {

        const { switchPwdInput, visible, showVertico, phone, from } = this.state
        

        return (
           <div className="hide-box" style={{paddingTop: '-60px'}}>
               {/* <div className="flex-box header-box">
                    <img alt="" className="logo" src="https://img.fanwoon.com/new_logo.png" />
                    <ul className="menu">
                        <li><a href="/index">首页</a></li>
                        <li><a href="/jobList">热招职位</a></li>
                        <li><a href="/contant">联系我们</a></li>
                        <li><a href="/templates">下载简历模板</a></li>
                        <li><a href="/login">登录注册</a></li>
                    </ul>
                </div> */}
                <div className='login-page'>
                    <img alt="" className='logo1' src="https://img.fanwoon.com/wave.png" />
                    <img alt="" className='logo2' src="https://img.fanwoon.com/hello.png" />
                    <img alt="" className='logo3' src="https://img.fanwoon.com/wave2.png" />
                    <div className='login-form-box'>
                        <div className="logo-box-header">
                                <img  alt="" className='oms-box' src="https://img.fanwoon.com/new_logo.png" />
                        </div>
                            <div className='oms-form-sub-box'>
                                <Form
                                    ref={this.formRef}
                                    onFinish={this.onFinish}>
                                    {
                                        !switchPwdInput ?
                                            <div>
                                                <div className='label-mark'>手机号码</div>
                                                <Form.Item
                                                    name='phone'
                                                    rules={[{ required: true, message: '请输入手机号!' }]}>
                                                    <Input placeholder='请输入手机号' />
                                                </Form.Item> 
                                            </div>
                                            :
                                            <div>
                                                <div className='label-mark'>手机号码</div>
                                                <Form.Item
                                                    name='account'
                                                    rules={[{ required: true, message: '请输入账号!' }]}>
                                                    <Input placeholder='请输入账号' />
                                                </Form.Item>
                                            </div>
                                    }
                                    {
                                        !switchPwdInput ?
                                            <div>
                                                <div className='label-mark'>验证码</div>
                                                <div className='address-local-box'>
                                                    <div className='code-input-box'>
                                                        <Form.Item name='verify'>
                                                            <Input className='ver-input' placeholder='验证码' />
                                                        </Form.Item>
                                                    </div>
                                                    <div>
                                                        {
                                                            this.state.showCode ? <Button className='vercode-btn' onClick={this.handleShowImgCode}>获取验证码</Button> :
                                                            <Button className='vercode-btn non-btn'><span id="serveTime">{this.state.timer}</span>秒后获取验证码</Button>
                                                        }
                                                    </div>
                                                </div>  
                                            </div>
                                            
                                            :
                                        <div>
                                                <div className='label-mark'>密码</div>
                                                <Form.Item
                                                    name='password'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '请输入密码!',
                                                        },
                                                    ]}>
                                                    <Input.Password placeholder='请输入密码' />
                                                </Form.Item>
                                        </div>
                                    }
                                    <div className='flex-box pwd-box-taggle'>
                                        <div className='cursor'></div>
                                        <div className='cursor switch-font' onClick={this.handleSwitchPwdInput}>
                                            {
                                                !switchPwdInput ? '切换密码登录' : '手机号码'
                                            }
                                        </div>
                                    </div>
                                    <Form.Item>
                                        <Button className='login-form-btn' htmlType="submit">
                                            登录
                                        </Button>
                                    </Form.Item>
                                    <div className='queck-btn'  onClick={this.showModal}>注册翻腕，快速招人</div>
                                    <div className='agreement-box'>
                                        <span>登录即代表您同意<a href='/areement'>《翻腕服务协议》</a></span>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    {
                        visible ? <Register parent={this} visible={visible}  /> : null
                    }
                    {
                        showVertico ? <Vertico from={from} parent={this} isRegister={visible} visible={showVertico} phone={phone} /> : null
                    }
                    <div className='footer-box-min'>
                        <div className='footer-container'>
                            <div>Copyright © 2022 翻腕   </div>
                            <div><a href='https://beian.miit.gov.cn/'>沪ICP备2020029704号-1</a></div>
                            <div>
                                <img alt="" className='beian-icon' src='https://img.fanwoon.com/police.png' />
                                <a target="_blank" className='normal-a' href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011302007180">沪公网安备 31011302007180号</a>
                            </div>
                        </div>
                    </div>
                    
                </div>
           </div> 
        )
    }
}

export default Login;