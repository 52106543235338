import React, { useState } from 'react'
import {  Modal } from 'antd'

import './cashier.less'

const ButtonWidget = (props) => {
    const EnoughButton = () => {
        let money =  Number(props.fixMoney) + Number(props.addUp)

        if (props.from === 'first' && Number(props.balance) < Number(money)) {
           return <button className='subBtn' onClick={
                () => window.location.href ='/amount'
            }>余额不足去充值</button>

        } else if (props.from === 'first' &&  Number(props.balance) > Number(money)) {
            return <button className='subBtn' onClick={() => props.onSubmit()}>确认付款</button>
        } else if (Number(props.balance) < Number(props.addUp)) {
            return  <button className='subBtn' onClick={
                () => window.location.href ='/amount'
            }>余额不足去充值</button>

        } 

        return <button className='subBtn' onClick={() => props.onSubmit()}>{props.advance ? '确认到岗，发放赏金' : '确认到岗，不打赏'}</button>
    }

    return (
        <div className='footerWrap'>
            <div className='footerBox'>
                <button className='cannelBtn' onClick={() => props.onCancel()}>取消</button> 
                <EnoughButton />
                {/* {
                      Number(props.balance) < Number(props.addUp) ? 
                      <button className='subBtn' onClick={
                            () => window.location.href ='/amount'
                        }>余额不足去充值</button> :
                        props.from === 'first' ? <button className='subBtn'>确认付款</button> : 
                        <button className='subBtn'>{props.advance ? '确认到岗，发放赏金' : '确认到岗，不打赏'}</button>
                } */}
            </div>
        </div>
    )
}

const DeductionWidget = (props) => {

    const deductionArr = new Map([
        [0, ()=> (
            {
                id: Symbol(), 
                icon: 'https://img.fanwoon.com/cashier_6.png',
                des: '推荐赏金将发放给简历的推荐人，有助于促使更多的人为你推荐员工，并提升职位曝光',
            }
        )],
        [1, ()=> (
            {
                id: Symbol(), 
                icon: 'https://img.fanwoon.com/cashier_7.png',
                des: '不发放推荐奖金，将降低您的职位曝光，也将大大降低他人为您推荐员工的意愿',
            }
        )]
    ])
   const radioArry = ['https://img.fanwoon.com/cashier_5.png', 'https://img.fanwoon.com/cashier_5_1.png'] 

    const  [checkVal, setCheckVal] = useState(0)
    const getInfo = (num) => deductionArr.get(num)()

    const onToggle = () => {
        setCheckVal(() => checkVal === 0 ? 1 : 0)
        props.onToggle(checkVal)
    }

    return (
        <div className={`defaultCashierBox`}>
            <div className={`headerCashierBox`}>
                <div className='cashierMidelBox'>
                    <img className='iconMindBox' alt='' src="https://img.fanwoon.com/cashier_4.png"/>
                    <div>
                        <div className='sloganBox'>发放推荐奖金</div>
                        <div className='defaultMoneyBox'>
                            <span className='activeMoneyNumber'>¥{props.addUp}</span>
                        </div>
                    </div>
                </div>
                <img className='smallIcon' src={radioArry[checkVal]} alt='' onClick={()=> onToggle()} />
            </div>
            <div className={`descriptionBox ${checkVal === 1 ? 'importDesc': 'yesDesc'}`}>
                <img className='smallMidel' src={getInfo(checkVal).icon} alt='' />
                <div className={`desbox ${checkVal === 1 ? 'desImp': ''}`}>{ getInfo(checkVal).des }</div>
            </div>
        </div>
    )
}

const Index = (props) => {
    console.log(props.addUp(),'哈哈')
    const [fixMoney] = useState(() => props.from === 'first' ? 7.99 : 0)
    const [addUpMoney] = useState(props.addUp()) //预扣金额
    const [fixedAddUpMoney, setFixedAddUpMoney] = useState(props.addUp())
    const [countMoney, setCountMoney] = useState(Number(fixMoney) + Number(addUpMoney)) //合计
    const [advance, setAdvance] = useState(true)

    const onTaggleAdvanceMoney = (num) => {
        //setAddUpMoney(() => num === 1 ? Number(props.addUp()).toFixed(2) : Number(0).toFixed(2))
        setFixedAddUpMoney(() => num === 1 ? Number(props.addUp()).toFixed(2) : Number(0).toFixed(2))
        setAdvance(() => num === 1 ? true : false)
        setCountMoney(() => num === 1 ? Number(Number(countMoney) + Number(props.addUp())).toFixed(2) 
        : Number(Number(countMoney) - Number(props.addUp())).toFixed(2) )
    }

    return (
        <div className='cashierModal'>
            <Modal  
                visible={props.show}
                footer={null}
                closable={false}
                width="400px">
                <div className='cashierWrap'>
                    <ul className='listBox'>
                        <li className='listItemBox'>
                            <img className='iconBox' alt='' src="https://img.fanwoon.com/cashier_1.png" />
                            <span className='itemTitle'>{ props.from === 'first' ? '邀约面试扣款' : '确认到岗' }</span>
                        </li>
                        {
                             props.from === 'first' ? (
                                <li>
                                    <div className={`headerCashierBox defaultCashierBox`}>
                                        <div className='cashierMidelBox'>
                                            <img className='iconMindBox' alt='' src="https://img.fanwoon.com/cashier_2.png" />
                                            <div>
                                                <div className='sloganBox'>邀约面试-解锁电话号码</div>
                                                <div className='defaultMoneyBox'>
                                                    <span className='defaultMoneyNumber'>¥12.00</span>
                                                    <span className='activeMoneyNumber'>¥{fixMoney}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <img className='iconMindBox' alt='' src="https://img.fanwoon.com/cashier_3.png" />
                                    </div>
                                </li>
                             ) : null
                        }
                        {
                             props.data.recommendReward && props.data.recommendRewardRole ? (
                                <li>
                                    <DeductionWidget 
                                        addUp={addUpMoney} 
                                        onToggle={(e) => onTaggleAdvanceMoney(e)} 
                                    />
                                </li>
                             ) : null
                        }

                        {
                            props.from === 'first' ? (
                                <li className={`flexSpaceBetween ptb40`}>
                                    <span>直接扣款:</span>
                                    <span>¥7.99</span>
                                </li>
                            ) : null
                        }
                        
                        
                        {
                             props.from === 'first' && props.data.recommendReward && props.data.recommendRewardRole ? (
                                <li className='pd5'>
                                    <div className={`flexSpaceBetween`}>
                                        <div className='tipBoxHeader'>
                                            <span>预扣:</span>
                                            <img className='tipIcon' src="https://img.fanwoon.com/cashier_8.png" alt='' />
                                        </div>
                                        <span>¥ {advance ? addUpMoney : 0}</span>
                                    </div>
                                    <div className='tipBox'>
                                        “预扣”指先支付给平台，淘汰后返还金额至您的余额，入职则将奖金发放给推荐者，若您没有操作简历，21天后将根据简历状态自动结算
                                    </div>
                                </li>
                             ) : null
                        }

                        { props.from === 'first' ?  <li className='lineBox'></li> : null }
                        
                        {
                            props.from === 'last' ? (
                                <li className={`totalBox `}>
                                    <span>扣款后余额:</span>
                                    <div className='totalMoneyBox'>
                                        <div className='totalMoney'>
                                        { Number(props.balance).toFixed(2) } - { Number(fixedAddUpMoney).toFixed(2)} = 
                                        { Number(Number(props.balance) - Number(fixedAddUpMoney)).toFixed(2) }
                                        </div>
                                    </div>
                                </li>
                            ) : null
                        }
                       
                        {
                            props.from === 'first' ? (
                                <li className='totalBox'>
                                    <span>合计:</span>
                                    <div className='totalMoneyBox'>
                                        <div className='totalMoney'>¥
                                        {Number(countMoney).toFixed(2)} 
                                        </div>
                                        <div>
                                            支付后余额 
                                            <span>
                                            { Number(props.balance).toFixed(2) } - {Number(Number(fixMoney) + Number(fixedAddUpMoney)).toFixed(2)} = 
                                            {
                                                Number(Number(props.balance) - Number(Number(fixMoney) + Number(fixedAddUpMoney))).toFixed(2)
                                            }
                                            </span>
                                        </div>
                                    </div>
                                </li>
                            ) : null
                        }

                        
                    </ul>
                    <ButtonWidget 
                        balance={props.balance}
                        addUp={fixedAddUpMoney} 
                        from={props.from}
                        fixMoney={fixMoney}
                        advance={advance}
                        onCancel={() => props.onCancel()} 
                        onSubmit={() => props.onSubmit(advance)}
                        />
                </div> 
            </Modal>
        </div>
    )
}

export default Index