import React, { Component } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { SET_USER } from '../utils/constant'
import Account from '../views/account'
import Company from '../views/company'
import Login from '../views/system/login'
import EditeCompany from '../views/company/edite'
import Job from '../views/job'
import Publish from '../views/job/publish'
import Introversion from '../views/Introversion'
import Follow from '../views/follow'
import Bole from '../views/bole'
import JobDetail from '../views/job/detail'
import Candidate from '../views/candidate'
import Agreement from '../views/agreement'

import Index from '../views/home'
import Jobs from '../views/home/jobs'
import Contant from '../views/home/contant'
import Templates from '../views/home/templates'
import Info from '../views/home/info'
import Amount from '../views/amount/index'
import Recharge from '../views/amount/recharge'
import Refund from '../views/amount/refund'
import Downloads from '../views/download/index'
import Flai from '../views/flai/index'

const routesList = [
    { path: '/index', exact: true, isAuthor: false, component: Index },
    { path: '/login', exact: true, isAuthor: false, component: Login },
    { path: '/joblist', exact: true, isAuthor: false, component: Jobs },
    { path: '/contant', exact: true, isAuthor: false, component: Contant },
    { path: '/templates', exact: true, isAuthor: false, component: Templates },
    { path: '/info', exact: true, isAuthor: false, component: Info },
    { path: '/company', exact: true, isAuthor: true, component: Company },
    { path: '/editeCompany', exact: true, isAuthor: true,  component: EditeCompany },
    { path: '/accout', exact: true, isAuthor: true, component: Account },
    { path: '/job', exact: true, isAuthor: true, component: Job },
    { path: '/jobDetail/:id', exact: true, isAuthor: true, component: JobDetail },
    { path: '/publish/:id?', exact: true, isAuthor: true, component: Publish },
    { path: '/introversion', exact: true, isAuthor: true, component: Introversion },
    { path: '/follow', exact: true, isAuthor: true, component: Follow },
    { path: '/bole', exact: true, isAuthor: true, component: Bole },
    { path: '/candidate', exact: true, isAuthor: true, component: Candidate },
    { path: '/areement', exact: true, isAuthor: false, component: Agreement },
    { path: '/download', exact: true, isAuthor: false, component: Downloads },
    { path: '/flai', exact: true, isAuthor: false, component: Flai },
    { path: '/amount', exact: true, isAuthor: true, component: Amount },
    { path: '/recharge', exact: true, isAuthor: true, component: Recharge },
    { path: '/refund', exact: true, isAuthor: true, component: Refund }
]

class RouterHome extends Component {


    render() {
        let path = window.location.href
        let auth = ''
        let agr = path.includes('/areement')
        let token = sessionStorage.getItem(SET_USER);
            if (token != null && token !== '') {
                token = JSON.parse(token)
                auth = token
            }
        
        return (
            <BrowserRouter>
                <Switch>
                    <Route path='/' exact render={() => (
                        <Redirect to='/login'></Redirect>
                    )}></Route>
                    {
                        routesList.map((item, index) => {
                            if (!item.isAuthor) {
                                return <Route  key={index} path={item.path} component={item.component} />
                            }else  if (item.isAuthor && (auth != null && auth !== '') || agr) {
                                return <Route  key={index} path={item.path} component={item.component} />
                            } else {
                                return <Route  key={index} path={item.path} component={item.component} />

                            }
                        })
                    }
                </Switch> 
            </BrowserRouter>
        )
    }
}

export default RouterHome