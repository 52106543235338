import React, { Component } from 'react'
import { InputNumber, Button, Modal, Image,Input, Form, message } from "antd";
import { refundCreate} from '../../api'

import './index.less';

class Recharge extends Component {
    constructor () {
        super()
        this.state ={
            rechargeModal: false,
            timer: 10,
            money: 0,
        }
    }

    componentWillMount(){
        let m = localStorage.getItem('M')
        this.setState({money: m})
    }

 
    onBack () {
        //window.location.href = 'amount'
        this.props.history.push('/amount')
    }

    validatorPrice (rule, value, callback) {
        const { money } = this.state
        if (!value) return callback('金额不能为空!')
        const reg = /^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/;
        if (!reg.test(value))return callback('金额格式有误!')
        
        if (Number(value).toFixed(2) > Number(money).toFixed(2)) {
            return callback('不可大于可退金额!')
        }
        return callback()
    }

    validatorBank (rule, value, callback) {
        if (!value) return callback('开户银行不能为空!')
        if (value.length > 100) return callback('开户银行不能超过100字符!')
        return callback()
    }
    validatorBankAccount (rule, value, callback) {
        if (!value) return callback('银行账号不能为空!')
        let re = /^[0-9]+.?[0-9]*/;
        if (!re.test(value))return callback('请输入数字!')
        return callback()
    }

    validatorOwnerName (rule, value, callback) {
        if (!value) return callback('户名不能为空!')
        if (value.length > 100) return callback('户名不能超过100字符!')
        return callback()
    }

    validatorPhone (rule, value, callback) {
        if (!value) return callback('手机号不能为空!')
        const myreg=/^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        if (!myreg.test(value))return callback('手机号格式有误!')
        return callback()
    }

    async onFinish (values) {
        const res = await refundCreate(values);
        if (res.code === 7001) {
            message.error(res.message)
            return
        }
        if (res.code === 0) {
            message.success('申请退款成功，预计30个工作日内完成退款，您可在退款明细中查看退款进度')
        }
        let timer = window.setTimeout(() => {
            window.clearTimeout(timer)
            timer = null;
            this.props.history.push('/amount')
        }, 1000)
    }



    render () {
        const { money } = this.state

        return (
            <div className="recharge-box">
                <div className='recharge-header'>申请退款</div>
                <div>
                    <div>
                        <Image className="notice-icon" src="https://img.fanwoon.com/redtishi%402x.png" />
                        退款说明
                    </div>
                    <div>
                       1. 退款将以转账方式汇入您填写的银行信息；
                    </div>
                    <div>
                        2. 请务必保证下列信息准确，如果退款失败，不会影响账户的余额；
                    </div>
                    <div>
                        3. 提交退款后，预计<span>30个工作日</span>内完成退款
                    </div>
                    <div>
                        4.由于不同银行的政策不同，请以银行实际到账时间为准；  
                    </div>
                </div>
                
                <div className='form-box'>
                    
                    <Form onFinish = { this.onFinish.bind(this)}>
                            <Form.Item name="price" rules={[{ required: true, validator: this.validatorPrice.bind(this), pattern: new RegExp('^[/d]/g','g')  }]}>
                               <div>
                               <div className="refund-input-box">
                                    <span className='mark-label'>退款金额：</span>
                                    <Input className='w430' placeholder="必填，数字可输入2位小数，不可超过当前可退款金额" />
                                </div>&nbsp;
                                <div className='refund-mark'>当前账户可退款金额：<span className='money-box'>{money}</span></div>
                               </div>
                            </Form.Item>
                            
                        <div className='mark-label mt20'>退款至以下银行账户</div>
                        <Form.Item label="开户银行" name="bank" rules={[{ required: true, validator: this.validatorBank }]}>
                            <Input placeholder="请输入开户银行" />
                        </Form.Item>
                        <Form.Item label="支行名称" name="subbranch">
                            <Input placeholder="请输入支行名称" />
                        </Form.Item>
                        <Form.Item 
                            label="银行账号" 
                            name="bankAccount" 
                            rules={[{ required: true, validator: this.validatorBankAccount, pattern: new RegExp('^[/d]/g','g') }]}>
                            <Input placeholder="请输入银行账号" />
                        </Form.Item>
                        <Form.Item label="户&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名" 
                            name="ownerName" rules={[{ required: true, validator: this.validatorOwnerName }]}>
                            <Input placeholder="请输入户名" />
                        </Form.Item>
                        <Form.Item label="手机号码" name="ownerPhone" rules={[{ required: true, validator: this.validatorPhone }]}>
                            <Input placeholder="请输入手机号码" />
                        </Form.Item>
                        <Form.Item label="留&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;言" name="remark">
                            <Input.TextArea placeholder="请输入留言" maxLength="300" />
                        </Form.Item>
                        <Form.Item>
                            <div className='form-footer'>
                                <span className='back-font cursors' onClick={this.onBack.bind(this)}>返回</span>
                                <Button size="small" type="primary" htmlType="submit">
                                    确定
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        )
    }
}

export default Recharge;