import React, { Component } from 'react'
import './info.less'


class Infos extends Component {

    constructor (props) {
        super(props)
        this.state = {
            info: {},
            data: [{
                id: 1,
                job: '房产经纪人/房产销售',
                company: '某上市连锁房产公司',
                date: '2021-07-28',
                city: '上海-黄浦区',
                money: '10k-20k',
                content: `
【福利待遇】
1、无行业经验的新人入职统一薪资为10000-11000+提成；
3、月休7天：做六休一，另外每月1、2、3号连休3天亲情小长假，方便你陪伴家人、看看外面的世界!
4、家庭式宿舍：有专职阿姨打扫卫生，洗衣熨衣；专职宿舍管理员负责安全及卫生；
5、完善的带薪培训体系，哪怕你是小白，只要你够努力，就没有什么不可能；
6、员工季度会议、年度旅游、部门团建活动、员工生日会等等……
【任职要求】
1. 学历：大专及以上，年龄：18-35岁；
2. 良好的沟通能力；
3. 有梦想，敢于为梦想拼搏。
【晋升通道】
一年成为专业的房地产经纪人，实现买车梦想；
二年晋升分行经理，参与公司股权分享，财富迅速提升；
三年晋升高级经理，实现买房梦想；
只要你足够优秀，只要你敢想敢做，我们提供无限的空间任你这，不仅仅是一份工作的机会，更是一个创业的平台！`
            }, {
                id: 2,
                job: '门店合伙人',
                company: '某房产公司',
                date: '2021-07-28',
                city: '上海-长宁区',
                money: '15k-30k',
                content: `
首先明确：我们无底薪，我们需要的是有远大理想的你
我有一个门店，位于长宁区双流路，河风丽景小区门口，地理位置绝佳，主营天山板块存量房交易中高端住宅项目
目前门店只有2个业务员工作业绩平平，现特发布此信息，寻找作业能力强，有团队管理经验人员或团队合作，门店可全权托付经验，具体面谈
当然，你也可以先做做业务感受一下，买卖统提60%，租赁统提70%，无平台管理费
职位招聘1-100人

本公司致力于上海二手房买卖租租赁、上海以及环沪一手房销售，至公司成立以来获得多位客户好评，也力求做好，做强，做出员工的保护墙和立家之本`
            }, {
                id: 3,
                job: '导购',
                company: '某时尚服装品牌',
                date: '2021-07-28',
                city: '上海-静安区',
                money: '8k-10k',
                content: `
1、接待顾客的咨询，了解顾客的需求并达成销售；
2、负责做好货品销售记录、盘点、账目核对，按规定完成销售统计工作；
3、完成商品的来货验收、上架陈列摆放、补货、退货、防损等日常工作；
4、做好所负责区域的卫生清洁工作；
5、完成上级领导交办的其他任务。
任职资格：
1、高中以上学历；
2、有相关工作经验者优先；
3、具有较强的沟通能力及服务意识，吃苦耐劳；
4、年龄18-35岁，身体健康。
工作时间：大部分为做一休一，10：00-22：00 少部分串班 欢迎咨询
职位招聘2人`
            }, {
                id: 4,
                job: '快递员（可就近分配）',
                company: '某上市物流公司',
                date: '2021-07-28',
                city: '上海-浦东新区',
                money: '7k-15k',
                content: `
1，负责区域货物按时配送，保证服务质量，维护客户关系。
2，有较强的团队协作意识，服从工作安排
3，做事认真踏实，积极向上的工作态度
职位招聘5-15人

· 薪资范围：7000-15000元/月
· 发薪日：18日
· 底薪：7000元/月
· 社保类型：五险
· 提成方式：计件提成；
· 奖金补贴：包吃·包住·餐补·加班费·全勤奖·工龄奖`
            }, {
                id: 5,
                job: '店长',
                company: '某时尚服装品牌',
                date: '2021-07-28',
                city: '上海-静安区',
                money: '15k-30k',
                content: `
岗位职责：
1、 根据门店业绩目标，制定门店销售计划，分解和执行；
2、 管理店内商品陈列，掌握店铺商品销售动态；
3、 门店人员考勤、形象、服务等日常管理；
4、 门店人员工作辅导、培训和考核；
5、 门店商品陈列管理，店内形象、购物环境维护；
6、 商品订货、调拨及库存、退货等管理；
7、 商品滞销、破损管理，做好商品保养；
8、 门店会员管理，达成公司对顾客满意度的要求；
9、 门店品牌推广活动及促销活动执行；
10.负责处理门店的突发事件、顾客投诉及其他需帮助解决事宜；
11.级交办的其它任务。
岗位要求：
1、年龄25-35岁，中专及以上学历。
2、性别不限，形象气质佳，普通话良好。
3、奢侈品行业或国内高端品牌5年以上门店经验，3年以上门店管理经验。
4、较强的服务意识和销售能力，对时尚行业充满兴趣，热爱本职工作。
5、良好的团队管理协调能力。`
            }, {
                id: 6,
                job: '后厨服务员（工资日结）',
                company: '某连锁餐饮品牌',
                date: '2021-07-28',
                city: '上海-徐汇区',
                money: '5k-6k',
                content: `
薪资范围：3000-5000元/月
发薪日：10日
社保类型：五险一金

轮班，09:00-18:00、07:00-15:00、15:00-22:00

按公司品控要求制作产品，厨房产品制作，总配汉堡制作等。
职位招聘1-3人`
            }, {
                id: 7,
                job: '美甲师',
                company: '某美容美发机构',
                date: '2021-07-28',
                city: '上海-宝山区',
                money: '8k-13k',
                content: `
· 薪资范围：8000-13000元/月
· 发薪日：15日
· 底薪：6000元/月
· 社保类型：五险
· 提成方式：销售额提成；
· 奖金补贴：包吃·包住·绩效奖金

长白班，11:00-20:00

熟练美甲美睫技术
有团队精神和个人荣誉感
职位招聘1-5人`
            }]
        }
    }

    componentWillMount () {
        this.initInfo()
    }

    initInfo () {
        let id = this.getQueryVariable('id')
        let obj = this.state.data[id - 1]
        this.setState({info: obj})
    }

    /* info() {
        let id = this.getQueryVariable('id')
        return this.state.data[id - 1] //this.data[id - 1]
    } */

    getQueryVariable (variable) {
        var query = window.location.search.substring(1);
            var vars = query.split("&");
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split("=");
                if (pair[0] == variable) {
                    return pair[1];
                }
            }
            return (false);
    }

    render() {

        const { info } = this.state
       
        return (
            <div className="app">
                <div className="flex-box header-box">
                    <img  alt="" className="logo" src="https://img.fanwoon.com/new_logo.png" />
                    <ul className="menu">
                        <li><a href="/index">首页</a></li>
                        <li><a href="/jobList">热招职位</a></li>
                        <li><a href="/contant">联系我们</a></li>
                        <li><a href="/templates">下载简历模板</a></li>
                        <li><a href="/login">登录注册</a></li>
                    </ul>
                </div>
                <div id="app">
                    <div className="job-items">
                        <div className="job-title">{ info.job }</div>
                        <div className="job-item-info">
                            <div>招聘企业：{ info.company }</div>
                            <div>发布日期：{ info.date }</div>
                            <div>工作地点：{ info.city }</div>
                            <div>月薪：{ info.money }</div>
                        </div>
                    </div>
                    <div className="job-content-info">
                        <div className="job-title-content">岗位要求</div>
                        <pre>
                            { info.content }
                        </pre>
                    </div>
                </div>

                <div className="footer-box">
                    <span>Copyright © 2022 翻腕</span>
                    <span>沪ICP备2020029704号-1</span>
                    <span>
                        <img  alt="" className="beian-icon" src="https://img.fanwoon.com/police.png" /><a target="_blank" className="normal-a" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011302007180">沪公网安备 31011302007180号</a>
                    </span>
                </div>
            </div>
        )
    }
}

export default Infos