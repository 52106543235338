import http from '../utils/axios'

let OMS = ''
let TOB = ''
let base = 'https://api.fanwoon.com'

if (process.env.NODE_ENV === "development") {
    OMS = '/ips'
    TOB = '/api'
} else {
    OMS = base + '/oms'
    TOB = base + '/tob'
}

// 

/**
 * @description 获取公司详情
 */
export const companyDetail = () => {
    return new Promise((resolve, reject) => {
        let url = TOB + '/company/detail'
        http('post', url).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}