import React, { Component } from 'react'
import {Input, Button, Image, Tag, Carousel} from 'antd'
import { companyDetail } from '../../api/company'
import EditeCompany from './edite'

import "./index.less"


class Company extends Component {

    constructor () {
        super(...arguments)
        this.state = {
            company: {},
            showEdit: false, //是否显示编辑页面
        }
    }

    componentWillMount() {
        this.init()
    }

    //打开或关闭编辑页面
    onTaggleEdit () {
        let { showEdit } = this.state
        this.setState({ showEdit: !showEdit })
    }

    async init () {
        let res = await companyDetail()
        if (typeof(res.data.corporateWelfare) == 'string' && res.data.corporateWelfare && res.data.corporateWelfare.includes("[")) {
            res.data.corporateWelfare = JSON.parse(res.data.corporateWelfare)
        } else if (typeof(res.data.corporateWelfare) == 'string' && res.data.corporateWelfare) {
            res.data.corporateWelfare = res.data.corporateWelfare.split(',')
        } else if(res.data.corporateWelfare) {
            res.data.corporateWelfare = JSON.parse(res.data.corporateWelfare)
        }
        if (res.data.gallery) {
            res.data.gallery = res.data.gallery.split(',')
        }
        this.setState({company: res.data})
        console.log(res, '公司详情')
    }

    render() {
        const { company, showEdit } = this.state

        return (
            <div className='company-page'>
              {
                  showEdit ? <EditeCompany parent={this} company={company} /> : 
                  (
                    <div>
                        <div className='edit-box'>
                             <Button className='btn' onClick={this.onTaggleEdit.bind(this)}>编辑</Button>
                        </div>
                            <div className='flex-item company-header-box flex-align-items-center'>
                                <div className='company-logo'>
                                        <Image className='company-pic' src={company.logo} />
                                </div>
                                <div className='company-info-box'>
                                    <div className='company-name'>线下牌匾名: {company.shortName}</div>
                                    <div className='company-sub-name'>{company.name}</div>
                                </div>
                            </div>
                            <div className='carousel-item'>
                                    <Carousel autoplay className='carousel-box'>
                                        {
                                            !company.gallery ? '' : 
                                            company.gallery.map((item, index) => {
                                                return (
                                                    <div key={index} className='company-pic-banner-box'>
                                                        <Image src={item} className='pic-box' />
                                                    </div>
                                                )
                                            })
                                        }
                                    </Carousel>
                            </div>
                            <div className='company-detail-box'>
                                <div className='company-title'>门店福利</div>
                                <div>
                                    {
                                        !company.corporateWelfare ? '暂无数据' : 
                                        company.corporateWelfare.map((item, index) => {
                                                return <Tag key={index}>{item}</Tag>
                                        })
                                    }
                                </div>
                            </div>
                            <div className='company-detail-box'>
                                <div className='company-title'>门店介绍</div>
                                <div >
                                    <Input.TextArea 
                                        value={!company.introduction ? '暂无数据' : company.introduction} 
                                        autoSize={true}
                                        bordered={false}
                                        disabled={true}
                                        rows={8}
                                        className="textarea-def-box"
                                         />
                                </div>
                            </div>
                            <div className='company-detail-box'>
                                <div className='company-title'>地址</div>
                                    <div>{company.city}{company.district}{company.hqAdd}</div>
                            </div>
                        </div>
                  )
              }
            </div>
        )
    }
}

export default Company