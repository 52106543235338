/**
 * 获取url参数
 * @param {*} variable 
 */
export const getQueryVariable = (variable) => {
    var query = window.location.search.substring(1);
        let vars = query.split("&");
        for (let i = 0; i < vars.length; i++) {
            let pair = vars[i].split("=");
            if (pair[0] == variable) {
                return pair[1];
            }
        }
        return (false);
}

/**
 * 防抖
 * @param {*} fn 
 * @param {*} delay 
 */
export const debounce = (fn, delay) => {
    let timeout
    return function() {
        let context = this
        let args = arguments

        if (timeout) clearTimeout(timeout)

        timeout = setTimeout(() => {
            fn.apply(context, args)
        }, delay)
    }
}

/**
 * 状态
 */
export const stateMsg = new Map([
    [0, '筛选简历'],
    [200, '面试'],
    [300, 'offer'],
    [400, '入职'],
    [10000, '淘汰'],
])

export function age(str) {
    var r = str.match(/^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2})$/);
    if (r == null) return false;
    var d = new Date(r[1], r[3] - 1, r[4]);
    if (d.getFullYear() == r[1] && (d.getMonth() + 1) == r[3] && d.getDate() == r[4]) {
        var Y = new Date().getFullYear();
        return ((Y - r[1]) + "岁");
    }
    return ("输入的日期格式错误！");
}

export function getQueryString(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
}


export const storage = {
    /*
    * set 存储方法
    * @ param {String}     key 键
    * @ param {String}     value 值，
    * @ param {String}     expired 过期时间，以分钟为单位，非必须
    */
    set(key, val, expired) {
        if (typeof val !== 'string') {
            val = JSON.stringify(val);
        }
        window.localStorage.setItem(key, val);
        if (expired) {
            window.localStorage.setItem(`${key}__expires__`, `${Date.now() + 1000 * 60 * expired}`);
        }
    },
    /*
   * get 获取方法
   * @ param {String}     key 键
   * @ param {String}     expired 存储时为非必须字段，所以有可能取不到，默认为 Date.now+1
   */
    get(key) {
        const expired = window.localStorage.getItem(`${key}__expires__`) || Date.now + 1;
        const now = Date.now();

        if (now >= expired) {
            window.localStorage.removeItem(key);
            return;
        }
        let val = window.localStorage.getItem(key);
        try {
            val = JSON.parse(val);
        } catch (e) {
            console.log(`${val} Unexpected token H in JSON at position 0`);
        }
        return val;
    },
    clear() {
        window.localStorage.clear();
    },
    /*
    * remove 移除
    * */
    remove(key) {
        if (window.localStorage.getItem(`${key}__expires__`)) {
            window.localStorage.removeItem(`${key}__expires__`);
        }

        if (window.localStorage.getItem(key)) {
            window.localStorage.removeItem(key);
        }
    }
};


export const timesStr = (date1, date2) => {
    //date2 = '2022-08-03 00:00:00'
    let sdate = new Date();  //开始时间  
    let now = new Date(date2);    //结束时间  
    let days= now.getTime() - sdate.getTime()  //时间差的毫秒数  
    let day = Math.floor(days/( 24 * 3600 * 1000))  
    
    //计算出小时数  
    let leave1=days%(24*3600*1000)    //计算天数后剩余的毫秒数  
    let hours=Math.floor(leave1/(3600*1000))  
    return {day, str: day + "天 "+hours+"小时 "}
}