import React, { Component } from 'react'
import { connect } from 'react-redux'
import {  Modal, Button, Input, message, Steps, Form, Tooltip } from 'antd'
import { ExclamationCircleOutlined, } from '@ant-design/icons'
import { 
    interview, 
    taskOffer, 
    taskEntry, 
    taskFinish, 
    eliminate, 
    recover,
    browseAndInterview 
} from '../../api/follw'
import { taskBrowse, remaining, delayReward } from '../../api'
import { stateMsg, age, timesStr } from '../../utils'
import { SET_CSNDIDATE } from '../../utils/constant'
import { initAccount } from '../../store/action'
import CashierWidget from '../CashierWidget'

import './index.less'

const defPic = 'http://img.fanwoon.com/avatar.png'

class FollowList extends Component {

    constructor () {
        super(...arguments)
        this.state = {
            visible: false,//控制显示面试弹框
            step: 0, //当前状态步骤
            interview: false,
            interviewId: 0,
            money: 0,//邀约面试扣款金额
            balance: 0, //余额
            link: 0, //第一环节奖金
            show: {
                type: 0,
                title: ''
            },
            from: 'first',
            addUpMoney: 0 //预扣累计
        }
    }

    componentWillMount () {
        this.initLinkMoney()
        this.onStep()
        this.onPayMoney()
     }

     //余额
     async _remaining () {
        const res = await remaining();
        const { initMoney } = this.props
        initMoney(res.data)
    }

     //初始化环节金额
     initLinkMoney () {
        const { job } = this.props;
        const { recommendReward,  recommendRewardRole} = job
        if (recommendReward && recommendRewardRole) {
            let price = JSON.parse(recommendRewardRole)
            this.setState({link: price[0]})
        }
     }

     onPayMoney () {
         const { job, info } = this.props;
         let m = localStorage.getItem('M')
         m ? this.setState({ balance: m }) : this.setState({ balance: 0})
     }

    // 完成入职
    async onTaskFinish (id, bool) {
        let res = await taskFinish({data: {id: id, name: bool}})
        this.setState({
            interview: false, 
            interviewId: 0,
            show: {
                type: 0,
                title: '发放内推奖金 & 邀约面试费'
            }
        })
        if (res.code !== 0) {
            message.error(`操作失败${res.message}`)
            return
        } else {
            message.success(`已${res.message}完成入职`)
            //此处为设置状态金额
            this._remaining()
            let timeout = setTimeout(() => {
                clearTimeout(timeout)
                timeout = null
                let { parent, defaultTabKey } = this.props
                parent.onRefresh(defaultTabKey)
            }, 2000)
        }
    }

    //接受入职
    async onTaskEntry(id) {
        let res = await taskEntry({data: id})
        this.setState({  interview: false, interviewId: 0 })
        if (res.code !== 0) {
            message.error(`操作失败${res.message}`)
            return
        } else {
            message.success(`已${res.message}接受入职`)
            //此处为设置状态金额
            this._remaining()
            let timeout = setTimeout(() => {
                clearTimeout(timeout)
                timeout = null
                let { parent, defaultTabKey } = this.props
                parent.onRefresh(defaultTabKey)
            }, 2000)
        }
    }

    //通过面试
    async onTaskOffer(id) { //
        let res = await taskOffer({data: id})
        this.setState({  interview: false, interviewId: 0 })
        if (res.code !== 0) {
            message.error(`操作失败${res.message}`)
            return
        } else {
            message.success(`已${res.message}通过全部面试`)
            //此处为设置状态金额
            this._remaining()
            let timeout = setTimeout(() => {
                clearTimeout(timeout)
                timeout = null
                let { parent, defaultTabKey } = this.props
                parent.onRefresh(defaultTabKey)
            }, 2000)
        }
    }

    //通过简历筛选
    async onInterview (id, bool) {
        let res = await browseAndInterview({data: {id: id, name: bool}})
        this.setState({  interview: false, interviewId: 0})
        if (res.code !== 0) {
            message.error(res.message)
            return
        } else {
            message.success(res.message + '通过筛选简历!')
            //此处为设置状态金额
            this._remaining()

            let timeout = setTimeout(() => {
                clearTimeout(timeout)
                timeout = null
                let { parent, defaultTabKey } = this.props
                parent.onRefresh(defaultTabKey)
            }, 2000)
        }
    }

    //获取年纪
    getAge(str) {
        if (!str)return 0
        return age(str)
    }

    showModal = (id) => {
        // 当前可用余额 - （平台邀约藐视费 + 第一环节内推奖金 >= 0
        this.setState({
            interview: true, 
            interviewId: id, 
            /* show: {
                type: 0,
                title: '发放内推奖金 & 邀约面试费'
            } */
        })
    }

   /*  async _taskBrowse(id) {
        const res = await taskBrowse({data: id})
        if (res.code !== 0) {
            return message.error(`${res.message}`)
        }
        return true
    } */

    onHandleCashier(bool) {
        console.log(bool, '打印了什么鬼东西呢....')
        this.onInterviewEnter()
    }

    onInterviewEnter (bool) {
        const { interviewId } = this.state 
        const { info } = this.props
        this.setState({ interview: false })
        if (info.status === 0) {
            Modal.confirm({
                title: '邀约通过',
                icon: <ExclamationCircleOutlined />,
                content: '该求职者将进入面试阶段，是否确定？',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    this.onInterview(interviewId, bool)
                 }
            })

        } else if (info.status === 400) {
            Modal.confirm({
                title: '已入职',
                icon: <ExclamationCircleOutlined />,
                content: '该该求职者已经顺利入职，是否确定？',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                   this.onTaskFinish(interviewId, bool)
                 }
            })
        }
    }

    //打开进入面试弹框
    showInterview = (id) => {
        const { job } = this.props;
        this.setState({money: 0})
        Modal.confirm({
            title: '通过全部面试',
            icon: <ExclamationCircleOutlined />,
            content: '该求职者将进入入职报到阶段，是否确定？',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                this.onTaskEntry(id)
                }
            })
    }

    //打开进入接口offer阶段
    showOffer = (id) => {
        Modal.confirm({
            title: '接受offer',
            icon: <ExclamationCircleOutlined />,
            content: '该求职者将进入入职阶段，是否确定？',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                this.onTaskEntry(id)
             }
        })
    }

    //打开已入职弹框
    showAlreadyEntery = (id) => {
        const { job, info } = this.props;
        this.setState({from: 'last'})

        //如果没有推荐奖金，则直接二次弹框确认
        //或者已扣除预扣款项，直接提示二次确认弹框
        if (!job.recommendReward || (job.recommendReward && job.recommendRewardRole && info.withheld)) {
            //openViewDailog()
            //openDialogConfirm(false)
            this.setState({money: 0})
            Modal.confirm({
                title: '已入职',
                icon: <ExclamationCircleOutlined />,
                content: '该求职者已经顺利入职，是否确定？',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                this.onTaskFinish(id, false)
                }
            })
            return
        }
        //如何设置了推荐奖金，预扣为勾选，则二次预扣弹框提示
        if (job.recommendReward && job.recommendRewardRole && !info.withheld) {
            //onOpenViewDailog()//再次显示预扣款弹框
            this.showModal(id)
            return
        }
    }

    //打开淘汰弹框
    showOut = () => this.setState({ visible: true })

    //取消淘汰弹框
    handleCancel = () => this.setState({ visible: false })

    onStep (){
        const { info } = this.props
       // if(info.status === 0) step = 0  
       switch(info.status) {
            case 0: 
                this.setState({step: 0}) //筛选简历阶段
                break 
            case 200:
                this.setState({step: 1}) //面试阶段
                break 
            case 300:
                this.setState({step: 2}) //offer阶段
                break   
            default:
                this.setState({step: 3}) //入职阶段
       }
    }

    onShowStateMsg () {
        const { curState } = this.props
        if (Number(curState) === 0) return "筛选简历"
        if (Number(curState) === 200) return "面试"
        if (Number(curState) === 300) return "offer"
        if (Number(curState) === 400) return "入职"
    }

    //淘汰操作
    async onOut (values) {
        const { info, parent } = this.props
        values['id'] = info.id
        let res = await eliminate(values)
        if (res.code  !== 0) {
            message.error(`操作${res.message}`)
        }
        this.handleCancel()
        parent.init()
    }

    //打开恢复至原阶段
    showRecover () {
        const { info  } = this.props
        let sta = info.eliminateStatus
        let str = '筛选简历'
        if (sta === 200) {
            str = '面试中'
        } else if (sta === 400) {
            str = '入职报到'
        } else if (sta === 10000) {
            str = '已淘汰'
        } 


        Modal.confirm({
            title: '恢复至原阶段',
            icon: <ExclamationCircleOutlined />,
            content: <div>是否将该求职者恢复至<span className="interview-state-font">{ str }</span>阶段</div>,
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                this.onRecover()
             },
          })
    }

    //确认恢复
    async onRecover (id) {
       const { info, parent } = this.props
         let res = await recover({data: info.id })
        if (res.code  !== 0) {
            message.error(`操作${res.message}`)
        }
        this.handleCancel()
        parent.onSetTab(info.status)
    }

    onGoCandidate() {
        const { info, job, curState } = this.props
        let jobInfo = { info: info, job: job }
        sessionStorage.setItem(SET_CSNDIDATE, JSON.stringify(jobInfo))
        window.location.href = "/candidate?id=" + info.id + '&jobId=' + job.id + '&status=' + curState
    }

    moneyBox () {
        const { info, money} = this.props
        let money2 = 0
        let num = 0
            num = !num ? 0 : num
        let obj = info.recommendRewardRole 
        if (obj) {
            obj = JSON.parse(obj)
            if (obj[0]) {
                money2 += Number(obj[0] + num)
            }
            if (obj[200]) {
                money2 += Number(obj[200]  + num)
            }
            if (obj[300]) {
                money2 += Number(obj[300]  + num)
            }
            if (obj[400]) {
                money2 += Number(obj[400]  + num)
            }
        }
        return money2.toFixed(2)
    }

    async _delayReward (id) {
        const res = await delayReward(id)
        if (res.code !== 0) {
            return message.error(`${res.message}`)
        } else {
            this._remaining()
            message.success('操作' + res.message)
            let timeout = setTimeout(() => {
                clearTimeout(timeout)
                timeout = null
                let { parent, defaultTabKey } = this.props
                parent.onRefresh(defaultTabKey)
            }, 2000)
        }
    }

    onDelays (day, id, expirationSent) {
        if (day > 2) { //（1）如果剩余天数 > 2 天，则toast提示：“距离结束时间2天才可申请”
            return message.error(`距离结束时间2天才可申请`)
        } 
        if (expirationSent) {
            return message.error(`已经申请过延长结算`)
        }
        if (day <= 2) { //（2）如果剩余天数 <= 2 天，则二次确认弹窗：“每笔奖金只能申请延长一次7天，确认吗？”
            Modal.confirm({
                title: '温馨提示!',
                icon: <ExclamationCircleOutlined />,
                content: '每笔奖金只能申请延长一次7天，确认吗？',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                     this._delayReward(id)
                 },
              })
        } 
    }

    onTuijianAvatarUI () {
        const { info, curState } = this.props
        let money = 0
        let obj = info.recommendRewardRole
        if (!info.recommendReward)return 
        /* if (info.recommendReward && !obj)return */
        if (obj) {
            obj = JSON.parse(obj)
            if (obj[0]) {
                money += Number(obj[0])
            }else if (obj[200]) {
                money += Number(obj[200])
            }else  if (obj[300]) {
                money += Number(obj[300])
            }else if (obj[400]) {
                money += Number(obj[400])
            }
        }

        if (info.talentName && Number(money).toFixed(2) !== Number(0).toFixed(2)) {
            return (
                <div className='tanlent-wrap'>
                    <span>发放给</span>
                    <img className='tanlent-avatar mlr8' src={info.talentImg} alt='' />
                    <span>
                        {info.talentName.length > 8 ? info.talentName.substring(0, 8) + '...'
                                : info.talentName}
                    </span>
                    <span className='neitui-money-font'>内推奖金:</span>
                    <span>¥ <label className='neitui-money'>{Number(money).toFixed(2)}</label></span>
                </div>
            )
        }

        if (!info.talentName && !info.talentImgSet || info.talentImgSet.length === 0 && Number(money).toFixed(2) !== Number(0).toFixed(2)) {
            return (
                <div className='tanlent-wrap'>
                    <span>发放给</span>
                    <img className='tanlent-avatar mlr8' src='https://img.fanwoon.com/slicetu.png' alt='' />
                    <span>翻腕精选</span>
                    <span className='neitui-money-font'>内推奖金:</span>
                    <span>¥ <label className='neitui-money'>{Number(money).toFixed(2)}</label></span>
                </div>
            )
        }
        if (info.talentImgSet && info.talentImgSet.length === 1  && Number(money).toFixed(2) !== Number(0).toFixed(2)) {
            return (
                <div className='tanlent-wrap'>
                    <span>发放给</span>
                    <img className='tanlent-avatar mlr8' src={info.talentImgSet[0]} alt='' />
                    <span>
                        {info.talentNameSet && info.talentNameSet.length > 0 ? (
                                info.talentNameSet[0].length > 10 ? info.talentNameSet[0].substring(0, 10) + '...'
                                : info.talentNameSet[0]
                            ) : null}
                    </span>
                    <span className='neitui-money-font'>内推奖金:</span>
                    <span>¥ <label className='neitui-money'>{Number(money).toFixed(2)}</label></span>
                </div>
            )
        }
        if (info.talentImgSet && info.talentImgSet.length > 1  && Number(money).toFixed(2) !== Number(0).toFixed(2)) {
            return (
                <div className='tanlent-wrap'>
                    <span>发放给</span>
                    <div className='tanlentListBox'>
                        <img className={`tanlent-avatar`}
                              src={info.talentImgSet[0]} alt='' />
                        <span>4
                            {info.talentNameSet && info.talentNameSet.length > 0 ? (
                                info.talentNameSet[0].length > 8 ? info.talentNameSet[0].substring(0, 8) + '...'
                                : info.talentNameSet[0]
                            ) : null}
                        </span>      
                        <span>等{info.talentImgSet.length} 人</span>
                        <span className='neitui-money-font'>内推奖金:</span>
                        <span>¥ <label className='neitui-money'>{Number(money).toFixed(2)}</label></span>
                    </div>
                </div>
            )
        }
        
    }
    
    render () {

        const { info, curState, job } = this.props
        const { interview, money, show } = this.state

        let buttons = ''
        if (curState === 0 && job.status !== 0) {
            buttons = (
                <div className='btn-bar-box'>
                    <Button onClick={this.showOut}>淘汰</Button>
                    <Button className='saixuan-btn'  onClick={this.showModal.bind(this, info.id)}>邀约面试</Button>
                </div>
            )
        } else if (curState === 200 && job.status !== 0) {
            buttons = (
                <div>
                    <div className='btn-bar-box'>
                        <Button onClick={this.showOut}>未通过面试</Button>
                        <Button className='saixuan-btn'  onClick={this.showInterview.bind(this, info.id)}>通过全部面试</Button>
                    </div>
                   {
                        info.withheld ? (
                            <div className='beleftBox'>
                                {
                                    timesStr(info.createTime, info.expirationTime).day < 0  ? <span>奖金已结算</span> : 
                                    
                                    (
                                        <span>还剩<span>{timesStr(info.createTime, info.expirationTime).str}</span>自动结算</span>
                                    )
                                }
                                {
                                        timesStr(info.createTime, info.expirationTime).day < 0  ? null : (
                                            <span onClick={() => this.onDelays(timesStr(info.createTime, info.expirationTime).day, info.id, info.expirationSent)} className='yanchangfont'>申请延长时间</span>
                                        )
                                }
                            </div>
                        ) : null
                   }
                </div>
                
            )
        } else if (curState === 300 && job.status !== 0) {
            buttons = (
                <div>
                    <div className='btn-bar-box'>
                        <Button onClick={this.showOut}>拒绝/不offer</Button>
                        <Button className='saixuan-btn'  onClick={this.showOffer.bind(this, info.id)}>接受offer</Button>
                    </div>
                    {
                         info.withheld ? (
                            <div className='beleftBox'>
                                {
                                    timesStr(info.createTime, info.expirationTime).day < 0 ? <span>奖金已结算</span> : 
                                    <span>还剩<span>{timesStr(info.createTime, info.expirationTime).str}</span>自动结算</span>
                                }
                                {
                                    timesStr(info.createTime, info.expirationTime).day < 0 ? null :
                                    <span onClick={() => this.onDelays(timesStr(info.createTime, info.expirationTime).day, info.id, info.expirationSent)} className='yanchangfont'>申请延长时间</span>
                                }
                            
                            </div>
                         ) : null
                    }
                </div>
            )
        } else if (curState === 400 && !info.finish && job.status !== 0) {
            buttons = (
                <div>
                    <div className='btn-bar-box'>
                        <Button onClick={this.showOut}>未入职</Button>
                        <Button className='saixuan-btn'  onClick={this.showAlreadyEntery.bind(this, info.id)}>已报到上岗</Button>
                    </div>
                    {
                        info.withheld ? (
                            <div className='beleftBox'>
                                {
                                    timesStr(info.createTime, info.expirationTime).day < 0 ? <span>奖金已结算</span> : 
                                    <span>还剩<span>{timesStr(info.createTime, info.expirationTime).str}</span>自动结算</span>
                                }
                                {
                                    timesStr(info.createTime, info.expirationTime).day < 0 ? null : 
                                    <span onClick={() => this.onDelays(timesStr(info.createTime, info.expirationTime).day, info.id, info.expirationSent)} className='yanchangfont'>申请延长时间</span>
                                }
                            </div>
                        ) : null
                    }
                </div>
            )
        }
    
        let status = 0
        if (Number(curState) === 0) {
            status = 0
         }
         if (Number(curState) === 200) {
            status = 1
         }
         if (Number(curState) === 300) {
            status = 2
         }
         if (Number(curState) === 400 && info.finish) {
            status = 4
         }else if (Number(curState) === 400) {
            status = 3
         }
        
        return (
            <div className='follow-flex'>
                <div className='follow-left follow-persone-box'>
                    <div className='user-info-follow' onClick={this.onGoCandidate.bind(this)}>
                                <div className='follow-left'>
                                    <img className='avatar-item' src={!info.candidateInfo.imgUrl ? defPic : info.candidateInfo.imgUrl} alt='' />
                                </div>
                                <div className='follow-right'>
                                    <div>
                                        <span className='persone-name'>{info.candidateInfo.name}</span> 
                                        {this.getAge(info.candidateInfo.birth)} <label>
                                        <label className='line-short'>|</label>
                                        </label> {info.candidateInfo.gender === 1 ? '男' : '女'} <label>
                                        <label className='line-short'>|</label>
                                        </label>{info.candidateInfo.startWork}年工作经验</div>
                                    <div className='candidateInfo-box'>
                                        <span className='school'>{info.candidateInfo.school}</span>
                                        <span className='profession'>{info.candidateInfo.profession}</span>
                                        <span className='education'>{info.candidateInfo.education}</span>
                                    </div>
                                    <div className='cantact-box'>
                                        <span className='phone'>手机： {info.candidateInfo.phone}</span>
                                        {
                                            !info.payStatus ? <span className='mark-box'><img src='https://img.fanwoon.com/jiesuo%402x.png' alt=''/><span>邀约面试解锁联系方式</span></span> : null
                                        }
                                        
                                    </div>
                                    <div className='company-box'>
                                        <span className='email'>邮箱：{info.candidateInfo.email}</span>
                                    </div>
                                </div>
                            </div>
                </div>
                <div className='follow-right'>

                   {
                       Number(curState) !== 10000 ? (
                            <div>
                                 <div>投递时间：{info.createTime}</div>
                                <Steps className="step-box-bar" size="small" current={status}>
                                    <Steps.Step title="筛选简历"/>
                                    <Steps.Step title="面试" />
                                    <Steps.Step title="入职报到" />
                                </Steps>
                                { buttons }
                            </div>
                       
                       ) : (
                           <div>
                               <div className="mb10">淘汰时间：{info.eliminateTime} </div>
                               <div className="mb10">淘汰阶段：{ stateMsg.get(info.eliminateStatus) } </div>
                               <div className="mb10">淘汰原因：{info.eliminateReason}</div>
                              {/*  <Button type="primary" onClick={this.showRecover.bind(this)}>恢复至原阶段</Button> */}
                           </div>
                       )
                   }

                </div>    


                <Modal
                    visible={this.state.visible}
                    footer={null}
                    closable={false}
                    width="400px"
                    onCancel={this.handleCancel}
                    >
                  <Form 
                   onFinish={this.onOut.bind(this)}>
                    <div className='out-box-header'>
                            <span className='mark-out'>
                                <ExclamationCircleOutlined style={{ fontSize: '18px', color: '#febe2d' }}  />
                            </span>
                            <span className='out-title-box'>淘汰候选人</span>
                        </div>
                        <div className="out-state-box">
                            淘汰阶段：{this.onShowStateMsg()}
                        </div>
                        <div className="out-reason-box">
                            <Form.Item 
                                    label="淘汰原因"
                                    name="name" 
                                    rules={[{ required: true, message: '请输入淘汰原因!' }]}>
                                    <Input />
                            </Form.Item>
                        </div>
                        <div className="out-reason-btns">
                            <Button type='primary' className='sub-btn'  htmlType="submit">提交</Button>
                            <Button onClick={this.handleCancel.bind(this)}>取消</Button>
                        </div>
                  </Form>
               </Modal>

             {
                interview ? (
                    <CashierWidget 
                        show={interview} 
                        from={this.state.from}
                        data={info}
                        balance={this.state.balance}//
                        addUp={()=> this.moneyBox()}
                        onCancel={() => this.setState({interview: false, interviewId: 0 })} 
                        onSubmit={(e) => this.onInterviewEnter(e)}
                    />
                ) : null
             }
              
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { state: state};
}

function mapDispatchToProps(dispatch) {
   return {
       initMoney: moeny => dispatch(initAccount(moeny))
   }
}
const App =  connect(
   mapStateToProps,
   mapDispatchToProps
)(FollowList)

export default App