import html2Canvas from "html2canvas";
import JsPDF from "jspdf";

/* var headerObj = document.querySelector("#resume-heade")
console.log(headerObj)*/
var page = 157;
var a4Height = 1060 - 20; //A4纸高度(20为内容上下间距)
const getter19 = 19; //item底部间距
const getter23 = 23; //item底部间距

/**
 * 循环数组处理翻页
 * @param {tag: '标记(此处为item的id,获取item 的dom元素)',array: ['此处为item数组'],getter: 'item间距'} obj 
 */
const handlePageControl = (obj) => {
    /* var headerObj = document.querySelector("#resume-heade")
    console.log(headerObj.offsetHeight, '--0'); */
    console.log(`1page:${page}`)
        /* if (obj.array.length >= 1) {
            for (let i = 0; i < obj.array.length; i++) {
                let domObj = document.querySelector(`#${obj.tag}_${(i + 1)}`);
                let tmp = domObj.offsetHeight + obj.getter;
                if (page + tmp > a4Height) {
                    let height = a4Height - page + obj.getter
                    console.log(`page:${page}---tmp${tmp}----height${height}`)
                    domObj.style = `margin-top: ${height}px;`
                    page = 0;
                    page += tmp
                } else {
                    page += tmp
                }
            }
        } */
}

/**
 * 简单计算单个item页面的高度
 * @param {单个item的高度} tmp 
 * @param {单个item的dom对象} domObj 
 */
const handleSimplePage = (tmp, domObj) => {
    if (page + tmp > a4Height) {
        let height = a4Height - page + getter23
        domObj.style = `margin-top: ${height}px;`
        page = 0;
        page += tmp
    } else {
        page += tmp
    }
}

/**
 * 单个翻页高度计算
 * @param {候选人详情信息} info 
 */
const handleSinglePageControl = (info) => {
    let workplaceObj = document.querySelector("#workplace"); //职场动机容器
    let skillObj = document.querySelector("#skill-list-box"); //技能容器
    let logoObj = document.querySelector("#logo-footer"); //技能容器
    let showPortrayal = (info.privacy != null && info.privacy != '') ? info.privacy.showPortrayal : false; //是否显示职场动机
    //职场动机
    if (showPortrayal && (workplaceObj != null && workplaceObj != "")) {
        let tmp = workplaceObj.offsetHeight + getter23;
        handleSimplePage(tmp, workplaceObj);
    }

    if (skillObj != null && skillObj != "") {
        let tmp = skillObj.offsetHeight + getter23;
        handleSimplePage(tmp, skillObj);
    }
    let tmp = logoObj.offsetHeight + getter23;
    handleSimplePage(tmp, logoObj);
}

/**
 * 导出pdf文件，单页-多页
 * @param {候选人详细信息对象} info 
 */
const exportPDF = (info) => {
    page = 157;
    var title = info.name + "_" + info.jobTitle;
    let dom = document.querySelector("#pdfCentent");

    handlePageControl({
        tag: 'bus',
        array: info.businesses,
        getter: getter19
    });

    handlePageControl({
        tag: 'edu',
        array: info.educations,
        getter: getter23
    });

    //handleSinglePageControl(info);

    html2Canvas(dom, {
        useCORS: true, // 【重要】开启跨域配置
        allowTaint: true, //允许跨域图片
        taintTest: false, //是否在渲染前测试图
        scale: 2, //提升画面质量，但是会增加文件大小
    }).then(function(canvas) {
        var contentWidth = canvas.width;
        var contentHeight = canvas.height;
        var pageData = canvas.toDataURL('image/jpeg', 1.0);
        var type = 'png';
        var imgData = canvas.toDataURL(type); //canvas转换为图片
        // 加工image data，替换mime type，方便以后唤起浏览器下载

        var pdf = new JsPDF('', 'mm', 'a4'); //A4纸，纵向
        var ctx = canvas.getContext('2d'),
            a4w = 190,
            a4h = 277, //A4大小，210mm x 297mm，四边各保留10mm的边距，显示区域190x277
            imgHeight = Math.floor(a4h * canvas.width / a4w), //按A4显示比例换算一页图像的像素高度
            renderedHeight = 0;

        while (renderedHeight < canvas.height) {
            var page = document.createElement("canvas");

            page.width = canvas.width;
            page.height = Math.min(imgHeight, canvas.height - renderedHeight); //可能内容不足一页

            //用getImageData剪裁指定区域，并画到前面创建的canvas对象中
            page.getContext('2d').putImageData(ctx.getImageData(0, renderedHeight, canvas.width, Math.min(imgHeight, canvas.height - renderedHeight)), 0, 0);
            pdf.addImage(page.toDataURL('image/jpeg', 1.0), 'JPEG', 10, 10, a4w, Math.min(a4h, a4w * page.height / page.width)); //添加图像到页面，保留10mm边距
            renderedHeight += imgHeight;
            if (renderedHeight < canvas.height) {
                pdf.addPage(); //如果后面还有内容，添加一个空页
            }
        }
        pdf.save(title + ".pdf");

    });
}

export {
    exportPDF
}