import React, { Component } from 'react'
import { Tabs, Table, Input, Modal, Form, Radio } from 'antd'


const { TabPane } = Tabs
const { Search } = Input
const { confirm } = Modal

const dataSource = [
    {
        key: 'time',
        time: '2020-08-31',
        name: '张三',
        sex: '男',
        idcard: '区域/商圈经理',
        company: '上海XX地产',
        phone: '18392828881',
        pushName: '-'
    }, {
        key: 'time',
        time: '2020-08-31',
        name: '张三',
        sex: '男',
        idcard: '区域/商圈经理',
        company: '上海XX地产',
        phone: '18392828881',
        pushName: '-'
    }, {
        key: 'time',
        time: '2020-08-31',
        name: '张三',
        sex: '男',
        idcard: '区域/商圈经理',
        company: '上海XX地产',
        phone: '18392828881',
        pushName: '张三'
    }
]

const columns = [
    {
        title: '申请时间',
        dataIndex: 'time',
        key: 'time'
    }, {
        title: '姓名',
        dataIndex: 'name',
        key: 'name'
    }, {
        title: '性别',
        dataIndex: 'sex',
        key: 'sex'
    }, {
        title: '身份',
        dataIndex: 'idcard',
        key: 'idcard'
    }, {
        title: '公司',
        dataIndex: 'company',
        key: 'company'
    }, {
        title: '公司',
        dataIndex: 'company',
        key: 'company2'
    }, {
        title: '手机号',
        dataIndex: 'phone',
        key: 'phone'
    }, {
        title: '推荐人',
        dataIndex: 'pushName',
        key: 'pushName'
    }, {
        title: '操作',
        dataIndex: 'option',
        render: (text, record) => <a>编辑</a>
    }
]




const dataSource2 = [
    {
        key: 'time',
        rose: '管理员',
        time: '2020-08-31',
        name: '张三',
        sex: '男',
        idcard: '区域/商圈经理',
        company: '上海XX地产',
        phone: '18392828881',
        pushName: '-'
    }, {
        key: 'time',
        rose: '普通用户',
        time: '2020-08-31',
        name: '张三',
        sex: '男',
        idcard: '区域/商圈经理',
        company: '上海XX地产',
        phone: '18392828881',
        pushName: '-'
    }, {
        key: 'time',
        rose: '普通用户',
        time: '2020-08-31',
        name: '张三',
        sex: '男',
        idcard: '区域/商圈经理',
        company: '上海XX地产',
        phone: '18392828881',
        pushName: '张三'
    }
]


function showConfirm() {
    confirm({
        title: '禁用后，该账号将无法登陆。',
        content: '但该公司和已发布的职位，不受影响',
        okText: '继续',
        cancelText: '取消',
        onOk() {
            console.log('OK');
        },
        onCancel() {
            console.log('Cancel');
        }
    })
}



class Company extends Component {

    constructor () {
        super()
        this.state = {
            visible: false,
            columns2: [
                {
                    title: '姓名',
                    dataIndex: 'name',
                    key: 'name'
                }, {
                    title: '性别',
                    dataIndex: 'sex',
                    key: 'sex'
                }, {
                    title: '身份',
                    dataIndex: 'idcard',
                    key: 'idcard'
                }, {
                    title: '所属公司',
                    dataIndex: 'company',
                    key: 'company'
                }, {
                    title: '角色',
                    dataIndex: 'rose',
                    key: 'rose'
                }, {
                    title: '手机号',
                    dataIndex: 'phone',
                    key: 'phone'
                }, {
                    title: '推荐人',
                    dataIndex: 'pushName',
                    key: 'pushName'
                }, {
                    title: '开通时间',
                    dataIndex: 'time',
                    key: 'time'
                }, {
                    title: '操作',
                    dataIndex: 'option',
                    render: (text, record) => {
                        return (
                            <div>
                                <a className='m-r-16' onClick={showConfirm}>禁用</a>
                                <a onClick={this.handleOpenEdite}>编辑</a>
                            </div>
                        )
                    }
                }
            ]
        }
        this.handleOpenEdite.bind(this)
    }

    handleOpenEdite = e => {
        this.setState({ visible: true })
    }

    handleCancelEdite = e => {
        this.setState({ visible: false })
    }

    render() {
        return (
            <div className='account-page'>
                <Tabs defaultActiveKey={'1'}>
                    <TabPane tab='注册申请' key='1'>
                        <Search
                            className='search-box'
                            placeholder="输入姓名/公司搜索"
                            enterButton="搜索"
                            size="large"
                            onSearch={value => console.log(value)}
                        />
                        <Table dataSource={dataSource} columns={columns} />
                    </TabPane>
                    <TabPane tab='账号管理' key='2'>
                        <Search
                            className='search-box'
                            placeholder="输入姓名/公司搜索"
                            enterButton="搜索"
                            size="large"
                            onSearch={value => console.log(value)}
                        />
                        <Table dataSource={dataSource2} columns={this.state.columns2} />
                    </TabPane>
                </Tabs>

                <Modal 
                    title='编辑账号'
                    visible={this.state.visible} 
                    okText='确认'
                    cancelText='取消'
                    onCancel={this.handleCancelEdite}>
                            
                    <Form layout="vertical">
                        <div>张三 - 上海XX地产 - 区域/商圈经理</div>
                        <div>19388828192</div>
                        <div>推荐人：未填写</div>
                        <Form.Item label='重置密码（不填表示不变）：'>
                            <Input />
                        </Form.Item>
                        <Form.Item label='角色'>
                            <Radio.Group>
                                <Radio value='1'>管理员</Radio>
                                <Radio value='2'>普通用户</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Form>

                </Modal>
            </div>
        )
    }
}

export default Company