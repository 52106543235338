import React, { Component } from "react";
import { connect } from 'react-redux'
import { Tabs, Image,Table, Pagination, Button, message, Modal } from "antd";
import { transactionList, refundList, orderList, refundCancel, transactionOrderInfo, remaining } from '../../api'
import QRCode from 'qrcode-react';
import { initAccount } from '../../store/action'

import "./index.less";

const { TabPane } = Tabs;

const orderState = new Map([
    [0, '待付款'],
    [1000, '充值成功'],
    [2000, '交易取消'],
    [10000, '申请退款'],
    [11000, '已退款'],
]) 

const refundState = new Map([
    [0, '申请中'],
    [100, '处理中'],
    [200, '已退款'],
    [1000, '退款失败'],
    [2000, '已取消'],
]) 

const typeStatus = new Map([
    [-1, '邀约面试服务费'],
    [0, '内推奖金(投递成功)'],
    [200, '内推奖金(推荐成功)'],
    [400, '内推奖金(面试通过)'],
    [500, '内推奖金(实际到岗)'],
    
])

const { confirm } = Modal


class Amount extends Component {

    constructor (props) {
        super(props)
        this.state = {
            refundModal: false,
            defaultActiveKey: "1",
            columns: [
                { title: '流水号',dataIndex: 'sn', key: 'sn'},
                { title: '交易时间', dataIndex: 'createTime', key: 'createTime' },
                {
                  title: '操作用户',
                  dataIndex: 'operatorName',
                  key: 'operatorName',
                  render: (text, record) => {
                        return (
                            <div>
                                <div className="w100">{record.operatorName}</div>
                            </div>
                        )
                    }
                },
                {
                  title: '金额',
                  dataIndex: 'money',
                  key: 'money',
                  render: (text, record) => {
                  return ( <div className="color-red w140">
                      {Number(!record.money ? 0 : record.money).toFixed(2)}
                      {
                          record.status === -1 ? '(限时折扣)' : null
                      }
                      
                      </div> )
                  }
                },
                { 
                    title: '类别', 
                    dataIndex: 'status', 
                    key: 'status',
                    render: (text, record) => {
                        return ( <div className="color-red w140">{typeStatus.get(record.status)}</div> )
                    } 
                },
                { title: '收款方', dataIndex: 'talentName', key: 'talentName' },
                {
                  title: '说明',
                  dataIndex: 'address',
                  key: 'address',
                  render: (text, record) => {
                        return (
                            <div style={{width: '280px'}}>
                                <div>职位：{ record.jobName }</div>
                                <div>求职者：{ record.candidateName }</div>
                                {this.onMes(record)}
                            </div>
                        )
                  }
                },
            ],
            rechargeColumns: [
                { title: '流水号',dataIndex: 'sn', key: 'sn'},
                { title: '创建时间', dataIndex: 'createTime', key: 'createTime' },
                {
                    title: '金额',
                    dataIndex: 'price',
                    key: 'price',
                    render: (text, record) => {
                    return ( <div className="color-green">+{Number(!record.price ? 0 : record.price).toFixed(2)}</div> )
                    }
                },
                {
                  title: '操作用户',
                  dataIndex: 'operatorName',
                  key: 'operatorName',
                  render: (text, record) => {
                        return (
                            <div>
                                <div>{record.operatorName}</div>
                            </div>
                        )
                    }
                },
                {
                  title: '状态',
                  dataIndex: 'address',
                  key: 'address',
                  render: (text, record) => {
                        return (
                            <div>
                               <div className={`color-origin 
                               ${record.orderState === 1000 ? 'green-color' : ''} 
                               ${record.orderState === 2000 ? 'cancel-color' : ''}`}>
                                   { orderState.get(record.orderState) }
                               </div>
                               {
                                   record.orderState === 0 ? 
                                   <div className="color-blue font-recharge cursors" onClick={this.onOpenQr.bind(this, record.id)}>继续付款</div>
                                   :
                                   null
                               }
                            </div>
                        )
                  }
                },
            ],
            refundColumns: [
                { title: '流水号',dataIndex: 'sn', key: 'sn'},
                { title: '创建时间', dataIndex: 'createTime', key: 'createTime' },
                {
                    title: '退款金额',
                    dataIndex: 'price',
                    key: 'price',
                    render: (text, record) => {
                    return ( <div className="color-green">{ Number(!record.price ? 0 : record.price).toFixed(2) }</div> )
                    }
                },
                {
                  title: '操作用户',
                  dataIndex: 'operatorName',
                  key: 'operatorName',
                  render: (text, record) => {
                        return (
                            <div>
                                <div>{ record.operatorName }</div>
                            </div>
                        )
                    }
                },
                {
                  title: '状态',
                  dataIndex: 'address',
                  key: 'address',
                  render: (text, record) => {
                        return (
                            <div>
                                {
                                    record.refundStatus === 0 || record.refundStatus === 100 ? 
                                    <div className="color-origin">{refundState.get(record.refundStatus)}(发起退款后30个工作日完成)</div> : 
                                    <div 
                                        className={`color-origin 
                                        ${record.refundStatus === 2000 ? 'cancel-color' : ''} 
                                        ${record.refundStatus === 200 ? 'green-color' : ''}
                                        ${record.refundStatus === 1000 ? 'red-color' : ''} `}>
                                            {refundState.get(record.refundStatus)}
                                        {
                                            record.refundStatus === 200 ? <span>{ record.refundTime }</span> : null
                                        }   
                                        {
                                            record.refundStatus === 1000 ? <div className="red-color">：{!record.reason ? '-': record.reason}</div> : null
                                        }    
                                    </div>
                                }
                                {
                                    record.refundStatus === 200 ? <div className="cancel-color">请以银行实际到账时间为准</div> : null
                                }

                                {
                                    record.refundStatus === 0 || record.refundStatus === 100 ? (
                                        <div className="color-blue font-recharge">
                                            <span className="mr10 cursors" onClick={this.onToggleRefund.bind(this, record)}>查看退款账户</span>
                                            <span className="cursors" onClick={this.onOpenChexiao.bind(this, record.id)}>撤销退款</span>
                                        </div>
                                    ) : null
                                }

                                {
                                    record.refundStatus === 1000 ? (
                                        <div className="color-blue font-recharge">
                                            <span className="mr10 cursors" onClick={this.onToggleRefund.bind(this, record)}>查看退款账户</span>
                                        </div>
                                    ) : null
                                }
                                
                            </div>
                        )
                  }
                },
            ],
            transaction: {
                data: []
            },
            refundLists : {
                data: []
            },
            orderLists: {
                data: [],
            },
            accountInfo: {},
            money: 0,
            show: false,
            qrUrl: ''
        }
    }

    componentWillMount() {
        this._remaining()
        let r = localStorage.getItem('R')
        if (r) {
            this.onTabChange(3)
            let timers = setTimeout(() => {
                clearTimeout(timers)
                timers = null 
                localStorage.removeItem('R')
            }, 3000)
        }
        this._transactionList()

    }

    //余额
    async _remaining () {
        const res = await remaining();
        const { initMoney } = this.props
        initMoney(res.data)
    }

    onContinuePay (id, money) {
        window.location.href = `/recharge?id=${id}&m=${money}`
    }

    onMes (obj) {
        if (obj.withheld) {
            return '预扣至平台：翻腕平台'
        }
        if (!obj.withheld && obj.withheldRefund) {
            return '已返还至余额'
        }
        if (!obj.withheld && !obj.withheldRefund) {
            return '已发放至推荐者'
        }
    }

    /**
     * 撤销退款
     * @param {*} id 
     */
    async _refundCancel (id) {
        const res = await refundCancel({data: id})
        if (res.code === 0) {
            message.success('撤销退款成功!')
            this._remaining() //刷新余额
            this._refundList()
        } else {
            message.error('撤销退款失败!')
            return
        }
    }

    /**
     * 打开撤销退款申请
     */
    onOpenChexiao(id) {
        confirm({
            title: '温馨提示',
            content: '你确定要撤销退款申请吗?',
            cancelText: '取消',
            okText: '确定',
            onOk: () => {
                this._refundCancel(id)
            },
        })
    }

    onChangeOrderLists (current, pageSize) {
        this._orderList(pageSize, (current - 1) * pageSize)
    }

    /**
     * 充值明细
     */
    async _orderList (limit = 10, start = 0) {
        const res = await orderList({limit, start})
        let obj = {
            data: res.data,
            pageNum: res.pageNum,
            pageSize: res.pageSize,
            totalRecord: res.totalRecord
        }
        this.setState({ orderLists: obj });
    }

    onChangeRefundLists (current, pageSize) {
        this._refundList(pageSize, (current - 1) * pageSize)
    }

    async _refundList () {
        const res = await refundList({limit: 10, start: 0})
        let obj = {
            data: res.data,
            pageNum: res.pageNum,
            pageSize: res.pageSize,
            totalRecord: res.totalRecord,
        }
        this.setState({ refundLists: obj });
    }

    onChangeTransaction (current, pageSize) {
        this._transactionList(pageSize, (current - 1) * pageSize)
    }

    /**
     * 交易明细
     */
    async _transactionList (limit = 10, start = 0) {
        const res = await transactionList({limit, start})
        let obj = {
            data: res.data,
            pageNum: res.pageNum,
            pageSize: res.pageSize,
            totalRecord: res.totalRecord
        }
        this.setState({ transaction: obj });
    }

    onToggleRefund (item) {
        const { refundModal } = this.state 
        this.setState({ refundModal: !refundModal,accountInfo: item })
    }

    onSubRefund () {
        this.setState({ refundModal: false})
    }

    onRecharge () {
        window.location.href = `/recharge`
    }

    amountUI () {
        const { state } = this.props
        return (
            <ul className="amount-box">
                <li>
                    <label>可用余额</label>
                    <Image className="safe-ico" src="https://img.fanwoon.com/anquanguanli%402x.png" />
                    <span className="color-red">资金安全有保障</span>
                </li>
                <li><span className="money color-red">￥{Number(!state.store.money ? 0 : state.store.money).toFixed(2)}</span></li>
                <li>
                    <span className="refund" onClick={this.onRefund.bind(this)}>申请退款</span>
                    <Button className="btn-recharge" onClick={this.onRecharge.bind(this)}>充值</Button>
                </li>
            </ul>
        )
    }

    onTabChange (key) {
        this.setState({defaultActiveKey: key + ''})
        let test = {
            1: () => '',
            2: () => this._orderList(),
            3: () => this._refundList()
        }
        test[key]()
    }

    onRefund () {
        window.location.href = '/refund'
    }

    //充值信息
    async _transactionOrderInfo (id) {
        const res = await transactionOrderInfo({data: id})
        const { orderState } = res.data 
        if (orderState === 1000) {
            message.error("充值成功")
            this.setState({ qrUrl: "", show: false })
            return true
        }
        return false
    }

    onOpenQr (id) {
        let res = localStorage.getItem(id);
        if(res) {
            res = JSON.parse(res)
            this.setState({show: true, qrUrl: res.data})


            let timers = setInterval(() => {
                clearInterval(timers)
                timers= null 
                this._transactionOrderInfo(id)
            }, 2000)


        }
        
        
    }

    onCloseQR() {
        this.setState({show: false, qrUrl: ''})
    }
  
    render() {
        const { columns, rechargeColumns, refundColumns, refundModal, 
            transaction, refundLists, orderLists, accountInfo, show, qrUrl, defaultActiveKey } = this.state
        return (
            <div className="amount-page">
                { this.amountUI() }

                <div className="list-box">
                    <Tabs defaultActiveKey={defaultActiveKey} onChange={this.onTabChange.bind(this)}>
                        <TabPane tab="交易明细" key="1">
                            <Table dataSource={transaction.data} columns={columns} pagination={false} rowKey="id" />
                            <div className='pagination-box'>
                                    {
                                        transaction.totalRecord < 10 ? null : (
                                            <Pagination
                                            total={transaction.totalRecord}/* pageToal */
                                            showSizeChanger
                                            showQuickJumper
                                            showTotal={total => `共计  ${total} 条数据`}
                                            onChange={this.onChangeTransaction.bind(this)}
                                            />
                                        )
                                    }
                                </div>
                        </TabPane>
                        <TabPane tab="充值明细" key="2">
                            <Table dataSource={orderLists.data} columns={rechargeColumns} pagination={false} rowKey="id" />
                            <div className='pagination-box'>
                                    {
                                        orderLists.totalRecord < 10 ? null : (
                                            <Pagination
                                            total={orderLists.totalRecord}/* pageToal */
                                            showSizeChanger
                                            showQuickJumper
                                            showTotal={total => `共计  ${total} 条数据`}
                                            onChange={this.onChangeOrderLists.bind(this)}
                                            />
                                        )
                                    }
                                </div>
                        </TabPane>
                        <TabPane tab="退款明细" key="3">
                            <Table dataSource={refundLists.data} columns={refundColumns} pagination={false} rowKey="id"/>
                            <div className='pagination-box'>
                                    {
                                        refundLists.totalRecord < 10 ? null : (
                                            <Pagination
                                            total={refundLists.totalRecord}/* pageToal */
                                            showSizeChanger
                                            showQuickJumper
                                            showTotal={total => `共计  ${total} 条数据`}
                                            onChange={this.onChangeRefundLists.bind(this)}
                                            />
                                        )
                                    }
                                </div>
                        </TabPane>
                    </Tabs>
                </div>

                <Modal 
                    title="查看退款账户" 
                    visible={refundModal} 
                    cancelText="取消" 
                    okText="确定" 
                    onCancel={ this.onToggleRefund.bind(this) } 
                    onOk={ this.onSubRefund.bind(this) }
                    >
                    <div className="refund-box">
                        <div className="header">
                            <Image className="notice-icon" src="https://img.fanwoon.com/redtishi%402x.png" />
                            <ul>
                                <li>退款将以转账方式汇入您填写的银行信息</li>
                                <li>请务必保证下列信息准确，如果退款失败，不会影响账户的余额</li>
                                <li>提交退款后，预计30个工作日内完成退款</li>
                                <li>由于不同银行的政策不同，请以银行实际到账时间为准</li>
                            </ul>
                        </div>
                        <ul className="content">
                            <li>
                                <label>卡户银行：</label><span>{accountInfo.bank}</span>
                            </li>
                            <li>
                                <label>支行名称：</label><span>{accountInfo.subbranch}</span>
                            </li>
                            <li>
                                <label>银行账号：</label><span>{accountInfo.bankAccount}</span>
                            </li>
                            <li>
                                <label>开户人姓名：</label><span>{accountInfo.ownerName}</span>
                            </li>
                            <li>
                                <label>手机号码：</label><span>{accountInfo.ownerPhone}</span>
                            </li>
                            <li>
                                <label>留言：</label><span>{accountInfo.remark}</span>
                            </li>
                        </ul>
                    </div>
                </Modal>
                <Modal visible={show} footer={null} width="240px" onCancel={this.onCloseQR.bind(this)}>
                    <div className='recharge-dialog-title'>翻腕充值交易</div>
                    <div className='recharge-qrcode-box'>
                        <QRCode 
                            size={150}
                            value={qrUrl}
                            logo={`https://img.fanwoon.com/new_logo.png`}
                            logoWidth={50}
                            logoHeight={50}
                        />
                    </div>
                </Modal>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return { state: state};
}

function mapDispatchToProps(dispatch) {
   return {
       initMoney: moeny => dispatch(initAccount(moeny))
   }
}
const App =  connect(
   mapStateToProps,
   mapDispatchToProps
)(Amount)

export default App