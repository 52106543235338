import { Provider, connect } from 'react-redux'
import { createStore } from 'redux'
import storeApp from './store'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Apps from './App';
import * as serviceWorker from './serviceWorker';

const increaseAction = { type: 'show' }

const initState = {
    show: false,
    money: 0,
}

function allAction(state = false, action) {
    switch (action.type) {
        case 'show':
          return state = true;
        default:
          return state = false
    }
}

let store = createStore(storeApp)

/* function mapStateToProps(state) {
  return { heaer: state};
}

function mapDispatchToProps(dispatch) {
  return {
    showHeader: () => dispatch(increaseAction)
  }
}


const App =  connect(
  mapStateToProps,
  mapDispatchToProps
)(Apps)
 */

ReactDOM.render(
  <Provider store={store}>
      <Apps />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
