
/**
 * 初始化金额
 * @param {*} money 
 */
export const initAccount = money => {
  return {
    type: 'INIT_MONEY',
    money: money
  }
}