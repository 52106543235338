import React, { Component } from 'react'
import { Table, Button, Modal, Pagination, message } from 'antd'
import Intro from '../../components/Intro'
import { rewardList, rewardDelete } from '../../api'

const { confirm } = Modal

class Introversion extends Component {

    constructor(props) {
        super(props)
        this.state = {
            from: 0,//0添加 1修改
            modle: {},
            rewardListArray: [],
            showIntro: false,
            register: {
                pageNum: 1,
                pageSize: 10,
                totalPage: 0,
                totalRecord: 0
            },
            columns: [
                {
                    title: '名称',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: '创建人',
                    dataIndex: 'publishName',
                    key: 'publishName',
                },
                {
                    title: '操作',
                    dataIndex: 'option',
                    render: (text, record) => {
                        return (
                            <div>
                                <span className='a-span-btn' onClick={this.handleOpenEdite.bind(this, record)}>编辑</span>
                                {
                                    !record.system ? <span className='a-span-btn' onClick={this.handleDelete.bind(this, record.id)}>删除</span> : null
                                }
                            </div>
                        )
                    }
                }
            ]
        }
        this.handleIntro = this.handleIntro.bind(this)
        this.handlerEwardList = this.handlerEwardList.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount() {
        this.handlerEwardList()
    }

    handleOpenEdite(record) {
        this.setState({ modle: record, showIntro: true, from: 1 })
    }

    handlerEwardList(limit = 10, start = 0) {
        rewardList({ limit, start }).then(res => {
            this.setState({
                rewardListArray: res.data,
                register: {
                    pageNum: res.pageNum,
                    pageSize: res.pageSize,
                    totalPage: res.totalPage,
                    totalRecord: res.totalRecord
                }
            })
        })

    }

    handleChange(current, pageSize) {
        let { searchName } = this.state
        this.handlerEwardList(pageSize, (current - 1) * pageSize, searchName)
    }

    handlerEwardDelete(id) {
        rewardDelete({data: id}).then(res => {
            if (res.code === 0) {
                message.success('内推奖励模版删除成功!')
                this.handlerEwardList()
            } else {
                message.error('内推奖励模版删除失败!')
            }
        })
    }

    handleDelete(id) {
        let _self = this
        confirm({
            title: '删除内推奖金规则模',
            content: '对职位、招聘中的内推奖金计算无影响，你还要继续吗？',
            cancelText: '取消',
            okText: '确认',
            onOk() {
                _self.handlerEwardDelete(id)
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    handleCloseIntro() {
        this.setState({ showIntro: false })
    }
    handleIntro() {
        this.setState({ showIntro: true, modle: null, from: 0 })
    }

    render() {

        const { columns, showIntro, rewardListArray, modle, from, register } = this.state

        return (
            <div className='company-page'>
                <div className='introversion-btn-box'>
                    <Button type='primary' onClick={this.handleIntro}>新建内推奖金规则</Button>
                </div>
                <Table
                    rowKey={(record) => record.id}
                    dataSource={rewardListArray}
                    columns={columns}
                    pagination={false} />
                <div className='pagination-box'>
                    {
                        register.totalRecord > 10 ?
                            <Pagination
                                defaultCurrent={register.pageNum}
                                total={register.totalRecord}
                                onChange={this.handleChange} /> : null
                    }
                </div>
                {
                    showIntro ? <Intro parent={this} visible={showIntro} modle={modle} from={from} /> : null
                }
            </div>
        )
    }
}

export default Introversion