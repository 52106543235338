import React, { Component } from 'react'
import { Modal, Form, Input, Tooltip, Radio, Select, Button, message, Checkbox, Spin } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { 
    listLikenameByCompany,
    register, 
    checkRegisterPhone, 
    findBySearch
} from '../api'
import { debounce } from '../utils'

const validatorShortName = (rule, value) => {
    if (!value) {
        return Promise.reject('请输入线下门店牌匾名，如“翻腕地产(静安店)!')
    }
    if (value.length > 30) {
        return Promise.reject('最多不超过30个字符!')
    }
    return Promise.resolve()
}

const validatorCompany = (rule, value) => {
    if (!value) {
        return Promise.reject('请输入选择公司!')
    }
  //let obj = JSON.parse(value)
    if (value.length > 30) {
        return Promise.reject('最多不超过30个字符!')
    }
    return Promise.resolve()
}

const validatorLeng20 = (rule, value) => {
    if (!value) {
        return Promise.reject('姓名不能为空!')
    } else if (value.length > 20) {
        return Promise.reject('最多不超过20个字符!')
    }
    return Promise.resolve()
}

const validatorOtherJob = (rule, value) => {
    if (!value) {
        return Promise.reject('必须要输入具体的身份!')
    } else if (value.length > 20) {
        return Promise.reject('最多不超过20个字符!')
    }
    return Promise.resolve()
}

const validatorPhone = (rule, value) => {
    let rgx = /^1\d{10}$/;
    if (!value) {
        return Promise.reject('请输入手机号!')
    } else if (value != null && value !== "") {
        if (!rgx.test(value)) {
            Promise.reject("手机号码格式有误，请重填");
        }
    }
    return Promise.resolve()
}

class Register extends Component {

    formRef = React.createRef()

    constructor(props) {
        super(props)
        this.state = {
            showInput: false,
            companyList: [],
            code: false,
            otherName: '',
            phone: '', //临时存储用户输入的手机号
            parent: null, //临时存放父组件实例
            reg: false,//是否注册过
            timer: 30,
            showCode: true,
            fetching: false,
            options: [],
            referrerName: ''
        }
        this.handleRelodCode = this.handleRelodCode.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
    }

    componentDidMount() {
        let { parent } = this.props
        this.setState({parent})
        let timeout = setTimeout(() => {
            clearTimeout(timeout)
            timeout = null
            this.formRef.current.setFieldsValue({ gender: '1' })
            this.formRef.current.setFieldsValue({ jobGrade: '店长' })
        }, 500)
    }

        handleTimes () {
            let siv = window.setInterval(() => {
                this.setState({
                    timer: (this.state.timer -1)
                }, () => {
                    if (this.state.timer === 0) {
                        window.clearInterval(siv)
                        siv = null
                        this.setState({ timer: 30, showCode: true })
                    }
                })
            }, 1000)
        }
    

    handleSearchCompany = debounce ((name) => {
        if (!name) return
        listLikenameByCompany({ limit: 10, start: 0, data: name }).then(res => {
            let data = []
            if (res.data.length === 0) {
                data.push({id: 0, name: name}) 
            } else {
                data = res.data
            }
            this.setState({ companyList:  data})
        })
    }, 1000)

    handleShowImgCode() {
        let { parent } = this.props
        let { reg } = this.state
        
        let phone = this.formRef.current.getFieldValue('phone')
        if (!phone) {
            message.error('请输入手机号!')
            return
        } else if(!(/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(phone))){ 
            message.error("手机号码有误，请重填");  
            return
        } 
        if(reg) {
            message.error("已注册");  
            return
        }
        parent.handleShowCodeModal(1, phone)
    }
    handleRelodCode() {
        this.setState({ code: false })
        setTimeout(() => this.setState({ code: true }), 50)
    }

    handleShowInput = e => {
        let { value } = e.target
        value === '其他' ? this.setState({ showInput: true }) :
            this.setState({ showInput: false, otherName: '' })
    }

    handleCancel() {
        let { parent } = this.props
        parent.handleCancel()
    }

    //检测手机号是否注册
    async handleCheckRegisterPhone(e) {
        let res = await checkRegisterPhone({ data: e.target.value })
        //let { code, message, data } = await checkRegisterPhone({ data: e.target.value })
        if (res.code !== 0) {
            return message.error(res.message)
        }
        if (res.data === 1) {
            this.setState({reg: true})
            return message.error(`已注册`)
        }
        this.setState({reg: false})
    }


    async onFinish(values) {
       
        let { parent } = this.props
      
        if (values.hasOwnProperty('otherjob')) {
            values['jobGrade'] = values['otherjob']
            delete values['otherjob']
        }
        // let company = JSON.parse(values.company)
        values['companyName'] = values['company']
        values['companyId'] = 0 //company.id 不关联，默认0
        delete values['company']
        let referrerName = false
        if (this.state.referrerName) {
            referrerName = this.state.referrerName.target.checked
        }
        if (referrerName && !values['referrer']) {
            return message.error(`请选择推荐人!`)
        } 
        let refer = ''
        if (referrerName && values['referrer']) {
            refer = values['referrer']
            values['omsAccountId'] = values['referrer'].value
            delete values['referrer']
            values['referrer'] = refer['label']
        }
        let { code } = await register(values)
        code !== 0 ? message.error('账号注册失败!') : message.success('账号注册成功!')
        parent.handleCancel()
    }


    async _findBySearch (name) {
        const res = await findBySearch({start: 0, limit: 10,data: name})
        const data = res.data.map((item) => ({label: item.name + `(${item.empNo})`, value: item.id}))
 
        this.setState({options: data, fetching: false})
   }


    debounceFetcher = debounce((name) => {
        if(!name)return
        this.setState({fetching: true})
        this._findBySearch(name)
    }, 1000)



    render() {
        const { showInput, companyList } = this.state
        const { visible, parent } = this.props
        return (
            <div>
                <Modal
                    title='注册翻腕，免费快速招人'
                    visible={visible}
                    footer={null}
                    width='420px'
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
  
                    <div>
                        <Form
                            ref={this.formRef}
                            onFinish={this.onFinish.bind(this)}>

                            <div className='address-local-box'>
                                <div className='w292'>
                                    <Form.Item
                                        name='name'
                                        rules={[{ required: true, validator: validatorLeng20 }]}>
                                        <Input placeholder='姓名(必填)' />
                                    </Form.Item>
                                </div>
                                <div className='w100'>
                                    <Form.Item name='gender' rules={[{ required: true, message: '请输选择性别!' }]}>
                                        <Select className="select-after">
                                            <Select.Option value="1">先生</Select.Option>
                                            <Select.Option value="0">女士</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                            <div className='address-local-box'>
                                <div className='w80'>
                                    <Tooltip className='reg-tooltip-box' placement="topLeft" title='仅展示给求职者，不影响使用产品'>
                                        <label>我是<QuestionCircleOutlined /></label>
                                    </Tooltip>
                                </div>
                                <div>
                                    <Form.Item name='jobGrade' rules={[{ required: true, message: '请选择身份!' }]}>
                                        <Radio.Group onChange={this.handleShowInput}>
                                            <Radio value="店长">店长</Radio>
                                            <Radio value="区域/商圈经理">区域/商圈经理</Radio>
                                            <Radio value="招聘人员">招聘人员</Radio>
                                            <Radio value="其他">其他</Radio>
                                        </Radio.Group>

                                    </Form.Item>
                                </div>
                            </div>
                            {
                                showInput ?
                                    <Form.Item name='otherjob' rules={[{ required: true, validator: validatorOtherJob }]}>
                                        <Input placeholder='请填写你的职位' />
                                    </Form.Item> : null
                            }
                            <Form.Item
                                name='company'
                                rules={[{ required: true, validator: validatorCompany }]}>
                                    <Input  placeholder='公司名称(请填写营业执照上的名称)' />
                            </Form.Item>
                            <Form.Item
                                name='shortName'
                                rules={[{ required: true, validator: validatorShortName }]}>
                                <Input  placeholder='线下门店牌匾名，如“翻腕拉面"' />
                            </Form.Item>
                            <Form.Item
                                name='phone'
                                rules={[{ required: true, validator: validatorPhone }]}>
                                <Input onChange={this.handleCheckRegisterPhone.bind(this)} placeholder='手机号(必填)' />
                            </Form.Item>
                            <Form.Item
                                name='code'
                                rules={[{ required: true, message: '请输入验证码!' }]}>
                                <div className='address-local-box'>
                                    <div className='code-input-box'>
                                        <Input placeholder='验证码' />
                                    </div>
                                    <div>
                                        {
                                            parent.state.showCode2 ? <Button type='primary' onClick={this.handleShowImgCode.bind(this)}>获取验证码</Button> :
                                            <Button className='non-btn'><span id="serveTime">{parent.state.timer}</span>秒后获取验证码</Button>
                                        }
                                    </div>
                                </div>
                            </Form.Item>
                            <div className='regibox'>
                               <div>
                                    <Form.Item>
                                        <Checkbox onChange={(e) => this.setState({referrerName: e})}>我有推荐人</Checkbox>
                                    </Form.Item>
                               </div>
                               <div>
                                    <Form.Item
                                        name='referrer'>
                                        <Select
                                        labelInValue
                                        filterOption={false}
                                        style={{ width: '260px'}}
                                        placeholder='推荐人姓名，填写后会快速审核' 
                                        showSearch
                                        disabled={!this.state.referrerName || !this.state.referrerName.target.checked ? true : false}
                                        onSearch={this.debounceFetcher.bind(this)}
                                        notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
                                        options={this.state.options}
                                        />
                                    </Form.Item>
                               </div>
                            </div>
                            
                            <Form.Item>
                                <Button className='login-form-btn' type="primary" htmlType="submit">
                                    提交申请
                                </Button>
                            </Form.Item>
                            <div>提交后，1个工作日内会审核并为您开通账号</div>
                        </Form>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default Register