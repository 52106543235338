import http from '../utils/axios'

let OMS = ''
let TOB = ''
let base = 'https://api.fanwoon.com'

if (process.env.NODE_ENV === "development") {
    OMS = '/ips'
    TOB = '/api'
} else {
    OMS = base + '/oms'
    TOB = base + '/tob'
}

// 

/**
 * 获取任务详情
 * @param {*} data 
 */
export const taskDetail = (data) => {
    return new Promise((resolve, reject) => {
        let url = TOB + '/task/detail'
        http('post', url, {data}).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 通过名字查询职位
 * @param {data} 
 */
export const listJobLikeName = (data) => {
    return new Promise((resolve, reject) => {
        let url = TOB + '/job/listJobLikeName'
        http('post', url, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 恢复
 * @param {id} 任务编号
 */
export const recover = (data) => {
    return new Promise((resolve, reject) => {
        let url = TOB + '/task/recover'
        http('post', url, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 淘汰
 * @param {id} 任务编号
 * @param {name} 淘汰原因
 */
export const eliminate = (data) => {
    return new Promise((resolve, reject) => {
        let url = TOB + '/task/eliminate'
        http('post', url, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 通过职位和状态和候选人名搜索任务
 * @param {*} data 
 */
export const listByJobAndStatusAndLikeName = (data) => {
    return new Promise((resolve, reject) => {
        let url = TOB + '/task/listByJobAndStatusAndLikeName'
        http('post', url, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 状态字典
 * @param {*} data 
 */
export const taskStatus = () => {
    return new Promise((resolve, reject) => {
        let url = TOB + '/task/status'
        http('post', url).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 统计
 * @param {*} data 
 */
export const countByJob = (data) => {
    return new Promise((resolve, reject) => {
        let url = TOB + '/task/countByJob'
        http('post', url, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

// 

/**
 * @description 完成入职
 * @param {*} data 
 */
export const taskFinish = (data) => {
    return new Promise((resolve, reject) => {
        let url = TOB + '/task/finish'
        http('post', url, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 接受offer-进入入职
 * @param {*} data 
 */
export const taskEntry = (data) => {
    return new Promise((resolve, reject) => {
        let url = TOB + '/task/entry'
        http('post', url, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 进入offer
 * @param {*} data 
 */
export const taskOffer = (data) => {
    return new Promise((resolve, reject) => {
        let url = TOB + '/task/offer'
        http('post', url, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

//
/**
 * 支付浏览并进入面试
 * @param {*} data 
 */
export const browseAndInterview = (data) => {
    return new Promise((resolve, reject) => {
        let url = TOB + '/task/browseAndInterview'
        http('post', url, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 面试环节
 * @param {*} data 
 */
export const interview = (data) => {
    return new Promise((resolve, reject) => {
        let url = TOB + '/task/interview'
        http('post', url, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 职位列表
 * @param {*} data 
 */
export const listJob = (data) => {
    return new Promise((resolve, reject) => {
        let url = TOB + '/job/listJob'
        http('post', url, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}