import React, { Component } from 'react'
import { Modal, Button, Form, Input, message } from 'antd'
import { getValidateCode, sendsms } from '../api'
/* import '../assets/css/job.less' */

class Intro extends Component {

    formRef = React.createRef()

    constructor(props) {
        super(props)
        this.state = { phone: '', path: '' }
        this.handleCancel = this.handleCancel.bind(this)
        this.handleSendsms = this.handleSendsms.bind(this)
    }


    componentDidMount() {
        let { phone } = this.props
        this.setState({ phone })
        this.onReloadCode()
    }

    /**
     * @description 关闭验证图像弹框
     */
    handleCancel() {
        let { parent } = this.props
        parent.handleHideImgCode()
    }

    /**
     * @description 发送短信验证码
     * @param {*} verify 图像验证码
     * @param {*} phone 手机号
     */
    handleSendsms(code) {
        let { phone,  parent, isRegister } = this.props

        if (!isRegister) {
            parent.setState({showCode: false})
            parent.handleTimes() 
        } else {
            parent.setState({showCode2: false})
            parent.handleRegi()
        }

       

        code['phone'] = phone
        sendsms(code).then(res => {
            res.code === 0 ? message.success('短信发送成功!') :
                message.error(res.message)
        }).finally(() => {
            this.handleCancel()
        })
    }

    //刷新验证码
    onReloadCode () {
        const { phone } = this.props
        let path = getValidateCode + '?phone=' + phone + '&verify=' + Math.random()
        this.setState({ path })
      }


    render() {
        const { visible } = this.props
        const { path } = this.state
        return (
            <Modal
                title='请填写图形验证码'
                visible={visible}
                onCancel={this.handleCancel}
                footer={null}
                width={400}
            >
                <Form
                    ref={this.formRef}
                    onFinish={this.handleSendsms}>
                    <div className='code-img-box'>
                        <img className='code-img' alt='' src={path} onClick={this.onReloadCode.bind(this)} />
                    </div>
                    <Form.Item
                        name='verify'
                        rules={[{ required: true, message: '请输入验证码!' }]}>
                        <Input placeholder='请输入验证码' />
                    </Form.Item>

                    <Form.Item>
                        <div className='footer-model'>
                            <Button className='m-r-16' type="primary" htmlType="submit">提交</Button>
                            <Button onClick={this.handleCancel}>取消</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default Intro