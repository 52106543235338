import React, { Component } from 'react'
import './job.less'

class Jobs extends Component {

    info (id) {
        window.location.href = '/info?id=' + id
    }

    render() {
       
        return (
            <div className="app">
                <div className="flex-box header-box">
                    <img  alt="" className="logo" src="https://img.fanwoon.com/new_logo.png" />
                    <ul className="menu">
                        <li><a href="/index">首页</a></li>
                        <li><a href="/jobList">热招职位</a></li>
                        <li><a href="/contant">联系我们</a></li>
                        <li><a href="/templates">下载简历模板</a></li>
                        <li><a href="/login">登录注册</a></li>
                    </ul>
                </div>
                <div>
                    <div className="hot-job">热招职位</div>
                    <div className="job-items" onClick={this.info.bind(this,1)}>
                        <div className="job-title">房产经纪人/房产销售</div>
                        <div className="job-item-info">
                            <div>招聘企业：某上市连锁房产公司</div>
                            <div>发布日期：2021-07-28</div>
                            <div>工作地点：上海-黄浦区</div>
                            <div>月薪：10k-20k</div>
                        </div>
                    </div>
                    <div className="job-items" onClick={this.info.bind(this,2)}>
                        <div className="job-title">门店合伙人</div>
                        <div className="job-item-info">
                            <div>招聘企业：某房产公司</div>
                            <div>发布日期：2021-07-28</div>
                            <div>工作地点：上海-长宁区</div>
                            <div>月薪：15k-30k</div>
                        </div>
                    </div>
                    <div className="job-items" onClick={this.info.bind(this,3)}>
                        <div className="job-title">导购</div>
                        <div className="job-item-info">
                            <div>招聘企业：某时尚服装品牌</div>
                            <div>发布日期：2021-07-28</div>
                            <div>工作地点：上海-静安区</div>
                            <div>月薪：8k-10k</div>
                        </div>
                    </div>
                    <div className="job-items" onClick={this.info.bind(this,4)}>
                        <div className="job-title">快递员（可就近分配）</div>
                        <div className="job-item-info">
                            <div>招聘企业：某上市物流公司</div>
                            <div>发布日期：2021-07-28</div>
                            <div>工作地点：上海-浦东新区</div>
                            <div>月薪：7k-15k</div>
                        </div>
                    </div>
                    <div className="job-items" onClick={this.info.bind(this,5)}>
                        <div className="job-title">店长</div>
                        <div className="job-item-info">
                            <div>招聘企业：某时尚服装品牌</div>
                            <div>发布日期：2021-07-28</div>
                            <div>工作地点：上海-静安区</div>
                            <div>月薪：15k-30k</div>
                        </div>
                    </div>
                    <div className="job-items" onClick={this.info.bind(this, 6)}>
                        <div className="job-title">后厨服务员（工资日结）</div>
                        <div className="job-item-info">
                            <div>招聘企业：某连锁餐饮品牌</div>
                            <div>发布日期：2021-07-28</div>
                            <div>工作地点：上海-徐汇区</div>
                            <div>月薪：5k-6k</div>
                        </div>
                    </div>
                    <div className="job-items" onClick={this.info.bind(this,7)}>
                        <div className="job-title">美甲师</div>
                        <div className="job-item-info">
                            <div>招聘企业：某美容美发机构</div>
                            <div>发布日期：2021-07-28</div>
                            <div>工作地点：上海-宝山区</div>
                            <div>月薪：8k-13k</div>
                        </div>
                    </div>
                </div>

                <div className="footer-box">
                    <span>Copyright © 2022 翻腕</span>
                    <span>沪ICP备2020029704号-1</span>
                    <span>
                        <img alt="" className="beian-icon" src="https://img.fanwoon.com/police.png"/><a target="_blank" className="normal-a" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011302007180">沪公网安备 31011302007180号</a>
                    </span>
                </div>
            </div>
        )
    }
}

export default Jobs