import React, { Component } from 'react'
import RouterHome from './router/index'
import Header from './components/Header'
import 'antd/dist/antd.less'
import './App.css'


class App extends Component {

  constructor () {
    super()
    this.state = {
       show: false
    }
  }

  componentDidMount () {
     this.init()
  }

  init () {
    const url = window.location.href
    if (url.indexOf('/index') > -1 || url.indexOf('/login') > -1 || url.indexOf('/joblist') > -1 || 
    url.indexOf('/contant') > -1 || url.indexOf('/templates') > -1 || url.indexOf('/info') > -1 || 
    url.indexOf('/areement') > -1 || url.indexOf('/download') > -1 || url.indexOf('/flai') > -1) {
        this.setState({ show: false })
    } else {
      this.setState({ show: true })
    }
  }

  paddingNum () {
    const url = window.location.href
    if (url.indexOf('/login') > -1 || url.indexOf('/areement') > -1 || url.indexOf('/download') > -1 
    || url.indexOf('/flai') > -1) {
        return 0
    }
  }

  render() {
   const { show } = this.state
    return (
      <div className="App" style={{paddingTop: this.paddingNum()}}>
        {
          show ? <Header /> : null
        }
        <RouterHome { ...this.props } />
      </div>
    );
  }
}


export default App;
