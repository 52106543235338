import React, { Component } from 'react'
import { BackTop } from 'antd'
import "./index.less"

const style = {
    height: 40,
    width: 40,
    lineHeight: '40px',
    borderRadius: 4,
    backgroundColor: '#FF4949',
    color: '#fff',
    textAlign: 'center',
    fontSize: 14,
  };
  

class Agreement extends Component {
    render() {
       
        return (
            <div className="agreement-page">
                <h2>翻腕用户协议</h2>
                <h3>（本协议于2022年09月21日最新修订）</h3>
                <p>
                    《翻腕用户协议》(以下简称“本协议”)作为翻腕网(以下简称“本网站”)提供服务的依据，确定用户在何种条件、
                    以何种方式使用本网站及本网站的服务(具体载体包括但不限于网页、APP应用程序、微信公众号平台、微信小程序等)。
                    在使用本网站及相关服务前，您应当注册成为本网站用户。进行用户注册时，请您认真阅读本协议，一旦完成注册即表示您已经知悉并了解本协议，
                    接受本协议的条款约束，本协议当即具有合同效力; 如您对本协议的任何条款表示异议，您应当立即停止访问本网站及使用相关服务。
                    用户根据自身招聘需求可以向本网站购买其他付费服务/产品，相关服务/产品的部分或全部利用本网站所提供的互联网信息业务和增值业务
                    (载体包括但不限于网站、APP、微信公众号、微信小程序等)，也应遵守本用户协议的规定。
                </p>
                <p>
                本协议包括基于本协议制定的各项规则，所有规则为本协议不可分割的一部分，与本协议具有同等效力。
                随着平台业务经营的发展及相关政策的变更，本网站用户协议将不时更新，我们会通过在网站公告、APP端推送、
                电子邮件等适当方式提醒您相关内容的更新。您也可以随时访问我们的用户协议页面(https://www.tueneitui.com/areement)来获知最新版本。
                当您继续使用本网站及相关服务，则视为您接受协议的变更，否则您应当停止访问网站及使用服务。
                </p>
                <h4>一、服务描述及定义</h4>
                <p>“我们”指上海往矣科技有限公司。</p>
                <p>
                    “本网站”、“本平台”、“翻腕”、“翻腕”、“翻腕招聘”、“我们的产品”：指上海往矣科技有限公司全权所有并运营的翻腕平台，
                    载体包括但不限于翻腕网站、APP、微信公众号、微信小程序等。
                </p>
                <p>
                     “用户”是指具有完全民事行为能力的翻腕使用者。用户身份包括“招聘方“、“求职者”、“推荐人”。
                </p>
                <p>
                     “招聘方”是指有用人需求，具有用人资格，在本网站发布招聘及相关信息的企事业单位、组织或其合法代理人或其他组织。
                </p>
                <p>“求职者”是指在本网站进行浏览职位信息，投递职位等行为的个人。</p>
                <p>
                    “推荐人”是指在本网站浏览职位、公司信息，并将职位或公司推荐给他人等行为的个人。
                </p>
                <p>
                     “账号”是指用户为使用本网站及相关服务而登记注册的用户身份识别信息。用户只有注册帐号才能使用本网站的服务。
                </p>
                <h4>二、成为注册用户</h4>
                <p>1.用户主体适格</p>

                <p>符合下列条件之一且未曾被本网站封禁账户的自然人或者组织可以注册成为本网站用户，但本网站不保证一定能够通过注册：</p>
                <p>（1）具有完全民事行为能力的自然人。</p>
                <p>
                    （2）符合中华人民共和国法律、行政法规、部门规章等规范性文件要求，合法设立并存续的企业、机关、社团、事业单位和其他组织，
                    并且具有合法用人资格及真实招聘需求（统称为“招聘方”）；
                    代表招聘方进行注册的自然人需具备招聘方的有效授权、并保证为职务行为。
                </p>
                <p>2.用户信息完备</p>
                <p>
                    用户应确保提供注册资料的真实性、准确性、完整性以及合法有效性。用户注册资料发生变更时，用户应及时更新登记资料，保持其真实性及有效性。用户承诺提供以上内容均出于自愿并已得到有效授权，用户需要对自己提供的内容和材料违法导致的法律纠纷承担相应责任。当本网站发现您的注册昵称、个人说明、发布的信息明显违反法律法规、用户协议等情况，本网站有权屏蔽用户的注册昵称、个人说明、发布的信息等，或暂停或终止该用户的帐号，或暂停或终止提供本网站提供的全部或部分服务。（详细规定参见：用户行为限制）
                    本网站将不时增加或删除用户需提交的信息。如用户不同意相关变化或更新，则应当停止使用我们的服务。用户保证以上信息真实、合法、准确，并同意本网站对其注册信息资料进行审查，并就相关疑问或问题进行解答。本网站将在必要的限度内收集、使用这些信息，并会妥善保存、处理这些信息。
                </p>
                <h5>3.平台审核</h5>
                <p>
                招聘方成为注册用户，入驻本网站或完成认证之前，需经过本网站审核。本网站有权要求经营者提供相应的资质文件（包括但不限于营业执照、经营许可证等）。禁止入驻情形：
                </p>
                <p>
                （1）招聘方有以下情形的不得入驻，如入驻之后出现此等情形的，本网站有权对账户进行封禁，对于已支付的服务费用(如有),本网站有权不予退还:招聘方违反法律、行政法规、部门规章、地方性法规等规范性文件，本网站的各项规则和协议以及社会公序良俗的，包括但不限于以下情形：
                </p>
                <p>涉嫌传销、赌博、色情、暴力、邪教与封建迷信、欺诈、非法集资等违法犯罪情形的；</p>
                <p>违法失信企业或法定代表人为失信被执行人的企业；</p>
                <p>
                被市监、公安、税务、工信、网信、人社、外管、商委、海关等行政机关查处或行政处罚，情节严重的；
                </p>
                <p>
                涉嫌拖欠/未按法律规定支付薪资/劳务报酬、虚假招聘，或其他可能损害劳动者合法权益的情形；
                </p>
                <p>
                    涉及其他违反法律、行政法规、部门规章等规范性文件的，以及违反社会公序良俗的。
                </p>
                <p>
                （2）对于上述情形，本网站将参考相关政府部门“黑名单”、用户的举报投诉、新闻媒体曝光、招聘方提供的资质文件或说明文件等，并将依据常识及社会大众通常认知水平做出是否合规的判断而不必须依据公权力机关事后通知、查处、判决等文件。
                </p>
                <p>
                （3）对于涉及经营劳务派遣、猎头业务、招聘业务、职业教育培训业务的用人者，基于安全性、真实性、产品政策、行业竞争、利益冲突等原因，本网站保留拒绝注册或限制使用部分功能的权利。前述主体以普通用户身份注册使用我们的产品下载简历、用户信息等数据的，视为严重违反本合同约定的根本违约行为。
                </p>
                <p>
                4.用户账号、密码及安全
                </p>
                <p>
                用户应自行负责用户名、登录密码、支付密码（如有）、验证码的安全。用户应对通过使用者账户和密码实施的行为负责，不得将上述信息提供给任何第三方使用或从事任何可能使用户名、密码存在泄露危险的行为（包括但不限于在第三方平台授权代理或登录）。因此所衍生的任何损失或损害，我们不承担任何责任，并保留向用户追偿的权利。
                </p>
                <p>当用户发现任何账户的不当使用或有任何其他可能危及账户安全的情形时，应立即通知我们。因黑客行为或用户的保管疏忽导致的帐号、密码泄漏，进而导致其资料、信息泄漏的，由用户承担其不利后果，我们不承担任何责任。</p>
                <h4>三、服务的使用</h4>
                <h5>1.用户应当遵守以下法规</h5>
                <p>1.1 用户在使用本网站时，遵守中华人民共和国相关法律法规，包括但不限于《中华人民共和国合同法》、《中华人民共和国著作权法》、《全国人民代表大会常务委员会关于维护互联网安全的决定》、《中华人民共和国保守国家秘密法》、《中华人民共和国电信条例》、《中华人民共和国计算机信息系统安全保护条例》、《中华人民共和国计算机信息网络国际联网管理暂行规定》及其实施办法、《计算机信息系统国际联网保密管理规定》、《互联网信息服务管理办法》、《计算机信息网络国际联网安全保护管理办法》、《互联网电子公告服务管理规定》、《中华人民共和国网络安全法》等相关中国法律法规的规定。</p>
                <p>1.2 企业用户账号使用限制</p>
                <p>下列情况下，您将无法注册本网站账户，或如您已注册，本网站可无需经过您的同意即可暂时/永久地采取限制您使用本网站账户的措施：</p>
                <p>（1）有投诉或者其他证据证明注册信息不真实，或者有其他违法行为；</p>
                <p>（2）用户大量投诉经查投诉成立或者一年内企业有责任投诉在三次以上；</p>
                <p>（3）有被利用做直销、传销或者其他违法行为的证据或者可能；</p>
                <p>（4）有证据证明企业不具备真实履行招聘的能力；</p>
                <p>（5）您在本网站上发布虚假信息或以明显夸张描述误导公众的；</p>
                <p>（6）账号明显异常，有被盗可能的；</p>
                <p>（7）其他以一般公众视角判断企业面临巨大经营风险的情形或您存在明显违反诚实信用原则的行为。</p>
                <h5>2.用户行为限制</h5>
                <p>2.1 用户的行为（包括但不限于使用本网站过程中的所有行为、针对本网站所进行的任何行为、利用本网站服务进行的后续行为等）不得违反法律、行政法规、部门规章、地方性法规等规范性文件，本网站的各项规则和协议以及社会公序良俗。本网站有权依据常识及社会大众通常认知水平做出是否合规的判断而不必须依据公权力机关事后通知、查处、判决等文件。</p>
                <p>2.2 用户不得在本网站或者利用本网站的服务制作、复制、发布、传播以下信息:反对宪法所确定的基本原则的;危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的;损害国家荣誉和利益的;煽动民族仇恨、民族歧视、破坏民族团结的;破坏国家宗教政策，宣扬邪教和封建迷信的;散布谣言，扰乱社会秩序，破坏社会稳定的;散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的;侮辱或者诽谤他人，侵害他人合法权利的;涉及政治敏感内容的;含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、人身攻击、或其它有悖社会公序良俗令人反感的内容;对本网站可能造成严重负面影响的内容的;含有中国法律、法规、规章、条例以及任何具有法律效力的规范所限制或禁止的其它内容的;其他本网站认为不合适的内容。</p>
                <p>2.3 用户不得破坏网站运营秩序，不得从事与本网站服务无关的行为，不得发布除招聘信息、自身简历所必需信息外的其他内容。</p>
                <p>2.4 用户不得以任何形式骚扰、侮辱、伤害其他用户。</p>
                <p>2.5 用户不得以虚假信息注册账号，不得冒用、盗用、借用他人、关联机构或社会名人的名义注册或使用账号，或以虚伪不实的方式陈述或谎称与任何人或机构有关。</p>
                <p>2.6 用户在未经本网站同意的情况下，不得转让或授权他人使用其所有的本网站账号。</p>
                <p>2.7 用户不得使用任何机器人软件、脚本等其他方式，自动访问或登录本网站；未经许可，不得通过其他第三方工具或运营平台获取本网站服务，包括但不限于通过第三方软件登录本网站账号、发布职位、浏览职位、收发简历等。</p>
                <p>2.8 用户不得以任何手段或形式干扰本网站服务，包括但不限于利用技术手段恶意破坏网站、干扰或破坏与本网站相连线之服务器和网络、大量发布垃圾信息等。</p>
                <p>2.9 用户不得以任何方式对本网站(以及已有或可能发布的相关App、微信公众号、微信小程序等平台服务载体)进行反向工程;未经网站允许，不得采取任何爬虫、抓取、批量检索等手段对公开或非公开网站信息进行复制、收集。</p>
                <p>2.10 用户不得在未经本网站和招聘/找工作行为相对方的同意下，将本网站提供的服务和/或本网站的任何信息、资料以及在招聘找工作过程中任何信息、资料进行转让、出售、传播(包括但不限于以复制、修改、转发、收集、翻译等形式制作复制品、衍生品，或使其公布于众)或其他商业、非商业用途。</p>
                <p>如用户违反上述行为限制要求，本网站有权立即采取下列一个或多个措施:立即停止提供服务;对相关信息予以下线、删除或要求整改;封禁用户账号等。对于用户已支付的服务费用(如有)，本网站有权不予退还。</p>
                <h5>3. 招聘方的特别保证</h5>
                <p>3.1 对于招聘方及其关联单位（包括但不限于分公司、子公司、独立事业部等）入驻本网站并使用招聘相关服务事宜，招聘方承诺并担保如下： 招聘方使用本网站服务仅限用于招聘，承诺不利用服务从事其他事宜，包括但不限于收集求职者数据、简历用作他用，以自身招聘为名招收学员或办理贷款，使用本网站服务从事公司业务经营活动，利用本网站服务中聊天沟通等功能进行广告营销等情形。招聘方成功入驻后，不得出现本协议“禁止入驻情形”条款中所列举的禁止情形。招聘方使用本网站服务，保证严格遵守平台用户协议、平台规则及与本网站签署的一切合同文件（如有）。在使用服务期间，保证不从事任何违反平台规则的业务、不发布任何违反平台规则的信息。</p>
                <p>3.2 如招聘方违反上述承诺，本网站有权立即停止提供服务并对招聘方在本网站上的公司主页和/或发布的信息进行封禁，且对于招聘方已支付的服务费用（如有），本网站有权不予退还。</p>
                <h5>4. 找工作行为</h5>
                <p>4.1 求职者可通过本网站简历模块完善个人简历及相关信息。上述行为均为用户自行操作，本网站不对用户的简历做任何审查、修改或指导。</p>
                <p>4.2 用户找工作，应当如实提供本人基本信息以及应聘岗位相关的知识、技能、工作经历等情况，保证遵守法律、法规对于服务期、从业限制、保密等方面的规定。如前述信息不实或行为违反法律法规的，用户自行承担相应的不利后果，包括被招聘方不予录用或录用后解除劳动关系等。因此给招聘方、本网站或第三人造成损失的，还应承担相应的赔偿责任。</p>
                <p>4.3 本网站将不时举办某些专项活动，用于满足招聘方特殊的招聘需求和求职者的职业发展需求。用户可能需要满足一定的要求，如工作经验、技能证书等，经过本网站一定审查，方可获得参与资格。</p>
                <h5>5.招聘行为</h5>
                <p>5.1 用户(招聘方)通过本平台审核，并依据法律法规及本协议规定提供相关资质的证明等资料后，方可发布招聘信息、进行招聘活动。</p>
                <p>5.2 用户在本网站指定位置(包括本网站各级页面、为招聘方生成的公司主页等)发布招聘相关信息，包括但不限于职位信息、公司简介(包含公司商标、名称、Logo、文字说明等)、配图等。上述行为均为用户自行操作，当用户不便于维护上述信息时，并经过招聘方同意后，可由本网站的运营人员代为操作。</p>
                <p>5.3 招聘方发布的信息除用于招聘所需的必要信息外不得有其他内容，包括但不限于自身产品或服务的推广、销售等。</p>
                <p>5.4 本网站认为有必要的情况下，有权对用户发布的信息做出审查、指导，并有权要求用户做出解释、更正或说明。</p>
                <p>5.5 用户应当确保发布的招聘信息真实合法。用户招聘信息不实或违规的，用户自行承担相应的不利后果。因此给求职者、本网站或第三人造成损失的，还应承担相应的赔偿责任。涉及违法或构成刑事犯罪的，依据法律法规承担相应的责任。</p>
                <p>5.6 用户发布招聘信息不得涉及以下内容：涉及性别、地域等各类歧视；涉及夸张性描述、夸大性承诺或虚假宣传；职位内容重复描述、无意义描述；多个职位相似；职位要求与职位描述不一致；侮辱、诽谤、骚扰、诋毁、攻击第三人的；招聘信息虚假；与招聘无关；其他可能违反社会公序良俗或伤害求职者权益的情形。如用户违反上述要求，本网站有权立即采取下列一个或多个措施：立即停止提供服务；对相关信息予以下线、删除或要求整改；封禁用户账号等。</p>
                <p>5.7 招聘者在发布职位时，为了提高职位曝光效果，可以设置内推奖金，该奖金均由招聘者线下发放，与本网站无关，本网站仅根据招聘者设置的奖金规则进行计算与记录，如果招聘者与推荐人针对内推奖金产生任何纠纷，需招聘者与推荐人双方自行解决，本平台不承担任何法律责任。</p>
                <h5>6.内推行为</h5>
                <p>6.1 推荐者可将招聘方发布在本网站的职位以及企业信息转发给有意向的其他求职者，转发时不得篡改招聘方发布在本网站的原信息。</p>
                <p>6.2 当推荐者成功帮助企业推荐求职者后，如果相应职位有推荐奖金或奖励，除非特别说明，均与本网站无关，本网站仅做展示与记录。</p>
                <p>6.3 本网站中展示给推荐人所示的推荐记录与奖金计算，均仅作为参考，最终是否发放、发放数额均以招聘者实际发放为准，相关招聘者发布职位时所设置的推荐奖金的最终解释权归招聘者所有。</p>
                <h5>7.推送消息</h5>
                <p>您谨此同意，在您使用我们的产品和服务时，您可能会收到推送信息，这些推送信息包括我们的产品或服务的更新内容以及可能对您有用的关于第三方产品或服务的信息等。</p>
                <p>我们特别提醒您，如果您收到的推送信息是关于第三方而非我们的，对于此类信息，您应当自行判断其内容的真实性并为自己的判断行为负责。除法律明确规定外，您因此类信息提供的内容而遭受的任何损失或损害，我们均不承担任何责任。</p>
                <h5>8.信息存储和限制</h5>
                <p>我们将会尽其商业上的合理努力保障用户在本网站服务中的数据存储安全，但是，我们并不能就此提供完全保证，包括但不限于以下情形：</p>
                <p>8.1 我们不对用户在本网站服务中相关数据的删除或存储失败负责；</p>
                <p>8.2 我们有权根据实际情况自行决定单个用户在服务器中数据的最长储存期限，并在服务器上为其分配数据最大存储空间等。用户可以根据自己的需要，自行备份服务中的相关数据。通过服务存储或传送之任何信息、通讯资料和其他内容，如被删除或未予储存，我们不承担任何责任。</p>
                <p>8.3 由于不可抗拒因素可能引起的个人信息丢失、泄露等问题，我们不承担责任，由用户自行承担风险。</p>
                <p>8.4 用户应当理解并同意，长时间未使用的帐号，我们有权关闭。同时我们有权自行决定并无论通知用户与否，随时变更这些一般措施及限制。</p>
                <h5>9. 用户间保密</h5>
                <p>用户对利用本网站服务进行找工作和招聘活动，从而获取的其他用户的任何信息，负有保密的义务。保密的程度依据法律法规、用户间的合意以及一般人的合理注意义务决定。</p>
                <h5>10.推广和宣传</h5>
                <p>除用户特别要求且我们接受该要求外，我们有权在不做出任何通知的情况下，在本网站页面或者与本网站有关的市场宣传、推广材料上公开使用用户的公开信息，包括但不限于名称、商号、商标、标语、用户已经公开的个人信息等。我们承诺上述信息不用于与本网站服务无关的事项上。</p>
                <h4>四、服务的中断或终止</h4>
                <p>1. 用户在使用本网站服务的所有行为，产生的所有信息，必要情况下本网站有权依据法律法规、社会公序良俗、本网站的规则和协议，做出审查。本网站有权中断或终止对违规用户的服务。该用户以其他名义再次注册账户的，本网站有权不予审核通过。</p>
                <p>2. 用户申请终止服务的，经本网站同意后可以解除该用户协议。</p>
                <p>3. 本网站中断或终止对用户提供服务后，根据相关法律规定，仍需继续保留用户在本网站的资料和信息(包括但不限于用户的账号信息、交易信息等)。</p>
                <p>4. 中断或终止对用户的服务并不免除用户因违反法律法规、社会公序良俗、本网站的规则和协议，而对本网站、网站其他用户或第三方应负的损害赔偿责任。</p>
                <h4>五、免责条款</h4>
                <p>1. 用户明确同意其使用本网站服务所存在的风险将完全由其自己承担；用户理解并同意我们对以下情况免责：</p>
                <p>1.1 对由于政府禁令、现行生效的适用法律或法规的变更、火灾、地震、动乱、战争、停电、通讯线路中断、黑客攻击、计算机病毒侵入或发作、电信部门技术调整、因政府管制而造成服务的暂时性关闭等任何影响网络正常运营的不可预见、不可避免、不可克服和不可控制的事件（“不可抗力事件”），以及他人蓄意破坏，正常的系统维护、系统升级，或者因网络拥塞而导致本网站不能访问而造成的所提供的信息及数据的延误、停滞或错误，以及使用者由此受到的一切损失不承担任何责任；</p>
                <p>1.2 我们并不能实时监视本网站服务内用户上传的所有内容，但保留进行随时监视的权利。对于非本网站公布的材料，我们一概不负任何责任。</p>
                <p>1.3 我们虽然对用户进行资质审查，但并非司法机关，仅能要求用户提交真实、有效的资质证明文件，并对该提交的资质证明文件进行审核。如用户提交虚假、伪造、编造文件的，我们对此概不承担法律责任。</p>
                <p>1.4 本网站仅为信息发布平台，任何通过本网站发布的信息及其他产品或者服务均系用户自行发布，我们无法保证其真实性、有效性、准确性、合法性，由此引发的法律责任皆由上传用户承担。</p>
                <p>1.5 用户在通过本网站得到资讯和信息后，与信息发布人所进行的任何交易均系其双方自主交易，我们不对用户的线下行为负责。双方若发生纠纷，皆与我们无关，我们不承担任何法律责任；我们对于用户由于使用本网站而造成的任何金钱、商誉、名誉的损失，或任何特殊的、间接的、或结果性的损失都不负任何责任。</p>
                <p>1.6 我们无法保证某一招聘信息会有一定数量的用户来浏览，也不能保证会有您期待的用户来浏览；您应当理解并同意本网站无法保证某一职位发布后一定产生招聘效果；也无法保证达到某一求职者预期的求职结果。我们并不保证所有信息、文本、图形、链接及其它项目的绝对准确性和完整性，故仅供用户参考使用。</p>
                <p>1.7 对于我们为使用者提供便利而设置的外部链接网址，我们并不保证其准确性、安全性和完整性，亦并不代表我们对其链接内容的认可，请使用者谨慎确认后使用，我们对由此导致的任何损失或伤害不承担任何责任。与本网站链接的其他网站或用户所使用的网络运营商所造成之个人资料泄露及由此而导致的任何法律争议和后果均由该网站或用户所使用的网络运营商负责。</p>
                <h5>2. 用户间纠纷</h5>
                <p>2.1 求职者与招聘方之间的线下活动，包括沟通、面试、入职、建立劳动关系等，不受本网站的约束。双方发生纠纷的，依据法律法规、双方合同处理，本网站不负责处理。</p>
                <p>2.2 本网站提供用户反馈渠道，包括网站特定模块或功能，用户可按特定方式表达、反映用户间的纠纷，本网站将尽快处理这些问题，但所做出的答复可能不具有法律约束力，用户仍然可以按照法律法规规定将纠纷提交相关公权力机关。</p>
                <h4>六、协议的更新及修改</h4>
                <p>我们保留随时修正、更新本协议的权利。一旦发生相关修订或更新，我们会将修订和更新的内容及时发布，用户如无法接受变更后的协议内容，应该停止使用本网站相关服务。如您登录或继续使用本网站相关服务的，则视为用户接受变更条款并愿意受其约束。</p>
                <h4>八、其他</h4>
                <p>1. 本网站的所有权、运营权、解释权归上海往矣科技有限公司所有。本网站拥有对本协议的最终解释权。</p>
                <p>2. 用户与本网站的任何纠纷，可以通过协商的途径解决。协商不成的，任何一方可向我们住所地有管辖权的法院诉讼解决。</p>
                <p>3. 本协议的订立、生效、解释、执行、管辖、争议的解决均适用中华人民共和国法律。</p>
                <BackTop>
                     <div style={style}>回到顶部</div>
                </BackTop>
            </div>
        )
    }
}

export default Agreement