import React, { Component } from 'react'
import './index.less'

import barnner from './img/banner.png'
import fuzhi from './img/fuzhi@2x.png'
import hailianganli from  './img/hailianganli@2x.png'
import zhenshikexin from './img/zhenshikexin@2x.png'
import cbd3b4f from './img/cbd3b4fb8ef7465883be78c48eff1f63@2x.png'
import weibiao from './img/weibiaoti38@2x.png'
import weibiaoti38 from './img/weibiaoti38@2x.png'
import weibiaoti382 from './img/weibiaoti38@2x.png'
import weibiaoti384 from './img/weibiaoti38@2x.png'


class Homes extends Component {



    render() {
       
        return (
            <div className="app">
            <div className="flex-box header-box">
                <img alt="" className="logo" src="https://img.fanwoon.com/new_logo.png" />
                <ul className="menu">
                    <li><a href="/index">首页</a></li>
                    <li><a href="/joblist">热招职位</a></li>
                    <li><a href="/contant">联系我们</a></li>
                    <li><a href="/templates">下载简历模板</a></li>
                    <li><a href="/login">登录注册</a></li>
                </ul>
            </div>
            <div className="banner-box">
                <img alt="" src={barnner} />
            </div>
            <div className="section-1 flex-box">
                <div className="item">
                    <div>
                        <img alt="" src={fuzhi} />
                    </div>
                    <div>海量简历</div>
                    <div>挑到手软</div>
                </div>
                <div className="item">
                    <div>
                        <img alt="" src={hailianganli} />
                    </div>
                    <div>服务透明</div>
                    <div>收费合理</div>
                </div>
                <div className="item">
                    <div>
                        <img alt="" src={zhenshikexin} />
                    </div>
                    <div>简历真实</div>
                    <div>愿意度高</div>
                </div>
            </div>
            <div className="flex-section">
                <div>
                    <img alt="" className="section2-pic" src={cbd3b4f} />
                </div>
                <div className="section-2-info">
                    <h3>合作流程</h3>
                    <ul className="section-list">
                        <li>
                            <img alt="" src={weibiao} />
                            <span>深度沟通，了解并梳理门店招聘需求</span>
                        </li>
                        <li>
                            <img  alt="" src={weibiaoti38} />
                            <span>开通线上账号，帮助客户发布招聘需求</span>
                        </li>
                        <li>
                            <img alt="" src={weibiaoti382} />
                            <span>转发职位，推荐求职者</span>
                        </li>
                        <li>
                            <img alt="" src={weibiaoti384} />
                            <span>录用人选，快速上岗报到</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="footer-box">
                <span>Copyright © 2022 翻腕</span>
                <a href='https://beian.miit.gov.cn/'>沪ICP备2020029704号-1</a>
                <span>
                    <img alt="" className="beian-icon" src="https://img.fanwoon.com/police.png"/><a target="_blank" className="normal-a" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011302007180">沪公网安备 31011302007180号</a>
                </span>
            </div>
        </div>
        )
    }
}

export default Homes