import http from '../utils/axios'

let OMS = ''
let TOB = ''
let base = 'https://api.fanwoon.com'
const REACT_APP_TOB = process.env.REACT_APP_TOB

if (process.env.NODE_ENV === "development") {
    OMS = '/ips'
    TOB = '/api'
} else {
    OMS = base + '/oms'
    TOB = base + '/tob'
}


/**
 * @description 职位列表
 * @param {*} data 
 */
export const listJob = (data) => {
    return new Promise((resolve, reject) => {
        let url = TOB + '/job/listJob'
        http('post', url, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

//  

/**
 * @description 内推奖励
 * @param {*} data 
 */
export const listByTalentIdAndCompanyId = (data) => {
    return new Promise((resolve, reject) => {
        let url = TOB + '/reward/log/listByTalentIdAndCompanyId'
        http('post', url, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 通过申请
 * @param {*} data 
 */
export const pass = (data) => {
    return new Promise((resolve, reject) => {
        let url = TOB + '/talent/pass'
        http('post', url, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 不通过申请
 * @param {*} data 
 */
export const notPass = (data) => {
    return new Promise((resolve, reject) => {
        let url = TOB + '/talent/notPass'
        http('post', url, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 搜索伯乐状态字典
 * @param {*} data 
 */
export const listStatus = () => {
    return new Promise((resolve, reject) => {
        let url = TOB + '/talent/listStatus'
        http('post', url).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 搜索伯乐(不搜索名字name给空，不限状态status不添加)
 * @param {*} data 
 */
export const talentSearch = (data) => {
    return new Promise((resolve, reject) => {
        let url = TOB + '/talent/search'
        http('post', url, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}