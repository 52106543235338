import React, { Component } from 'react'
import './templates.less'

import pic1 from './img/1@2x.png'
import pic2 from './img/2@2x.png'
import pic3 from './img/3@2x.png'
import pic4 from './img/4@2x.png'
import pic5 from './img/5@2x.png'
import pic6 from './img/6@2x.png'
import pic7 from './img/7@2x.png'
import pic8 from './img/8@2x.png'

class Templates extends Component {

    render() {
       
        return (
            <div className="app">
                <div className="flex-box header-box">
                    <img alt="" className="logo" src="https://img.fanwoon.com/new_logo.png" />
                    <ul className="menu">
                        <li><a href="/index">首页</a></li>
                        <li><a href="/jobList">热招职位</a></li>
                        <li><a href="/contant">联系我们</a></li>
                        <li><a href="/templates">下载简历模板</a></li>
                        <li><a href="/login">登录注册</a></li>
                    </ul>
                </div>
        
                <div className="flex-box-list">
                    <div className="item">
                        <img alt="" src={pic1} />
                        <div><a href="/login">下载此模板</a></div>
                    </div>
                    <div className="item">
                        <img alt="" src={pic2} />
                        <div><a href="/login">下载此模板</a></div>
                    </div>
                    <div className="item">
                        <img alt="" src={pic3} />
                        <div><a href="/login">下载此模板</a></div>
                    </div>
                    <div className="item">
                        <img alt="" src={pic4} />
                        <div><a href="/login">下载此模板</a></div>
                    </div>
                    <div className="item">
                        <img alt="" src={pic5} />
                        <div><a href="/login">下载此模板</a></div>
                    </div>
                    <div className="item">
                        <img alt="" src={pic6} />
                        <div><a href="/login">下载此模板</a></div>
                    </div>
                    <div className="item">
                        <img alt="" src={pic7} />
                        <div><a href="/login">下载此模板</a></div>
                    </div>
                    <div className="item">
                        <img alt="" src={pic8} />
                        <div><a href="/login">下载此模板</a></div>
                    </div>
                </div>
        
        
                <div className="footer-box">
                    <span>Copyright © 2022 翻腕</span>
                    <span>沪ICP备2020029704号-1</span>
                    <span>
                        <img alt="" className="beian-icon" src="https://img.fanwoon.com/police.png" /><a target="_blank" className="normal-a" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011302007180">沪公网安备 31011302007180号</a>
                    </span>
                </div>
            </div>
        )
    }
}

export default Templates