import React, { Component } from 'react'
import { Form, Input, Select, Checkbox, 
    Tooltip, Tag, Switch, Cascader, Button, message, InputNumber, Modal, Image } from 'antd'
/* import '../../assets/css/job.less' */
import { QuestionCircleOutlined, PlusOutlined, CheckCircleOutlined } from '@ant-design/icons'
import {
    educationList, 
    workExpList, 
    edcation, 
    provinceMap,
    rewardList, 
    industryList, 
    createJob, 
    jobDetail, 
    jobUpdate, 
    listlikeName,
    jobHighLights,
    cityGet,
    circlesParent
} from '../../api'

import './index.less'

let createHistory = require('history').createBrowserHistory
const history = createHistory()
const { Option } = Select

let blackData = []
let selectBlack = []

class Publish extends Component {

    formRef = React.createRef()

    constructor(props) {
        super(props)
        this.state = {
            negotiable: false, //是否面议
            isQuick: false, // 是否快速复制
            isDisabled: false,
            jobInfo: {},
            jobTypeStr: '',
            workExperienceId: '',
            workExperienceMax: '',
            workExperienceMin: '',
            workExperienceName: '',
            educationId: '',
            educationName: '',
            rewardListArray: [],
            educationArray: [],
            provinceMapList: [],
            workExpListArray: [],
            educationListArray: [],
            listOpenArray: [], //招聘中的职位
            industryListArray: [], //行业树
            tags: [],
            inputVisible: false,
            inputValue: '',
            editInputIndex: -1,
            editInputValue: '',
            recommendReward: true, // 是否有推荐奖励
            showLight: false,
            lightList: [], //字典
            lightList2: [],
            selectLight: [],
            lightArry: [],
            lightDefArry: [],
            lightIdArry: [],
        }
        this.handleGetJobDetail = this.handleGetJobDetail.bind(this)
    }

    componentDidMount() {
        this.handleEducationList()
        this.handleWorkExpList()
        this.handleEdcation()
        this.handleProvinceMap()
        this.handleListOpen()
        //this.handlerEwardList()
        this.handleIndustryList()
        this._jobHighLights()

        this.formRef.current.setFieldsValue({ education: '不限' })
        this.formRef.current.setFieldsValue({ workExperienceName: '不限' })
        let { id } = this.props.match.params
        if (id != null && id !== "") {
            this.handleGetJobDetail()
        }
    }

    async _jobHighLights () {
        const res = await jobHighLights()
        const arry = []
        res.data.forEach(item => {
            let obj = { label: item.name, value: item.id }
            arry.push(obj)
        })
        this.setState({lightList: res.data, lightList2: arry})
    }

    handleGetFunctionInfo(id) {
        let { industryListArray } = this.state

        let array = []

        industryListArray.forEach(item => {
            item.children.forEach(ite => {
                if (ite.id === id) {
                    array.push(item.id, ite.id)
                    this.setState({ jobTypeStr: item.name + '/' + ite.name })
                }
            })
        })
        return array
    }

    //职位详情
    handleDetial(id) {
        this.setState({ isQuick: true })
        this.handleGetJobDetail(id)
    }

    async initCircles (id) {
        const res = await circlesParent(id)
        let mapData = this.state.provinceMapList
            mapData.map((item) => {
                item['isLeaf'] = true
                item.children.map(ite => {
                    ite['isLeaf'] = true
                    ite.children.map(it => {
                        it['isLeaf'] = false
                        if (it.id === id) {
                            it['children'] = res.data
                        }
                    })
                })
            })
            this.setState({ provinceMapList: mapData })
    }

    handleGetJobDetail(number) {
        let { id } = this.props.match.params
        let params = {}
        !number ? params['data'] = id : params['data'] = number
        let { isQuick } = this.state

        jobDetail(params).then(res => {
            if (isQuick) {
                delete res.data['id']
            }

            let { address, description, salaryMin, salaryMax,
                provinceId, cityId, districtId,circlesId, highlights, highlightsId, functionId,
                recommendRewardRole, commission, workExperienceId, name,
                education, educationId, recommendReward, negotiable } = res.data

           /*  if() {
                lightList2
            }     */

            let cityArry = [provinceId, cityId, districtId, circlesId]
            let strArry = []
            if (highlights != null && highlights !== "") {
                let nameArry = JSON.parse(highlights)
               
                let idArry = JSON.parse(highlightsId)
                let arry = []
                idArry.forEach((ite, index) => {
                     let obj = { id: ite, name: nameArry[index] }
                     arry.push(obj)   
                     strArry.push(nameArry[index])
                })
                this.setState({ lightArry: arry, lightDefArry:   strArry}) 
            }
            this.formRef.current.setFieldsValue({ highlights: strArry })
            this.setState({ recommendReward, isDisabled: !recommendReward, negotiable })
            if (recommendRewardRole != null && recommendRewardRole !== "") {
                let RRRole = JSON.parse(recommendRewardRole)
                this.formRef.current.setFieldsValue({ role: RRRole[0] })
                this.formRef.current.setFieldsValue({ role2: RRRole[200] })
                //this.formRef.current.setFieldsValue({ role3: RRRole[300] })
                this.formRef.current.setFieldsValue({ role4: RRRole[400] })
            }
            let timeout = setTimeout(() => {
                clearTimeout(timeout)
                timeout = null
                let array = this.handleGetFunctionInfo(functionId)
                this.formRef.current.setFieldsValue({ function: array })
            }, 500)
            this.setState({
                educationName: education,
                educationId: educationId
            })
            this.formRef.current.setFieldsValue({ education: education })
            this.formRef.current.setFieldsValue({ workExperienceName: workExperienceId })
            this.formRef.current.setFieldsValue({ commission })
            this.formRef.current.setFieldsValue({ name })
            this.formRef.current.setFieldsValue({ address })
            this.formRef.current.setFieldsValue({ description })
            this.formRef.current.setFieldsValue({ salaryMin })
            this.formRef.current.setFieldsValue({ salaryMax })
            this.initCircles(districtId)
            //this.formRef.current.setFieldsValue({ city: cityArry }) 110000, 110100, 110101, 626
            this.formRef.current.setFieldsValue({ city: cityArry })
            this.setState({ jobInfo: res.data})
        })
    }

    handleIndustryList() {
        industryList().then(res => {
            this.setState({ industryListArray: res.data })
        })
    }

    handlerEwardList(limit = 10, start = 0) {
        rewardList({ limit, start }).then(res => {
            this.setState({
                rewardListArray: res.data
            })
        })

    }

    handleProvinceMap() {
        //this.initCircles(110101)
        provinceMap().then(res => {
            res.data.map((item) => {
                item['isLeaf'] = true
                item.children.map(ite => {
                    ite['isLeaf'] = true
                    ite.children.map(it => {
                        it['isLeaf'] = false
                    })
                })
            })
            this.setState({ provinceMapList: res.data })
        })
    }

    async handleListOpen(limit = 50, start = 0, name = '') {
        let { data } = await listlikeName({ limit, start, data: name })
        this.setState({ listOpenArray: data })
    }

    async handleEdcation() {
        let { data } = await edcation()
        data.forEach(res => {
            if (res.name === '不限') {
                this.setState({
                    educationName: res.name,
                    educationId: res.id
                })
                return
            }
        })
        this.setState({ educationListArray: data })
    }

    async handleWorkExpList() {
        let { data } = await workExpList()
        data.forEach(res => {
            if (res.name === '不限') {
                this.setState({
                    workExperienceId: res.id,
                    workExperienceMax: res.workExperienceMax,
                    workExperienceMin: res.workExperienceMin,
                    workExperienceName: res.name,
                })
            }
        })
        this.setState({ workExpListArray: data })
    }

    async handleEducationList() {
        let { data } = await educationList()
        this.setState({ educationArray: data })
    }

    handleClose = removedTag => {
        const tags = this.state.tags.filter(tag => tag !== removedTag);
        this.setState({ tags });
    };

    showInput = () => {
        this.setState({ inputVisible: true }, () => this.input.focus());
    };

    handleInputChange = e => {
        this.setState({ inputValue: e.target.value });
    };

    handleInputConfirm = () => {
        const { inputValue } = this.state;
        let { tags } = this.state;
        if (inputValue && tags.indexOf(inputValue) === -1) {
            tags = [...tags, inputValue];
        }
        this.setState({
            tags,
            inputVisible: false,
            inputValue: '',
        });
    };

    handleEditInputChange = e => {
        this.setState({ editInputValue: e.target.value });
    };

    handleEditInputConfirm = () => {
        this.setState(({ tags, editInputIndex, editInputValue }) => {
            const newTags = [...tags];
            newTags[editInputIndex] = editInputValue;

            return {
                tags: newTags,
                editInputIndex: -1,
                editInputValue: '',
            };
        });
    };

    saveInputRef = input => {
        this.input = input;
    };

    saveEditInputRef = input => {
        this.editInput = input;
    }

    /**
     * @description 获取省市区各个的编号以及名称
     * @param {*} cityMap 
     */
    handleGetCityInfo(cityMap) {
        let { provinceMapList } = this.state
        let provinceId = ''
        let province = '' //省
        let cityId = '' //市id
        let city = ''
        let districtId = ''
        let district = ''

        provinceMapList.forEach((p, index) => {
            if (p.id === cityMap[0]) {
                province = p.name
                provinceId = p.id
            }
            p.children.forEach((c, cindex) => {
                if (c.id === cityMap[1]) {
                    city = c.name
                    cityId = c.id
                }
                c.children.forEach((d, dindex) => {
                    if (d.id === cityMap[2]) {
                        district = d.name
                        districtId = d.id
                    }
                })
            })
        })
        return { provinceId, province, cityId, city, districtId, district }
    }

    handleGetJobTypeInfo(array) {
        let { industryListArray } = this.state
        let functio = ''
        let functionId = ''

        industryListArray.forEach((p, index) => {
            p.children.forEach((c, cindex) => {
                if (c.id === array[1]) {
                    functio = c.name
                    functionId = c.id
                }
            })
        })
        return { function: functio, functionId }
    }

    //修改工作经验的值
    handleChangeWork(val) {
        let { workExpListArray } = this.state
        workExpListArray.forEach(item => {
            if (item.id === val) {
                this.setState({
                    workExperienceId: item.id,
                    workExperienceMax: item.workExperienceMax,
                    workExperienceMin: item.workExperienceMin,
                    workExperienceName: item.name,
                })
            }
        })
    }
    handleChangeEdu(val) {
        let { educationListArray } = this.state
        educationListArray.forEach(item => {
            if (item.id === val) {
                this.setState({
                    educationId: item.id,
                    educationName: item.name,
                })
            }
        })
    }
    async onFinish(values) {
        let { city, role, role2, role3, role4 } = values
        let { recommendReward, educationName, educationId, workExperienceId,
            workExperienceMax, workExperienceMin, workExperienceName,
            tags, jobInfo, negotiable, isQuick, lightArry, lightIdArry, lightList } = this.state

        let rules = {}
        let data = {}
        if (!jobInfo['id'] || isQuick) {
            let citys = this.handleGetCityInfo(city)
            let funs = this.handleGetJobTypeInfo(values['function'])
            delete values['city']
            data = Object.assign(values, citys)
            data = Object.assign(data, funs)
        } else {
            data = Object.assign(data, values)
        }

        data['status'] = 1
        data['negotiable'] = negotiable
        data['recommendReward'] = recommendReward
        data['education'] = educationName
        data['educationId'] = educationId
        data['workExperienceId'] = workExperienceId
        data['workExperienceMin'] = workExperienceMin
        data['workExperienceMax'] = workExperienceMax
        data['workExperienceName'] = workExperienceName

       // 

        if (recommendReward) { // 当推荐奖金规则打开时，可设置推荐奖金
            if (role != null && role !== '') rules['0'] = role
            if (role2 != null && role2 !== '') rules['200'] = role2
            if (role3 != null && role3 !== '') rules['300'] = role3
            if (role4 != null && role4 !== '') rules['400'] = role4

            if (!role && !role2 && !role3 && !role4) {
                rules = ''
            }
            data['recommendRewardRole'] = !rules ? '' : JSON.stringify(rules)
        }

        if(data['salaryMin'] > data['salaryMax'])return message.error('最小薪水不能大于最大薪水!')

        delete data['role']
        delete data['role2']
        delete data['role3']
        delete data['role4']


        if (lightIdArry.length > 0) {
            let namearry = []
            lightList.forEach(item => {
                lightIdArry.forEach(ite => {
                    //let obj = { label: item.name, value: item.id }
                     if (item.name === ite) {
                        namearry.push(Number(item.id))
                     }
                })
            })
            data['highlights'] = JSON.stringify(lightIdArry)
            data['highlightsId'] = JSON.stringify(namearry)

        }else if (lightArry.length > 0) {
            let ligArry = []
            let ligIdrry = []

            ligIdrry = lightArry.map(item => Number(item.id))
            ligArry = lightArry.map(item => item.name)

            data['highlights'] = JSON.stringify(ligArry)
            data['highlightsId'] = JSON.stringify(ligIdrry)
        }
        
        if (data['highlights'] && data['highlights'].constructor === Array && data['highlights'].length === 0) {
            data['highlights'] = ""
        } else if (data['highlights'] === "[]") {
            data['highlights'] = ""
        }

        if (isQuick) {//因为是复制过来的，所以要把之前的id删除.
            delete jobInfo['id']
        }
        if (!jobInfo['id']) {
            const obj = this.getCircles()
            if (!obj) {
                data['circlesId'] = jobInfo.circlesId
                data['circles'] = jobInfo.circles
                data['cityId'] = jobInfo.cityId
                data['city'] = jobInfo.city
            } else {
                data['circlesId'] = obj.id
                data['circles'] = obj.name
            }
        }
       /*  console.log(data)
        return */
        if (!jobInfo['id']) {
            let { code } = await createJob(data)
            if (code === 0) {
                this.onResetForm()
                message.success('职位发布成功!')
                let timeout = setTimeout(() => {
                    clearTimeout(timeout)
                    timeout = null 
                    window.location.href = '/job'
                }, 1000)
            } else {
                message.error('职位发布失败!')
            }
        } else {
            data['id'] = jobInfo.id
            let { code } = await jobUpdate(data)
            if (code === 0) {
                this.onResetForm()
                message.success('职位更新成功!')
                let timeout = setTimeout(() => {
                    clearTimeout(timeout)
                    timeout = null 
                    window.location.href = '/job'
                }, 1000)
            } else {
                message.error('职位更新失败!')
            }
        }
    }
    //清除表单数据
    onResetForm () {
        this.formRef.current.resetFields() //清空表单
        this.setState({
            workExperienceId: '',
            workExperienceMax: '',
            workExperienceMin: '',
            workExperienceName: '',
            educationId: '',
            educationName: ''
        })
    }

    handleSwitchChange(bool) {
        this.setState({ isDisabled: !bool, recommendReward: bool })
    }

    /**
     * @description 设置是否面议
     * @param {*} bool 
     */
    handleNegotiable(e) {
        this.setState({ negotiable: e.target.checked })
    }

    /**
     * @description 选择推荐奖金模板
     * @param {*} v 
     */
    handlerEward(v) {
        let { rewardListArray } = this.state
        let res = rewardListArray.filter(item => item.id === v)
        let reward = res[0].recommendRewardRole
        reward = JSON.parse(reward)
        this.formRef.current.setFieldsValue({ role: reward['100'] })
        this.formRef.current.setFieldsValue({ role2: reward['200'] })
        this.formRef.current.setFieldsValue({ role3: reward['300'] })
        this.formRef.current.setFieldsValue({ role4: reward['400'] })
    }

    //返回
    onBack() {
        history.goBack()
    }

    validatorCitys(rule, value, callback) {
        if (!value) {
            return callback("请选择工作地点")
        }
        if (value.length < 4) {
            return callback("工作地点有误")
        }
        return callback()
    }

    //验证职位名称
    validatorName(rule, value, callback) {
        if (!value) {
            return callback("职位名称不能为空")
        }
        if (value.length > 20) {
            return callback("不可超过20个字符")
        }
        return callback()
    }

    //检验详细地址
    validatorAddress(rule, value, callback) {
        if (value && value.length >20) {
            return callback("不超过20个字符")
        }
        return callback()
    }

    //校验提成
    validatorCommission(rule, value, callback) {
        if (value && value.length > 20) {
            return callback("不超过20个字符")
        }
        return callback()
    }

    onChangeJobType(values) {
        console.log(values)
    }

    onStepSalaryMin (e) {
        let salaryMax = this.formRef.current.getFieldValue('salaryMax')
        if (!salaryMax)return 
        if (e >  salaryMax) return  message.error('最小薪水不能大于最大薪水!')
    }
    onStepSalaryMax (e) {
        let salaryMin = this.formRef.current.getFieldValue('salaryMin')
        if (!salaryMin)return 
        if (salaryMin > e) return  message.error('最小薪水不能大于最大薪水!')
    }

    handleOk (e) {
        const { selectLight, lightList } = this.state;
        const arry = []
        selectLight.forEach(item => {
            lightList.forEach(ite => {
                if (item === ite.id) {
                    arry.push(ite)
                }
            })
        })
        this.setState({lightArry: arry, showLight: false})
       
    }

    handleCancel () {
        this.setState({showLight: false})
    }

    handleHightlit (e) {
        this.setState({selectLight: e})
    }

    handleLightChange (value) {
        if (value.length === 0) return
        if (value.length > 5) {
             value.splice(5, 1)
             this.setState({ lightIdArry: value  })
            return message.error('最多选择5个!')
        }
        this.setState({ lightIdArry: value  })
    }

    handleonDeselect (value) {
        let { lightDefArry } = this.state 
        lightDefArry.forEach((item, index) => {
            if (item == value) {
                lightDefArry.splice(index, 1)
                this.setState({ lightDefArry })
                return
            }
        })
    }

    limitDecimals (value) {
        if(!value)return
        let num = 1
        if(!isNaN(value)) {
            num = value
        }
        return Number(num)
    }

    getCircles () {
       // const { blackData, selectBlack} = this.state
        const code = selectBlack[3]
        let obj = {}
        if (blackData.length === 0)return
        for (let i = 0; i < blackData.length; i ++) {
            if (code === blackData[i].id) {
                obj = blackData[i]
                break
            }
        }
        return obj
    }

    async onChangeBlock (ev) {
        const code = ev[3]
        if(!code)return
        if (ev.length === 4) {
            selectBlack = ev
        } else {

        }
    }

    loadDataParent = async (selectedOptions) => {
        const provinceMapList = this.state.provinceMapList
        const targetOption = selectedOptions[selectedOptions.length - 1];
        targetOption.loading = true;
        const res = await circlesParent(targetOption.id)
        setTimeout(() => {
          targetOption.loading = false;
          targetOption.children = res.data;
          blackData = res.data
          this.setState({provinceMapList})
        }, 1000);
      };

    render() {
        const { tags, inputVisible, inputValue, editInputIndex, editInputValue,
            listOpenArray, workExpListArray, educationListArray, provinceMapList, rewardListArray,
            industryListArray, recommendReward, jobInfo, isDisabled, jobTypeStr, 
            negotiable, isQuick, showLight, lightList2, lightArry, lightDefArry } = this.state

        let headerComponents = ''
        let jobTitle = ''
        let cityStr = ''
        let jobType = ''

        if (!jobInfo['id'] || isQuick) {
            headerComponents = (
                <div className='publish-form-header'>
                    <div className='title-font'>发布职位</div>
                    <div className='title-font-low-normal'>从其他职位快速复制：</div>
                    <div className='type-search-box'>
                        <Form.Item>
                            <Select placeholder='请选择职位' onChange={this.handleDetial.bind(this)}>
                                {
                                    listOpenArray.map((item, index) => {
                                        return <Option key={index} value={item.id}>{item.name}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </div>
                </div>
            )
            jobTitle = (
                <Form.Item
                    label='职位名称'
                    name='name'
                    rules={[{validator: this.validatorName} ]}>
                    <Input placeholder='请输入，不超过20个字符' />
                </Form.Item>
            )
            cityStr = (
                <div>
                    <Form.Item label='工作地点' name='city' rules={[{ validator: this.validatorCitys }]}>
                        <Cascader
                            placeholder='请选择工作地点    '
                            fieldNames={{ label: 'name', value: 'id', children: 'children' }}
                            onChange={this.onChangeBlock}
                            loadData={this.loadDataParent}
                            options={provinceMapList}
                            changeOnSelect
                             />
                    </Form.Item>
                    <Form.Item 
                    name='address' 
                    rules={[
                        { required: false, message: '' },
                         {validator: this.validatorAddress}
                        ]}>
                        <Input placeholder='具体地址，不超过20个字符' />
                    </Form.Item>
                </div>
            )
            jobType = (
                <div className='publish-flex'>
                    <div className='w450'>
                        <Form.Item
                            label='职位类别'
                            name='function'
                            rules={[{ required: true, message: '请选择职位类别' }]}>
                            <Cascader
                                placeholder='请选择职位类别'
                                fieldNames={{ label: 'name', value: 'id', children: 'children' }}
                                options={industryListArray}
                                onChange={this.onChangeJobType.bind(this)} />
                        </Form.Item>
                    </div>
                    <div className='tooltip-box'>
                        <Tooltip placement="topLeft" title='更多职位会陆续开放'>
                            <QuestionCircleOutlined />
                        </Tooltip>
                    </div>
                </div>
            )
        } else {
            headerComponents = ''
            jobTitle = (
                <Form.Item label='职位名称'>
                    {jobInfo.name}
                </Form.Item>
            )
            cityStr = (
                <Form.Item label='工作地点'>
                    {jobInfo.province}/{ jobInfo.city}/{ jobInfo.district} {jobInfo.address}
                </Form.Item>
            )
            jobType = (
                <Form.Item label='职位类别'>
                    {jobTypeStr}
                </Form.Item>
            )
        }

        return (
            <div className='publish-page'>
                <Form
                    ref={this.formRef}
                    onFinish={this.onFinish.bind(this)}>
                    {
                        headerComponents
                    }
                    <ul className='publis-form-layout'>
                        <li>
                            <div>
                                <div className='title-font-low'>职位基本信息</div>
                                <div className='title-font-low-normal'>发布成功后，以下信息将无法修改</div>
                            </div>
                            {jobTitle}
                            {jobType}
                            {cityStr}
                        </li>
                        <li>
                            <div>
                                <div className='title-font-low'>职位要求</div>
                                <div className='title-font-low-normal'>我们将根据以下要求，将职位推荐给合适的求职者</div>
                            </div>
                            <Form.Item label='经验要求' name='workExperienceName' rules={[{ required: true, message: '请选择经验要求!' }]}>
                                <Select onChange={this.handleChangeWork.bind(this)}>
                                    {
                                        workExpListArray.map((item, index) => {
                                            return <Option
                                                key={index}
                                                value={item.id}>{item.name}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item label='学历要求' name='education' rules={[{ required: true, message: '请选择学历要求' }]}>
                                <Select onChange={this.handleChangeEdu.bind(this)}>
                                    {
                                        educationListArray.map((item, index) => {
                                            return <Option key={index} value={item.id}>{item.name}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </li>
                        <li>
                            <div>
                                <div className='title-font-low'>职位描述</div>
                                <div className='title-font-low-normal'>描述的越准确，越容易吸引求职者</div>
                            </div>
                            <div className="publish-moneys">
                                <div className='publish-flex'>
                                    <div>
                                        <Form.Item className="money-between" label='月薪范围' name='salaryMin' rules={[{ required: true, message: '请输入最低月薪' }]}>
                                            <InputNumber min={1} max={1000000} placeholder='最低' />
                                        </Form.Item>
                                    </div>
                                    <div className="w10">至</div>
                                    <div>
                                        <Form.Item name='salaryMax' rules={[{ required: true, message: '请输入最高月薪' }]}>
                                            <InputNumber min={1} max={1000000} placeholder='最高'/>
                                        </Form.Item>
                                    </div>
                                    <div className="w10">/月</div>
                                </div>
                               {/*  <Form.Item className='interview-box'>
                                    <Checkbox checked={negotiable} onChange={this.handleNegotiable.bind(this)}>显示面议</Checkbox>
                                </Form.Item> */}
                            </div>
                            <Form.Item 
                                label=' 提成比例' 
                                name='commission' 
                                rules={[  {validator: this.validatorCommission} ]}>
                                <Input placeholder='比如15%-35%' />
                            </Form.Item>
                            <Form.Item label='职位亮点' name="highlights">
                                <Select 
                                    mode="multiple" 
                                    placeholder="请选择亮点" 
                                    onChange={this.handleLightChange.bind(this)}
                                    onDeselect={this.handleonDeselect.bind(this)} 
                                    >
                                    {lightList2.map((item, index) => {
                                    return (
                                        <Option key={index} value={item.label}>
                                        {item.label}
                                        </Option>
                                    );
                                    })}
                                </Select>
                                </Form.Item>
                                <div className='title-font-low-normal title-font-low-normal2'>比如“无责任底薪”、“超高提成”、“就近分配”等</div>
                            <Form.Item label='职位描述' name='description' rules={[{ required: true, message: '请输入职位描述' }]}>
                                <Input.TextArea rows={5} showCount maxLength={5000} placeholder='请输入' />
                            </Form.Item>
                        </li>
                        <li>
                            <div>
                                <div className='title-font-low'>推荐奖金</div>
                                <div className='title-font-low-normal'>邀请员工参与推荐，快速获得靠谱人才</div>
                            </div>
                            <Form.Item label='推荐奖金规则'>
                                <Switch checked={recommendReward} onChange={this.handleSwitchChange.bind(this)} />有推荐奖金
                            </Form.Item>
                            <div className='throu-line-box'>每成功推荐一名应聘者：</div>
                            <div className='push-box'>
                                <div className='publish-flex money-month-box'>
                                    <div className='w130'> 推荐成功(邀约面试)<div className='checkout-line-box'><CheckCircleOutlined /></div></div>
                                    <div className='w130'>通过面试 <div className='checkout-line-box line-box-2'><CheckCircleOutlined /></div></div>
                                    <div className='w130'>实际到岗 <div className='checkout-line-box-last'><CheckCircleOutlined /></div></div>
                                </div>
                            </div>
                            <div className='publish-flex money-month-box'>
                                <div className='w130'>
                                    <Form.Item label='￥' name='role'>
                                        <InputNumber 
                                            min={0} 
                                            placeholder='0' 
                                            precision={0} 
                                            parser={this.limitDecimals} 
                                            disabled={isDisabled} />
                                    </Form.Item>
                                </div>
                                <div className='w130'>
                                    <Form.Item label='￥' name='role2'>
                                        <InputNumber 
                                            min={0} 
                                            placeholder='0' 
                                            precision={0} 
                                            parser={this.limitDecimals}
                                            disabled={isDisabled} 
                                            />
                                    </Form.Item>
                                </div>
                                <div className='w130'>
                                    <Form.Item label='￥' name='role4'>
                                        <InputNumber 
                                            min={0} 
                                            placeholder='0' 
                                            precision={0} 
                                            parser={this.limitDecimals}
                                            disabled={isDisabled}
                                             />
                                    </Form.Item>
                                </div>
                            </div>
                           {/*  <div>推荐奖金规则由您所在的企业制定，且奖金由企业发放，与平台无关</div> */}
                            <div className="font-money-des">
                                <div>
                                    <Image className="notice-icon" src="https://img.fanwoon.com/redtishi%402x.png" />
                                    <span className="font-money-header">推荐奖金说明:</span>
                                 </div>
                                <div className="font-money-sub">
                                    <div>1.当您邀约面试时，会预扣推荐奖金至平台；</div>
                                    <div>2.邀约面试起21天内</div>
                                    <div>
                                        <div>(1)对求职者的“通过”操作，会结算相应环节的预扣奖金，发放给推荐者；</div>
                                        <div>(2)对求职者的“淘汰”操作，会返还相应环节预扣的奖金至您的账户；</div>
                                        <div>(3)若21天后，未登录平台进行任何操作，会将预扣的奖金全部自动发放给推荐者；</div>
                                    </div>
                                    <div>3.在21天到期前，会有短信提醒，您可申请延长一次7天的自动结算周期</div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <Form.Item>
                        <div className='form-footer-box'>
                            <Button className='m-r-16' type="primary" htmlType="submit">提交</Button>
                            <Button onClick={this.onBack.bind(this)}>取消</Button>
                        </div>
                    </Form.Item>
                </Form>

                <Modal 
                    title="职位亮点" 
                    visible={showLight} 
                    onOk={this.handleOk.bind(this)} 
                    onCancel={this.handleCancel.bind(this)} 
                    cancelText="取消"
                    okText="确认">
                    <Checkbox.Group options={lightList2} defaultValue={lightDefArry} onChange={this.handleHightlit.bind(this)}  />
                </Modal>

            </div>
        )
    }
}

export default Publish