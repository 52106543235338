const initState = {
    money: 0, //余额
}

const todos = (state = initState, action) => {
    switch (action.type) {
        case 'INIT_MONEY':
            return { money: action.money}
        default:
            return state    
    }
}

export default todos