import React, { Component } from 'react'
import { Modal, Button, Form, Input, InputNumber, message, Steps } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons'
import { rewardSave } from '../api'

import '../assets/css/index.less'

class Intro extends Component {

    formRef = React.createRef()

    constructor(props) {
        super(props)
        this.state = { visible: false }
        this.handleCancel = this.handleCancel.bind(this)
    }


    componentDidMount() {
        this.init()
    }

    init () {
       let timeout = setTimeout(() => {
           clearTimeout(timeout)
           timeout = null
            let { modle } = this.props
            if(!modle){
                this.formRef.current.setFieldsValue({ 100: 0 })
                this.formRef.current.setFieldsValue({ 200: 10 })
                this.formRef.current.setFieldsValue({ 300: 50 })
                this.formRef.current.setFieldsValue({ 400: 200 })
                return
            }
            let recommendRewardRole = modle.recommendRewardRole
            if (recommendRewardRole.includes('{')) {
                recommendRewardRole = JSON.parse(recommendRewardRole)
                this.formRef.current.setFieldsValue({ name: modle.name })
                this.formRef.current.setFieldsValue({ 100: recommendRewardRole['100'] })
                this.formRef.current.setFieldsValue({ 200: recommendRewardRole['200'] })
                this.formRef.current.setFieldsValue({ 300: recommendRewardRole['300'] })
                this.formRef.current.setFieldsValue({ 400: recommendRewardRole['400'] })
            }
        }, 500)
    }

    handleCancel() {
        let { parent } = this.props
        parent.handleCloseIntro()
    }

    onFinish = async (values) => {
        let { parent, modle } = this.props
        let obj = {}

        if (modle != null && modle !=='' && modle.id) {
            modle.name = values.name
            delete values['name']
            modle.recommendRewardRole = JSON.stringify(values)
            obj = modle
        } else {
            obj = values
        }

        if (!modle) {
            let recommendRewardRole = {100: obj[100], 200: obj[200], 300: obj[300], 400: obj[400]}
            let param = {name: obj.name, recommendRewardRole: JSON.stringify(recommendRewardRole)}
            obj = param
        }
      
        let id= ''
        if (modle != null && modle !=='') {
            id = modle.id
        }

        let { code } = await rewardSave(obj)
        if (code === 0) {
            !id ? message.success('内推奖励模版添加成功!') :  message.success('内推奖励模版修改成功!')
            parent.handlerEwardList()
        } else {
            !id ? message.error('内推奖励模版添加失败!') : message.error('内推奖励模版修改失败!')
        }
        parent.handleCloseIntro()
    }

    validatorName(rule, value, callback) {
        if (!value) {
            return callback("模版名称不能为空!")
        }
        if (value && value.length > 20) {
            return callback("不超过20个字符")
        }
        return callback()
    }

    render() {
        const { visible, from } = this.props
        let title = from === 1 ? '编辑内推奖金规则模板' : '新增内推奖金规则模板'
        return (
            <Modal
                title={title}
                visible={visible}
                onCancel={this.handleCancel}
                footer={null}
                className="intro-modal"
            >
                <Form
                    ref={this.formRef}
                    onFinish={this.onFinish.bind(this)}>
                    <Form.Item label='模板名称' 
                        name='name' 
                        rules={[  {validator: this.validatorName} ]}>
                        <Input placeholder='请输入，不超过20个字' />
                    </Form.Item>
                    <div>每成功推荐一名应聘者：</div>
                    <Steps className="step-box-bar" size="small" current={4}>
                          <Steps.Step title="通过筛选"/>
                          <Steps.Step title="通过面试" />
                          <Steps.Step title="接受offer" />
                          <Steps.Step title="已入职" />
                    </Steps>
                    {/* <div className='publish-flex money-month-box'>
                        <div> 通过筛选<div className='checkout-line-box'><CheckCircleOutlined /></div></div>
                        <div>通过面试 <div className='checkout-line-box'><CheckCircleOutlined /></div></div>
                        <div>接受offer <div className='checkout-line-box'><CheckCircleOutlined /></div></div>
                        <div>已入职 <div className='checkout-line-box-last'><CheckCircleOutlined /></div></div>
                    </div> */}
                    <div className='publish-flex money-month-box'>
                        <div className='w120'>
                            <Form.Item label='￥' name='100'>
                                <InputNumber placeholder='0' min={1} max={1000} />
                            </Form.Item>
                        </div>
                        <div className='w120'>
                            <Form.Item label='￥' name='200'>
                                <InputNumber placeholder='0' min={1} max={1000} />
                            </Form.Item>
                        </div>
                        <div className='w120'>
                            <Form.Item label='￥' name='300'>
                                <InputNumber placeholder='0' min={1} max={1000} />
                            </Form.Item>
                        </div>
                        <div className='w120'>
                            <Form.Item label='￥' name='400'>
                                <InputNumber placeholder='0' min={1} max={1000} />
                            </Form.Item>
                        </div>
                    </div>
                    <div className='important-box'>
                        内推奖励规则由您所在的企业制定，且奖金由企业发放，与平台无关
                    </div>
                    <Form.Item>
                        <div className='footer-model'>
                            <Button className='m-r-16' type="primary" htmlType="submit">提交</Button>
                            <Button onClick={this.handleCancel}>取消</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default Intro