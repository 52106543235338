import React, { Component } from 'react'
import { Radio, Input, Table, Image, Pagination, Modal, Button, message } from 'antd'
import { 
    talentSearch, 
    listStatus, 
    notPass, 
    pass ,
    listByTalentIdAndCompanyId
} from '../../api/bole'
import { SET_USER } from '../../utils/constant'
import Qrcode from 'qrcode-react'

import './index.less'
const logo = 'http://img.fanwoon.com/qr_logo.png'
const company = 'https://img.fanwoon.com/guide_new.png'
const avatar = 'http://img.fanwoon.com/avatar.png'

const { Search } = Input

const optionsState = [
    { label: '通过认证', value: 1 },
    { label: '不通过认证', value: 0 }
]

let getStatusMsg = new Map([
    [0, '未申请认证'],
    [10, '待审核'],
    [20, '通过认证'],
    [100, '不通过认证']
])

class Bole extends Component {

    constructor () {
        super(...arguments)
        this.state = {
            visible: false,//显示认证弹框
            visible2: false, //显示推荐简历明细
            visible3: false, //显示内推明细
            visible4: false,
            pageToal: 0, //伯乐列表总页数
            pageToal2: 0, //伯乐列表总页数
            sharePath: '',
            list: [], //伯乐列表
            statusArry: [],//伯乐状态字典
            clickSearch: false,// 是否点击了搜索
            searchName: '',
            curRido: -1, //当前的状态值
            boleObj: {}, //单个伯乐信息
            passStatus: -1, //是否通过， -1空状态
            tuiMoneyList: [], //内推奖金
            columns: [
                {
                   title: '伯乐',
                   dataIndex: 'name',
                   key: 'name',
                   render: (text, record) => {
                       return (
                           <div className='peronse-info'>
                                <Image className='img-box' src={record.imgUrl} />
                                <span className='persone-name'>{record.name}</span>
                           </div>
                       )
                   },
                 },
                 {
                   title: '手机号码',
                   dataIndex: 'phone',
                   key: 'phone',
                 },
                 {
                   title: '已推荐简历',
                   dataIndex: 'recommendNumber',
                   key: 'recommendNumber',
                   render: (text, record) => <span className='line-box' onClick={this.showModal2.bind(this, record)}>{text}人</span>,
                 },
                 {
                   title: '内推奖金',
                   dataIndex: 'recommendBonus',
                   key: 'recommendBonus',
                   render: (text, record) => <span className='line-box' onClick={this.showModal3.bind(this, record)}>￥ {text}元</span>,
                 },
           ],
           columns2: [
              {
                title: '求职者',
                dataIndex: 'candidateName',
                key: 'candidateName'
              },
              {
                title: '投递时间',
                dataIndex: 'createTime',
                key: 'createTime'
              },
              {
                title: '应聘职位',
                dataIndex: 'jobName',
                key: 'jobName'
              }
           ],
           columns3: [
              {
                title: '获得时间',
                dataIndex: 'createTime',
                key: 'createTime'
              },
              {
                title: '求职者',
                dataIndex: 'candidateName',
                key: 'candidateName'
              },
              {
                title: '应聘职位',
                dataIndex: 'jobName',
                key: 'jobName'
              },
              {
                title: '获得方式',
                dataIndex: 'status',
                key: 'status',
                render: (text, record) => {
                    return (
                        <div>
                             <span>{this.onGetStatus(record.status)}：<span>通过</span></span>
                        </div>
                    )
                }
              },
              {
                title: '奖金',
                dataIndex: 'money',
                key: 'money',
                render: text => {
                    return (
                        <div>
                             <span>￥{text}</span>
                        </div>
                    )
                }
              }
           ]  
        }
    }

    componentWillMount(){
        this.onTalentSearch()
        this.onListStatus()
    }

    //认证状态
    async onListStatus() {
        let res = await listStatus()
        let data = res.data.map(item => {
            return {label: item.name, value: item.id}
        })
        data.unshift({label:'不限', value: -1})
        this.setState({statusArry: data})
    }

    //获取伯乐列表
    async onTalentSearch (limit = 10, start = 0, params = {}) {
        let res = await talentSearch({ data: params, limit, start  })
        this.setState({list : res.data, pageToal: res.totalRecord})
    }

    //打开推荐人列表
    showModal2 (obj){
        let { id } = obj
        this.setState({ visible2: true, boleObj: obj, tuiMoneyList: [] })
        this.onListByTalentIdAndCompanyId(10, 0, id)
    }
    //打开内推金额列表
    showModal3 (obj){
        let { id } = obj
        this.setState({ visible3: true,boleObj: obj, tuiMoneyList: [] })
        this.onListByTalentIdAndCompanyId(10, 0, id)
    }

    async onListByTalentIdAndCompanyId(limit = 10, start = 0, data = '') {
        let res = await listByTalentIdAndCompanyId({limit , start , data})
        this.setState({tuiMoneyList: res.data, pageToal2: res.totalRecord})
    }
    
      //认证状态确认
      handleOk = () => {
        this.setState({ visible: false })
      }

      //取消编辑认证状态
      handleCancel () {
        this.setState({ visible: false })
      }

      //关闭推荐人列表
      handleCancel2 () {
        this.setState({ visible2: false})
      }

       //关闭内推奖金列表
       handleCancel3 () {
        this.setState({ visible3: false})
      }

      //打开分享公司二维码
    onOpenShareCompany() {
        let user = sessionStorage.getItem(SET_USER)
        user = JSON.parse(user)
        //let path = `${sharePath}&action=${id}` //+ id 
        let path = `https://qr.tueneitui.com/companyId?companyId=${user.companyId}`
        this.setState({ visible4: true, sharePath: path })
    }  

    handleCancel4() {
        this.setState({
            visible4: false,
            sharePath: ''
          })
    }  

    onChangeName(e) {
        let { value } = e.target
        this.setState({searchName: value})
    }
    onSearchBole(val){
        this.setState({clickSearch: true})
        this.onTalentSearch(10, 0, {name: val})
    }

    onSearchStatusRadio(e) {
        this.setState({clickSearch: true, curRido: e.target.value})
        let { value } = e.target
        let { searchName } = this.state
        let params = {name: searchName}
        if (value !== -1) {
            params['status'] = value
        }
        this.onTalentSearch(10, 0, params)
    }

    //设置是否通过认证
    onPassSatatus(e) {
        let { value } = e.target
        this.setState({passStatus: value})
    }

    //编辑伯乐，是否通过认证
    async onPassBole() {
        let { passStatus, boleObj } = this.state
        if (passStatus === -1) {
            message.error('请选择认证状态!')
            return
        }
        this.setState({passStatus: -1})
        this.handleCancel()
        if (passStatus === 0) {
            let res = await notPass({data: boleObj.id})
            res.code === 0 ? message.success(res.message) : 
            message.error(res.message)
            this.onTalentSearch()
            return
        }
        if (passStatus === 1) {
            let res = await pass({data: boleObj.id})
            res.code === 0 ? message.success(res.message)
                : message.error(res.message)
            this.onTalentSearch()
            return
        }
        
    }

    onGetStatus(status) {
    if (status === 0) return "筛选简历"
    if (status === 200) return "面试"
    if (status === 300) return "offer"
    if (status === 400)  return "入职"
  }

  onChangeBoleList(current, pageSize) {
    window.scrollTo(0,0)
    let { searchName, curRido } = this.state
    let params = {name: searchName}
    if (curRido !== -1) {
        params["status"] = curRido
    }
    this.setState({clickSearch: true})
    this.onTalentSearch(pageSize, (current - 1) * pageSize, params)
  }

  onChangeAlready (current, pageSize) {
    window.scrollTo(0,0)
    let {  boleObj } = this.state
    let params = {data: boleObj.id}
    this.onListByTalentIdAndCompanyId(pageSize, (current - 1) * pageSize, params)
  }

  onSetColor (status) {
      if (status === 0)return 'un-status'  
      if (status === 10)return 'await-status'  
      if (status === 20)return 'pass-status'
      if (status === 100)return 'dangeros-status'
  }
  
    render() {
        const { columns, columns2, columns3, list, sharePath, 
            statusArry, clickSearch, boleObj, tuiMoneyList, 
            pageToal, pageToal2 } = this.state
        return (
            <div className='bole-page'>
                {
                    list.length === 0 && !clickSearch ? (
                        <div className='no-bole-info-box'>
                            <Image className='pic' src='http://img.fanwoon.com/default1.png' />
                            <div className='no-font-box'>无任何伯乐</div>
                            <Button onClick={this.onOpenShareCompany.bind(this)}>邀请员工内推</Button>
                        </div>
                    ) : (
                        <div>
                            <div className='bole-header-box'>
                                <div className='bole-seartch-box'>
                                    <div className='state-item'>伯乐:</div>
                                    <div>
                                        <Search 
                                        placeholder="请输入伯乐姓名/手机号"  
                                        style={{ width: 200 }} 
                                        onChange={this.onChangeName.bind(this)}
                                        onSearch={this.onSearchBole.bind(this)} />
                                    </div>
                                </div>
                            </div>
                            <div className='table-list'>
                                {
                                    list.length === 0 && clickSearch ? (
                                        <div className='no-bole-info-box'>
                                            <Image className='pic' src='http://img.fanwoon.com/default1.png' />
                                            <div className='no-font-box'>未找到伯乐</div>
                                        </div>
                                    ) : (
                                        <div>
                                            <Table 
                                            rowKey={(record) => record.id}
                                            columns={columns} 
                                            dataSource={list} 
                                            pagination={false} />
                                            <div className='pagination-box'>
                                                {
                                                    pageToal < 10 ? null : (
                                                        <Pagination
                                                        total={pageToal}/* pageToal */
                                                        showSizeChanger
                                                        showQuickJumper
                                                        showTotal={total => `共计  ${total} 条数据`}
                                                        onChange={this.onChangeBoleList.bind(this)}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                                
                            </div>
                        </div>
                    )
                }
            
                <Modal
                title="内推奖金明细"
                visible={this.state.visible3}
                footer={null}
                width='800px'
                onCancel={this.handleCancel3.bind(this)}
                >
                    <div className='bole-push-box'>
                        <div className='bole-info-header'>
                            <span>
                                <span className='font-item'>推荐人：</span>
                                <span>{boleObj.name}</span>
                            </span>
                            <span className='persone-num'>
                                <span className='font-item'>累计内推总奖金：</span>
                                <span>￥{boleObj.recommendBonus}</span>
                            </span>
                        </div>
                        <Table 
                        rowKey={(record) => record.id}
                        columns={columns3} 
                        pagination={false} 
                        dataSource={tuiMoneyList} 
                        />
                        {
                            pageToal2 < 10 ? null : (
                                <div className='bole-pagination-box'>
                                    <Pagination 
                                    total={pageToal2}
                                    onChange={this.onChangeAlready.bind(this)}
                                    />
                                </div>
                            )
                        }
                    </div>
                </Modal>

                <Modal
                title="推荐简历明细"
                visible={this.state.visible2}
                footer={null}
                width='600px'
                onCancel={this.handleCancel2.bind(this)}
                >
                    <div className='bole-push-box'>
                        <div className='bole-info-header'>
                            <span>
                                <span className='font-item'>推荐人：</span>
                                <span>{boleObj.name}</span>
                            </span>
                            <span className='persone-num'>
                                <span className='font-item'>推荐人数：</span>
                                <span>{boleObj.recommendNumber}人</span>
                            </span>
                        </div>
                        <Table 
                         rowKey={(record) => record.id}
                        columns={columns2} 
                        dataSource={tuiMoneyList} 
                        pagination={false} 
                        />
                       {
                            pageToal2 < 10 ? null : (
                                <div className='bole-pagination-box'>
                                    <Pagination 
                                    total={pageToal2}
                                    onChange={this.onChangeAlready.bind(this)}
                                    />
                                </div>
                            )
                        }
                    </div>
                </Modal>

                <Modal
                    title="将职位分享同事，让他们帮你内推吧！"
                    visible={this.state.visible4}
                    footer={null}
                    onCancel={this.handleCancel4.bind(this)}
                    >
                    <div className='share-box'>
                        <div>
                            <Qrcode
                                size={150}
                                value={sharePath}
                                logo={logo}
                                logoWidth={50}
                                logoHeight={50}
                            />
                            <div>步骤一：打开微信扫一扫</div>
                        </div>
                        <div>
                            <Image className='job-pic' src={company} />
                            <div>第二步，点击「邀请朋友应聘」</div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default Bole