import React, { Component } from 'react'
import { Modal, message, Image } from 'antd'
import { closeJob, openJob } from '../api'
import { SET_USER } from "../utils/constant"

const avatar = 'http://img.fanwoon.com/avatar.png'

const { confirm } = Modal

class JobList extends Component {

    constructor() {
        super(...arguments)
        this.state = {
            visible: true,
            showOpen: false, //是否显示开启按钮
        }
    }

    componentWillMount() {
        this.onRule()
    }

    async handleOpenJob (id) {
        let { parent } = this.props
        let { code } = await openJob({data: id})
        if (code === 0) {
            message.success('职位开启成功!') 
            setTimeout(() => parent.handleListCloseLikeName(), 1000)
        } else {
            message.error('职位开启失败!')
        }
    }

    async handleCloseJob (id) {
        let { parent } = this.props
        let { code } = await closeJob({data: id})
        if (code === 0) {
            message.success('职位关闭成功!') 
            setTimeout(() => parent.handleListOpen(), 1000)
        } else {
            message.error('职位关闭失败!')
        }
    }

    handleOpenCloseModal = (id) => {
        confirm({
            title: '关闭职位后，将无法接受投递的简历',
            content: '已经收到的简历将不受影响，你还要继续吗？',
            cancelText: '取消',
            okText: '确认',
            onOk: () => {
                this.handleCloseJob(id)
            }
        })
    }

    handleOpenJobModal = (id) => {
        confirm({
            title: '开启职位后，求职者将可以投递简历',
            cancelText: '取消',
            okText: '确认',
            onOk: () => {
                this.handleOpenJob(id)
            }
        })
    }

    onOpenShare (id) {
        let { parent } = this.props
        parent.onOpenShare(id)
    }

    onGoDetail (id) {
        let { pageNum } = this.props.parent.state.register;
        window.localStorage.setItem('pageNum', pageNum)
        window.location.href = `/jobDetail/${id}`
    }

    onRule () {
       let user = sessionStorage.getItem(SET_USER)
       user = JSON.parse(user)
       this.setState({showOpen: user.system})
    }

    toolBar (data) {
        let user = sessionStorage.getItem(SET_USER)
       user = JSON.parse(user)
       const { defaultTabKey } = this.props
       const { showOpen } = this.state

       let bar = ''

       if (showOpen && defaultTabKey !== 1) {
        bar = (
            <ul className='small-toolbar'>
                <li onClick={this.handleOpenJobModal.bind(this,data.id)}>开启</li>
            </ul>
        ) 
        }else if (user.system || user.id === data.publishId) { //管理员 或者当前登录用户是该条职位的发布者
                bar = (
                    <ul className='small-toolbar'>
                        <li><a href={'/publish/' + data.id}>编辑</a></li>
                        <li onClick={this.handleOpenCloseModal.bind(this,data.id)}>关闭</li>
                        <li onClick={this.onOpenShare.bind(this, data.id)}>分享</li>
                    </ul>
                )
        } else if (user.id !== data.publishId) { // 当前用户与当前单条发布的的发布者不是同一个人，只能查看跟分享
                bar = (
                    <ul className='small-toolbar'>
                        <li onClick={this.onOpenShare.bind(this, data.id)}>分享</li>
                    </ul>
                )
        }
        return bar
    }

    onPublisJobGrade = (str) => {
        if (str == "1")return "店长"
        if (str == "2")return "区域/商圈经理"
        if (str == "3")return "招聘人员"
        if (str == "4")return "其他"
        return str
    }

    render() {
        const { defaultTabKey, data } = this.props
        const { showOpen } = this.state
        return (
            <div className='job-list'>
                <div className='job-name-box' onClick={this.onGoDetail.bind(this, data.id)}>
                    <div className='blod-font'>{data.name}</div>
                    <div>
                        {data.city} - {data.district} | 
                        {data.education === '不限' ? '学历不限' : data.education} | 
                        {data.workExperienceName === '不限' ? '工作经历不限' : data.workExperienceName}</div>
                  </div>
                <div className='money'>{data.salaryMin} - {data.salaryMax} /月</div>
                <div className='user-box'>
                    <div className='user-avator'>
                         <img src={!data.publishImgUrl ? avatar : data.publishImgUrl } />
                    </div>
                   <div>
                        <div className='blod-font'>{data.publishName}</div>
                        <div>{this.onPublisJobGrade(data.publishJobGrade)}</div>
                    </div>
                </div>
                <div className='createTime-box'>
                    发布时间： <span>{data.createTime}</span>
                </div>
                <div className='toolbar-box'>
                    {this.toolBar(data)}
                </div>
            </div>
        )
    }
}

export default JobList