import React, { Component } from 'react'
import { Tag, Tabs, Input, Pagination, Tooltip, Image, Button } from 'antd'
import FollowList from '../../components/followList'
import { InboxOutlined } from '@ant-design/icons'
import { listJob, listByJobAndStatusAndLikeName, countByJob, listJobLikeName } from '../../api/follw'

import './follow.less'
const { TabPane } = Tabs
const { Search } = Input

class Follow extends Component {


    constructor() {
        super(...arguments)
        this.state = {
            jobList: [], //职位列表
            job: {}, //默认选中的单个职位
            pageNum: 0,
            pageSize: 0,
            totalPage: 0,
            totalRecord: 0,
            resumeList: [], //筛选简历
            start: 0,
            interview: 0,
            offer: 0,
            enter: 0,
            out: 0,
            curState: 0, //当前状态默认筛选简历
            register: {
                pageNum: 1,
                pageSize: 10,
                totalPage: 0,
                totalRecord: 0
            },
            searchName: '', //跟进列表输入名称
            defaultTabKey: 0,
            leftSearch: false,//左边搜索
            searchView: false,//是否点击过筛选简历
            defaultActiveKey: "0",
            selectId: 0
        }
    }

    componentWillMount(){
        this.init()
    }

   init () {
    this.handleListOpen()
   }

    //统计职位任务
    async onCountByJob(id) {
        let res = await countByJob({data: id})
        this.setState({ start: 0, interview: 0, offer: 0, enter: 0, out: 0})
        res.data.forEach(item => {
            if(item.id === 0) this.setState({ start: item.name || 0 })
            if(item.id === 200) this.setState({ interview: item.name || 0 })
            if(item.id === 300) this.setState({ offer: item.name || 0 })
            if(item.id === 400) this.setState({ enter: item.name || 0 })
            if(item.id === 10000) this.setState({ out: item.name || 0 })
        })
    }

    //查询简历
    async onListByJobAndStatusAndLikeName(data = {}, limit = 10, start = 0) {
        let res = await listByJobAndStatusAndLikeName({ data, limit , start })
        this.setState({resumeList: res.data })
    }

    //通过名字查询职位
    async onListJobLikeName(limit = 50, start = 0, name = '') {
        let res = await listJobLikeName({ limit, start, data: name })
        res.data.forEach((item, index) => {
            index === 0 ? item['checked'] = true : item['checked'] = false
        })

        if (res.data.length > 0) {
            this.onCountByJob(res.data[0].id)
            this.setState({job: res.data[0]})
            this.onListByJobAndStatusAndLikeName({
                jobId: res.data[0].id,
                name: '',
                status: 0
            })
        }
        
        this.setState({
            jobList: res.data,
            pageNum: res.pageNum,
            pageSize: res.pageSize,
            totalPage: res.totalPage,
            totalRecord: res.totalRecord
        })
    }

    //获取招聘中的职位
    async handleListOpen(limit = 50, start = 0, params = {}) {
        let res = await listJob({ limit, start, data: params })
        res.data.forEach((item, index) => {
            index === 0 ? item['checked'] = true : item['checked'] = false
        })

        if (res.data.length > 0) {
            this.onCountByJob(res.data[0].id)
            this.setState({job: res.data[0], selectId: res.data[0].id})
            this.onListByJobAndStatusAndLikeName({
                jobId: res.data[0].id,
                name: '',
                status: 0
            })
        }
        
        this.setState({
            jobList: res.data,
            pageNum: res.pageNum,
            pageSize: res.pageSize,
            totalPage: res.totalPage,
            totalRecord: res.totalRecord
        })
    }

    //刷新右侧候选人跟进列表
    onRefresh(defaultTabKey) {
        let { job } = this.state
        this.onCountByJob(job.id)
        this.onListByJobAndStatusAndLikeName({
        jobId: job.id,
        name: '',
        status: defaultTabKey })
    }

    //更换选中的职位
    onChangeJob (item) {
        let { defaultTabKey } = this.state
        let jobList = this.state.jobList
        jobList.forEach((item, index) =>  item['checked'] = false)
        item['checked'] = true
        this.setState({ jobList, job: item, selectId: item.id })
       this.onCountByJob(item.id)
       this.onListByJobAndStatusAndLikeName({
        jobId: item.id,
        name: '',
        status: defaultTabKey })
    }

    onChangeTabs(key) {
        console.log(key)
        let { id } = this.state.job
        this.setState({ 
            defaultTabKey: Number(key),
            curState: Number(key),
            register: {
                pageNum: 1,
                pageSize: 10,
                totalPage: 0,
                totalRecord: 0
            },
            searchName: '', 
            resumeList: []
         })
        this.onListByJobAndStatusAndLikeName({
            jobId: id,
            name: '',
            status: key,
            
        })
    }

    //搜索职位
    onSearchJob (v) {
        this.setState({leftSearch: true})
        this.onListJobLikeName(50, 0, v)
    }

    onChangeSearch(e) {
        this.setState({ searchName:  e.target.value})
    }
    //跟进列表查询
    onFollowSearch (v) { 
        let { defaultTabKey, job } = this.state
        console.log(defaultTabKey)
        this.setState({searchView: true})
        this.onListByJobAndStatusAndLikeName({
            jobId: job.id,
            name: v,
            status: defaultTabKey  + '',
        })
    }

    onSetTab (key) {
        let { job } = this.state
        this.onListByJobAndStatusAndLikeName({ jobId: job.id ,name: '', status: key })
        this.onCountByJob(job.id)
    }

    render() {
        const { jobList, job, resumeList, start, interview, offer, enter, 
            out, curState, register, leftSearch, searchView, defaultTabKey, defaultActiveKey } = this.state
        
        return (
            <div className='follow-page'>
                {
                    jobList.length === 0 && !leftSearch ? (
                        <div className='no-bole-info-box'>
                            <Image className='pic' src='http://img.fanwoon.com/default1.png' />
                            <div className='no-font-box'>您还未发布任何职位</div>
                            <Button>+ 发布职位 快速招聘</Button>
                        </div>
                    ) : (
                        <div className='follow-content'>
                    <div className='left-box'>
                        <div className='mark-slogan'>选择职位</div>
                        <Search 
                            className='tab-search-bar' 
                            placeholder="输入职位名称搜索"
                            onSearch={this.onSearchJob.bind(this)} />
                        <div className='job-list-left'>
                            {
                                jobList.length ===0 && leftSearch ? (
                                    <div className="no-job-search-info">未找到职位</div>
                                ) :
                                 jobList.map((item, index) => {
                                    let text = `${item.name} [${item.city}-${item.district}]`
                                    return (
                                        <div 
                                            className={`list-item ${item.checked ? 'active' : ''}`} 
                                            key={index}
                                            onClick={this.onChangeJob.bind(this, item)}
                                            >
                                            <div className='job-name-bar'>
                                                <Tooltip placement="top" title={text}>
                                                    {item.name}
                                                    <span className='city-box'>
                                                        [{item.city}-{item.district}]
                                                    </span>
                                                 </Tooltip>
                                                 {
                                                     item.number !== 0 ? <span className='square-box'></span> : null
                                                 }
                                                 
                                            </div>
                                            <div className='job-state-box'>
                                                <div>
                                                    <span className={`item-square ${item.status === 0 ? 'close-square' : 'success-square'}`}></span>
                                                    <span>{item.status === 0 ? '已关闭' : '招聘中'}</span>
                                                 </div>
                                                <div><InboxOutlined /><span>{item.number}</span></div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            
                        </div>
                    </div>
                    <div className='right-box'>
                        <div className='job-info-box'>
                            <div>
                                <span className='name'>{job.name}</span>
                                <span className='money'>{job.salaryMin} - {job.salaryMax} /月 </span>
                            </div>
                            <div>
                                <Tag>{job.city}-{job.district}</Tag>
                                <Tag>{job.education === '不限' ? '学历不限' : job.education}</Tag>
                                <Tag>{job.workExperienceName === '不限' ? '经验不限' : job.workExperienceName}</Tag>
                                <span className='job-state'>
                                <span className={`item-square ${job.status === 0 ? 'close-square' : 'success-square'}`}></span><span>{job.status === 1 ? '招聘中' : '已关闭'}</span>
                                </span>
                                 <span className='create-time-box'>发布时间: {job.createTime}</span>
                            </div>
                        </div>
                        <Tabs defaultActiveKey={ defaultActiveKey } onChange={this.onChangeTabs.bind(this)}>
                            <TabPane tab={`待筛选简历(${start})`} key="0"></TabPane>
                            <TabPane tab={`面试中(${interview})`} key="200"></TabPane>
                            {/* <TabPane tab={`待入职(${offer})`} key="300"></TabPane> */}
                            {/* <TabPane tab={`入职(${enter})`} key="400"></TabPane> */}
                            <TabPane tab={`入职报到(${ offer + enter })`} key="400"></TabPane>
                            <TabPane tab={`已淘汰(${ out })`} key="10000"></TabPane>
                        </Tabs>
                        <div className='saixuan-header-box'>
                                   {/*  {
                                        resumeList.length > 0 ? (
                                            
                                        ) : null
                                    } */}

                                            <div>
                                                <Search  
                                                    placeholder="输入求职者姓名搜索" 
                                                    onBlur={this.onChangeSearch.bind(this)}
                                                    onSearch={this.onFollowSearch.bind(this)}/>
                                            </div>
                                    
                                    <div>
                                        {
                                            register.totalRecord > 10 ? (
                                                <Pagination simple defaultCurrent={register.pageNum} total={register.totalRecord} />
                                            ) : null
                                        }
                                        
                                    </div>
                                </div>

                                {
                                    resumeList.length === 0 && searchView ? (
                                        <div className='no-bole-info-box'>
                                            <Image className='pic' src='http://img.fanwoon.com/default1.png' />
                                            <div className='no-font-box'>
                                               {/*  未找到相关简历 */}
                                               未找到相关简历
                                            </div>
                                        </div>
                                    ) :
                                    resumeList.length === 0 && !searchView ? (
                                        <div className='no-bole-info-box'>
                                            <Image className='pic' src='http://img.fanwoon.com/default1.png' />
                                            <div className='no-font-box'>
                                               {/*  未找到相关简历 */}
                                               无跟进中的简历
                                            </div>
                                        </div>
                                    ) : (
                                        resumeList.map((item, index) => {
                                            return <FollowList key={index} info={item} job={job} curState={curState} defaultTabKey={defaultTabKey}  parent={this} />
                                        })
                                    )
                                }
                    </div>
                </div>
                    )
                }
            </div>
        )
    }
}

export default Follow