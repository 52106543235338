import React, { Component } from 'react'
import { connect } from 'react-redux'
import {  Modal, Button, Input, message, Steps, Form, Image } from 'antd'
import { SET_CSNDIDATE } from "../../utils/constant"
import { exportPDF } from "../../utils/pdf.js"
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { eliminate, taskEntry, interview, taskFinish, taskOffer, recover, browseAndInterview } from "../../api/follw"
import { taskBrowse, remaining, jobDetail, delayReward } from "../../api"
import { listByJobAndStatusAndLikeName } from '../../api/follw'
import { age, stateMsg, getQueryVariable, timesStr } from "../../utils"
import { initAccount } from '../../store/action'
import CashierWidget from '../../components/CashierWidget'

import './index.less'

//let createHistory = require('history').createBrowserHistory
//const history = createHistory()

const defPic = 'http://img.fanwoon.com/avatar.png'

class Candidate extends Component {

    constructor () {
        super(...arguments)
        this.state = {
            detail: {}, //候选人信息
            state: 0, //当前状态
            visible: false,
            job: {}, // 职位信息
            interview: false,
            interviewId: 0,
            money: 0,//邀约面试扣款金额
            balance: 0, //余额
            link: 0, //第一环节奖金
            show: {
                type: 0,
                title: ''
            },
            from: 'first',
            addUpMoney: 0 //预扣累计
        }
    }

    componentWillMount() {
       //this.init()
        this.onDetails()
    }

    //替换指定传入参数的值,paramName为参数,replaceWith为新值
    replaceParamVal(paramName,replaceWith) {
        let oUrl = window.location.href.toString();
        let re=eval('/('+ paramName+'=)([^&]*)/gi');
        let nUrl = oUrl.replace(re,paramName+'='+replaceWith);
        window.location = nUrl;
        window.location.href=nUrl
    }

    async onDetails () {
        let id = getQueryVariable("id")
        let jobId = getQueryVariable("jobId")
        let status = getQueryVariable("status")
        const res1 = await jobDetail({data: jobId})
        const jobRes = await listByJobAndStatusAndLikeName({
            data: {jobId, name: "", status: parseInt(status)},
            limit: 10,
            start: 0
        })

        const cuurentObj = jobRes.data.filter(item => item.id == id)

        let obj = {
            job: res1.data,
            info: cuurentObj[0],
        }
        this.init(obj)
    }

    //余额
    async _remaining () {
        const res = await remaining();
        const { initMoney } = this.props
        initMoney(res.data)
    }

    init (res) {
      /*  let res = sessionStorage.getItem(SET_CSNDIDATE)
       res = JSON.parse(res) */
       const { info } = res 
       const { recommendReward,  recommendRewardRole} = res.job
         if (recommendReward && recommendRewardRole) {
             let price = JSON.parse(recommendRewardRole)
             let rewardMoney = info.status === 0 ? price[0] : info.status === 200 ? (!price[200] ? 0 :  price[200]) : info.stats === 400 ? price[400] : 0
             this.setState({money: !rewardMoney ? 0 : rewardMoney})
         }
         let m = localStorage.getItem('M')
         if (m) {
            this.setState({ balance: m })
         } else {
            this.setState({ balance: 0 })
         }

       this.onState(res.info)
       this.setState({ detail: res.info, job: res.job })
    }

    onState (detail) {
        if (detail.status === 0) return  this.setState({state: 0})
        if (detail.status === 200) return  this.setState({state: 1})
        if (detail.status === 300) return  this.setState({state: 2})
        if (detail.status === 400 && detail.finish) return  this.setState({state: 4})
        if (detail.status === 400) return  this.setState({state: 3})
    }

    getPdfHandle() {
        const { detail } = this.state
        const info = detail.candidateInfo 
        exportPDF(info);
    }

     //打开淘汰弹框
     showOut = () => {
        this.setState({
            visible: true,
          })
    }

     //取消淘汰弹框
     handleCancel = () => {
        this.setState({ visible: false })
    }

    onShowStateMsg () {
        const { status } = this.state.detail
        if (Number(status) === 0) return "筛选简历"
        if (Number(status) === 200) return "面试"
        if (Number(status) === 300)  return "offer"
        if (Number(status) === 400) return "入职"
    }

    //淘汰操作
    async onOut (values) {
        const { detail } = this.state
        values['id'] = detail.id
        let res = await eliminate(values)
        if (res.code  !== 0) {
            message.error(`操作${res.message}`)
        }
        this.handleCancel()
        this.onBack()
    }

    //打开进入接口offer阶段
    showOffer = () => {
        const { detail } = this.state
        let id = detail.id
        Modal.confirm({
            title: '接受offer',
            icon: <ExclamationCircleOutlined />,
            content: '该求职者将进入入职阶段，是否确定？',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                this.onTaskEntry(id)
             }
        })
    }

    //接受入职
    async onTaskEntry(id) {
        let res = await taskEntry({data: id})
        if (res.code !== 0) {
            message.error(`操作失败${res.message}`)
            return
        } else {
            message.success(`已${res.message}接受入职`)
            //此处为设置状态金额
            this._remaining()
            let timeout = setTimeout(() => {
                clearTimeout(timeout)
                timeout = null
                this.onBack()
            }, 1000)
        }
    }

    //返回
    onBack() {
       window.location.href = '/follow'
    }

    //获取年纪
    getAge(str) {
        if (!str)return
        return age(str)
    }

    onInterviewEnter (bool) {
        const { interviewId, show } = this.state;
        console.log(this.state.detail)
        if (this.state.detail === 0) {
            Modal.confirm({
                title: '邀约通过',
                icon: <ExclamationCircleOutlined />,
                content: '该求职者将进入面试阶段，是否确定？',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    this.onInterview(interviewId, bool)
                 }
            })

        } else if (this.state.detail === 400) {
            Modal.confirm({
                title: '已入职',
                icon: <ExclamationCircleOutlined />,
                content: '该求职者已经顺利入职，是否确定？',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                   this.onTaskFinish(interviewId, bool)
                 }
            })
        }
    }

    showModal = (id) => {
        this.setState({
            interview: true, 
            interviewId: id, 
            show: {
                type: 0,
                title: '发放内推奖金 & 邀约面试费'
            }
        })
    }

    async _taskBrowse(id) {
        const res = await taskBrowse({data: id})
        if (res.code !== 0) {
            return message.error(`${res.message}`)
        }
        return true
    }

    //打开进入面试弹框
    showInterview = (id) => {
        const { job } = this.state;
        this.setState({money: 0})
        Modal.confirm({
            title: '通过全部面试',
            icon: <ExclamationCircleOutlined />,
            content: '该求职者将进入入职报到阶段，是否确定？',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                this.onTaskEntry(id)
                }
            })
    }

    //打开进入接口offer阶段
    showOffer = (id) => {
        Modal.confirm({
            title: '接受offer',
            icon: <ExclamationCircleOutlined />,
            content: '该求职者将进入入职阶段，是否确定？',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                this.onTaskEntry(id)
             }
        })
    }

    //打开已入职弹框
    showAlreadyEntery = (id) => {
        const { job, detail } = this.state;
        this.setState({from: 'last'})
        console.log(detail)
        //如果没有推荐奖金，则直接二次弹框确认
         //此处为，已扣除预扣款项，直接提示二次确认弹框
        if (!detail.recommendReward || (detail.recommendReward && detail.recommendRewardRole && detail.withheld)) {
            this.setState({money: 0})
            Modal.confirm({
                title: '已入职',
                icon: <ExclamationCircleOutlined />,
                content: '该求职者已经顺利入职，是否确定？',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                this.onTaskFinish(id, false)
                }
            })
            return
        }
        //如何设置了推荐奖金，预扣为勾选，则二次预扣弹框提示
        if (detail.recommendReward && detail.recommendRewardRole && !detail.withheld) {
            //onOpenViewDailog()//再次显示预扣款弹框
            this.showModal(id)
            return
        }
    }

    //打开淘汰弹框
    showOut = () => {
        this.setState({ visible: true })
    }

    //取消淘汰弹框
    handleCancel = () => {
        this.setState({ visible: false })
    }

    //通过简历筛选
    async onInterview (id, bool) {
        let res = await browseAndInterview({data: {id: id, name: bool}})
        if (res.code !== 0) {
            message.error(res.message)
            return
        } else {
            message.success(res.message + '通过筛选简历!')
            //此处为设置状态金额
            this._remaining()
            let timeout = setTimeout(() => {
                clearTimeout(timeout)
                timeout = null
                this.replaceParamVal("status", "200")
                //this.onBack()
            }, 1000)
        }
    }

     // 完成入职
     async onTaskFinish (id, bool) {
        let res = await taskFinish({data: {id: id, name: bool}})
        if (res.code !== 0) {
            message.error(`操作失败${res.message}`)
            return
        } else {
            message.success(`已${res.message}完成入职`)
            //此处为设置状态金额
            this._remaining()
            let timeout = setTimeout(() => {
                clearTimeout(timeout)
                timeout = null
                this.replaceParamVal("status", "400")
            }, 1000)
        }
    }

    //通过面试
    async onTaskOffer(id) { //
        let res = await taskOffer({data: id})
        if (res.code !== 0) {
            message.error(`操作失败${res.message}`)
            return
        } else {
            message.success(`已${res.message}通过全部面试`)
            //此处为设置状态金额
            this._remaining()
            let timeout = setTimeout(() => {
                clearTimeout(timeout)
                timeout = null
                this.replaceParamVal("status", "400")
            }, 1000)
        }
    }

    //打开恢复至原阶段
    showRecover () {
        Modal.confirm({
            title: '恢复至原阶段',
            icon: <ExclamationCircleOutlined />,
            content: <div>是否将该求职者恢复至<span className="interview-state-font">面试</span>阶段</div>,
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                this.onRecover()
             },
          })
    }

    //确认恢复
    async onRecover () {
        const { detail } = this.state
         let res = await recover({data: detail.id })
        if (res.code  !== 0) {
            message.error(`操作${res.message}`)
        }
        this.handleCancel()
        this.onBack()
    }

    rePlaceMark (str) {
        if(!str)return null 
        return str.replace(/-/g, '年') + '月'
    }

    moneyBox () {
        const { job, detail, money, info} = this.state
        //debugger
        let money2 = 0
        let num = 0
        let obj = detail.recommendRewardRole 
        if (obj) {
            obj = JSON.parse(obj)
            if (obj[0]) {
                money2 += Number(obj[0] + num)
            }
            if (obj[200]) {
                money2 += Number(obj[200]  + num)
            }
            if (obj[300]) {
                money2 += Number(obj[300]  + num)
            }
            if (obj[400]) {
                money2 += Number(obj[400]  + num)
            }
        }
        return money2.toFixed(2)
    }

    async _delayReward (id) {
        const res = await delayReward(id)
        if (res.code !== 0) {
            return message.error(`${res.message}`)
        } else {
            this._remaining()
            let timeout = setTimeout(() => {
                clearTimeout(timeout)
                timeout = null
                let { parent, defaultTabKey } = this.props
                parent.onRefresh(defaultTabKey)
            }, 2000)
        }
    }

    onDelays (day, id) {
        day = 2
        if (day > 2) { //（1）如果剩余天数 > 2 天，则toast提示：“距离结束时间2天才可申请”
            return message.error(`距离结束时间2天才可申请`)
        } 
        if (day <= 2) { //（2）如果剩余天数 <= 2 天，则二次确认弹窗：“每笔奖金只能申请延长一次7天，确认吗？”
            Modal.confirm({
                title: '温馨提示!',
                icon: <ExclamationCircleOutlined />,
                content: '每笔奖金只能申请延长一次7天，确认吗？',
                okText: '确认',
                cancelText: '取消',
              })
        } 
    }

    render () {
        const { detail, state, job, interview, money, show } = this.state
        const info = detail.candidateInfo
        //debugger
        let buttons = ''
        if (this.state.detail.candidateInfo) {
            if (this.state.detail.status === 10000 && this.state.job.status !== 0) {
                buttons = (
                    <div>
                         <div className="mb10">淘汰时间：{detail.eliminateTime} </div>
                         <div className="mb10">淘汰阶段：{ stateMsg.get(detail.eliminateStatus) } </div>
                         <div className="mb10">淘汰原因：{detail.eliminateReason}</div>
                         {/* <Button type="primary" onClick={this.showRecover.bind(this)}>恢复至原阶段</Button> */}
                   </div>
                )
            }else if (this.state.state === 0 && this.state.job.status !== 0) {
                buttons = (
                    <div className='footer-list-btn'>
                        <Button onClick={this.showOut}>淘汰</Button>
                        <Button type="primary" className="down-btn saixuan-btn" onClick={this.showModal.bind(this, detail.id)}>邀约面试</Button>
                    </div>
                )
            } else if (this.state.state === 1 && this.state.job.status !== 0) {
                buttons = (
                    <div>
                        <div className='footer-list-btn'>
                            <Button onClick={this.showOut}>未通过面试</Button>
                            <Button type="primary" className="down-btn saixuan-btn"  onClick={this.showInterview.bind(this, detail.id)}>通过全部面试</Button>
                        </div>
                        {
                            info.withheld ? (
                                <div className='beleftBox' style={{marginLeft: '24px'}}>
                                    {
                                        timesStr(detail.createTime, detail.expirationTime).day < 0 ? <span>奖金已结算</span> : (
                                            <span>还剩<span>{timesStr(detail.createTime, detail.expirationTime).str}</span>自动结算</span>
                                        )
                                    }
                                    {
                                        timesStr(detail.createTime, detail.expirationTime).day < 0 ? null : (
                                            <span onClick={() => this.onDelays(timesStr(detail.createTime, detail.expirationTime).day, detail.id)} className='yanchangfont'>申请延长时间</span>
                                        )
                                    }
                                </div>
                            ) : null
                        }
                        
                    </div>
                )
            } else if (this.state.state === 2 && this.state.job.status !== 0) {
                buttons = (
                    <div>
                        <div className='footer-list-btn'>
                            <Button onClick={this.showOut}>拒绝/不offer</Button>
                            <Button type="primary" className="down-btn saixuan-btn" onClick={this.showOffer.bind(this, detail.id)}>接受offer</Button>
                        </div>
                        {
                            info.withheld ? (
                                <div className='beleftBox' style={{marginLeft: '24px'}}>
                                    {
                                        timesStr(detail.createTime, detail.expirationTime).day < 0 ? <span>奖金已结算</span> : (
                                            <span>还剩<span>{timesStr(detail.createTime, detail.expirationTime).str}</span>自动结算</span>
                                        )
                                    }
                                    {
                                        timesStr(detail.createTime, detail.expirationTime).day < 0 ? null : (
                                            <span onClick={() => this.onDelays(timesStr(detail.createTime, detail.expirationTime).day, detail.id)} className='yanchangfont'>申请延长时间</span>
                                        )
                                    }
                                </div>
                            ) : null
                        }
                    </div>
                )
            } else if (this.state.state === 3 && !this.state.detail.finish && this.state.job.status !== 0) {
                buttons = (
                    <div>
                        <div className='footer-list-btn'>
                            <Button onClick={this.showOut}>未入职</Button>
                            <Button className='saixuan-btn'  onClick={this.showAlreadyEntery.bind(this, detail.id)}>已报到上岗</Button>
                        </div>
                        {
                            info.withheld ? (
                                <div className='beleftBox' style={{marginLeft: '24px'}}>
                                    {
                                        timesStr(detail.createTime, detail.expirationTime).day < 0 ? <span>奖金已结算</span> : (
                                            <span>还剩<span>{timesStr(detail.createTime, detail.expirationTime).str}</span>自动结算</span>
                                        )
                                    }
                                    {
                                        timesStr(detail.createTime, detail.expirationTime).day < 0 ? null : (
                                            <span onClick={() => this.onDelays(timesStr(detail.createTime, detail.expirationTime).day, detail.id)} className='yanchangfont'>申请延长时间</span>
                                        )
                                    }
                                </div>
                            ) : null 
                        }
                        
                    </div>
                )
            }
        }
        

        return (
            <div>
                {
                    !this.state.detail.candidateInfo ? <div>
                        <div className="spinner">
                            <div className="rect1"></div>
                            <div className="rect2"></div>
                            <div className="rect3"></div>
                            <div className="rect4"></div>
                            <div className="rect5"></div>
                        </div>
                    </div> : (
                        <div className="candidate-flex">
                <div className="left-box" id="pdfCentent">
                    <div className="can-item flex-item  align-items">
                        <div className="flex-item align-items">
                            <Image className="avatar" src={!info.imgUrl ? defPic : info.imgUrl} />
                            <div>
                                <div className="name">{info.name}</div>
                                <div>
                                    <span>{this.getAge(info.birth)}</span>
                                    <label className="line-bar">|</label>
                                    <span>{info.gender === 1 ? "男" : "女"}</span>
                                    <label className="line-bar">|</label>
                                    <span>{Number(info.startWork)}年工作经验</span>
                                </div>
                            </div>
                        </div>
                        <div className="contact-box">
                            <div>手机：{info.phone}</div>
                            <div>邮箱：{info.email}</div>
                        </div>
                    </div>
                    <div className="content-box">
                        <div className="section-item">
                            <div className="mark-box">个人优势</div>
                            <div className="good-like-box">{info.advantage}</div>
                        </div>
                        <div className="section-item">
                            <div className="mark-box">工作经历</div>
                            {
                                !info.businesses ? null : info.businesses.map((item, index) => {
                                    return (
                                        <div className="work-box" key={index}>
                                            <div>
                                                <span className="compan">{item.company}</span>
                                                <span className="job">{item.jobTitle}</span>
                                                <span>
                                                    <span>{this.rePlaceMark(item.beginDate)} 至 {!item.endDate ? "至今" : this.rePlaceMark(item.endDate)}</span>
                                                </span>
                                            </div>
                                            <div className="work-des">
                                                 {item.description}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="section-item">
                            <div className="mark-box">教育经历</div>
                            {
                                !info.educations ? null : info.educations.map((item, index) => {
                                    return (
                                        <div className="work-box" key={index}>
                                            <div>
                                                <span className="compan">{item.school}</span>
                                                <span className="job">{item.profession}</span>
                                                <span className="edt-box">{item.education}</span>
                                                <span>
                                                    <span>{this.rePlaceMark(item.beginDate)} 至 {!item.endDate ? "至今" : this.rePlaceMark(item.endDate)}</span>
                                                </span>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="right-box">
                   <div className="header-list-box">
                        <Button type="primary" className="down-btn" onClick={this.getPdfHandle.bind(this)}>下载简历</Button>
                        <Button onClick={this.onBack.bind(this)}>返回</Button>
                   </div>
                   <div className="content-box">
                        {
                            !detail.talentName ? null : 
                            (
                                <div className="push-info-box flex-item align-items">
                                    <div>推荐人</div>
                                    <div className="flex-item align-items">
                                        <Image className="avatar midel-avatar" src={!detail.talentImg ? defPic : detail.talentImg} />
                                        <span>{detail.talentName}</span>
                                    </div>
                                </div>
                            )
                        }
                         <div>
                             {
                                 detail.status === 10000 && job.status !== 0 ? null : (
                                    <Steps size="small" current={state}>
                                        <Steps.Step title="筛选简历"/>
                                        <Steps.Step title="面试" />
                                        <Steps.Step title="入职" /> 
                                    </Steps>
                                 )
                             }
                             
                         </div>
                         {
                             buttons
                         }
                   </div>
                </div>


                <Modal
                    visible={this.state.visible}
                    footer={null}
                    closable={false}
                    width="400px"
                    onCancel={this.handleCancel}
                    >
                  <Form 
                   onFinish={this.onOut.bind(this)}>
                    <div className='out-box-header'>
                            <span className='mark-out'>
                                <ExclamationCircleOutlined style={{ fontSize: '18px', color: '#febe2d' }}  />
                            </span>
                            <span className='out-title-box'>淘汰候选人</span>
                        </div>
                        <div className="out-state-box">
                            淘汰阶段：{this.onShowStateMsg()}
                        </div>
                        <div className="out-reason-box">
                            <Form.Item 
                                    label="淘汰原因"
                                    name="name" 
                                    rules={[{ required: true, message: '请输入淘汰原因!' }]}>
                                    <Input />
                            </Form.Item>
                        </div>
                        <div className="out-reason-btns">
                            <Button type='primary' className='sub-btn'  htmlType="submit">提交</Button>
                            <Button onClick={this.handleCancel.bind(this)}>取消</Button>
                        </div>
                  </Form>
               </Modal>

               {
                interview ? (
                    <CashierWidget 
                        show={interview} 
                        from={this.state.from}
                        data={this.state.detail}
                        balance={this.state.balance}//
                        addUp={()=> this.moneyBox()}
                        onCancel={() => this.setState({interview: false, interviewId: 0 })} 
                        onSubmit={(e) => this.onInterviewEnter(e)}
                    />
                ) : null
             }
            </div>
                    )
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { state: state};
}

function mapDispatchToProps(dispatch) {
   return {
       initMoney: moeny => dispatch(initAccount(moeny))
   }
}
const App =  connect(
   mapStateToProps,
   mapDispatchToProps
)(Candidate)

export default App