import React, { Component } from 'react'
import './contant.less'

import pic1 from './img/images@2x.png'
import pic2 from './img/qrcode_for_gh_6a29a0e04a67_258@2x.png'

class Contact extends Component {


    render() {
       
        return (
            <div className="app">
                <div className="flex-box header-box">
                    <img alt="" className="logo" src="https://img.fanwoon.com/new_logo.png" />
                    <ul className="menu">
                        <li><a href="/index">首页</a></li>
                        <li><a href="/jobList">热招职位</a></li>
                        <li><a href="/contant">联系我们</a></li>
                        <li><a href="/templates">下载简历模板</a></li>
                        <li><a href="/login">登录注册</a></li>
                    </ul>
                </div>

                <div>
                    <img alt="" className="banner-pic" src={pic1} />
                </div>

                <div className="section-box">
                    <div className="left">翻腕隶属于上海往矣科技有限公司，成立于2020年12月，总部坐落于上海。公司利用人工智能技术，通过社群运营等创新方式为各行业客户提供全周期招聘服务，覆盖房产中介、零售店员、导购、快递员、外卖派送员、美甲师、餐饮服务员、厨师等职能，合作门店超过1000家。</div>
                    <div className="right">
                        <img alt=""src={pic2} />
                    </div>
                </div>

                <div className="footer-box">
                    <span>Copyright © 2022 翻腕</span>
                    <a href='https://beian.miit.gov.cn/'>沪ICP备2020029704号-1</a>
                    <span>
                        <img alt="" className="beian-icon" src="https://img.fanwoon.com/police.png" /><a target="_blank" className="normal-a" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011302007180">沪公网安备 31011302007180号</a>
                    </span>
                </div>
            </div>
        )
    }
}

export default Contact