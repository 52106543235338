import React, { Component } from 'react'
import { InputNumber, Button, Modal, Image, message } from "antd";
import { orderCreate, orderPay, transactionOrderInfo } from '../../api'
import { getQueryString, storage, debounce } from '../../utils'
import QRCode from 'qrcode-react';

import './index.less';

class Recharge extends Component {
    constructor () {
        super()
        this.state ={
            rechargeModal: false,
            timer: 10,
            rechargeMoney: undefined,
            qrUrl: '',
            show: false,
            isOne: false,
        }
    }

    componentWillMount(){
        this.handleTimes()
        let id = getQueryString('id');
        let qr = storage.get(id)
        if (qr) {
            qr = JSON.parse(qr)
            this.setState({ qrUrl: qr.data })
        }else if(id) {
             this.onGetCode(id)
        } else {
            this.setState({qrUrl: ''})
        }
    }

    //充值信息
    async _transactionOrderInfo (id) {
        const res = await transactionOrderInfo({data: id})
        const { orderState } = res.data 
        if (orderState === 1000) {
            message.error("充值成功")
            this.setState({ qrUrl: "", show: false })
            return true
        }
        return false
    }

    async _orderCreate (money) {
        let urs = window.sessionStorage.getItem('SET_USER')
        urs = JSON.parse(urs)

        const res = await orderCreate({data: { id: urs.id, name: money }})
        const { id } = res.data
        this.onGetCode(id)

        let timers = setInterval(() => {
            clearInterval(timers)
            timers = null 
            this._transactionOrderInfo(id)
        }, 2000)
    }

    handleTimes () {
       // let { visible } = this.state 
        let siv = window.setInterval(() => {
            this.setState({
                timer: (this.state.timer -1)
            }, () => {
                if (this.state.timer === 0) {
                    window.clearInterval(siv)
                    siv = null
                    this.setState({timer: 10})
                }
            })
        }, 1000)
    }

    onToggleAgree () {
        const { rechargeModal } = this.state
        this.setState({ rechargeModal: !rechargeModal })
    }

    onAgree () {
        this.setState({ rechargeModal: false, isOne: true })
    }

    /**
     * 生成付款码地址
     * @param {*} id 
     */
    async onGetCode (id) {
        let getUserInfo = window.sessionStorage.getItem('SET_USER')
        getUserInfo = JSON.parse(getUserInfo);

       // const res = await orderPay({ data: { id: id,name: 'NATIVE'} });
       const res = await orderPay({ 
        transactionId: id, payerId: getUserInfo.openId, payType: 1,  tradeType: 'NATIVE'});
        /**
         * 
         */
        storage.set(id, res, 60)
        this.setState({ qrUrl: res.data, show: true })
    }

    onChangeMoney = debounce((val) => {
        this.setState({rechargeMoney: val})
        if (!val)return
        let min = Number(12)
            let max = Number(10000)
            if (Number(!val ? 0  : val) <  min || Number(!val ? 0 : val) > max) {
                return message.error("单笔限额12-10000")
            }
    }, 1000)

    //确认充值操作.
    onRecharge () {
      
        const key = 'updatable'
        message.loading({ content: '正在处理...', key , duration: 1 });
        let timers = setTimeout(() => {
            clearTimeout(timers)
            timers = null 
            const { rechargeMoney, isOne } = this.state;
           
            if (!rechargeMoney) {
                return message.error("充值金额不能为空")
            }
            
            if (!isOne) {
                this.setState({ rechargeModal: true }) //打开交易说明
                return 
            }
            let rm = Number(rechargeMoney)
            let min = Number(12)
            let max = Number(10000)
            if (rm <  min || rm > max) {
                return message.error("单笔限额12-10000")
            }
            this._orderCreate(rechargeMoney)
        }, 1500)
        
       
       // 
    }
    
    onBack () {
        //this.props.history.push('/amount')
        window.history.back();
    }

    render () {
        const { rechargeModal, qrUrl, show, rechargeMoney } = this.state;

        return (
            <div className="recharge-box">
                <div className='recharge-header'>账户充值</div>
                <div>
                    <label>充值金额：</label>
                    <InputNumber onChange={this.onChangeMoney.bind(this)} className='wecha-money-input' placeholder="12-10000" min={12} max={10000} />
                </div>
                <div className='money-label'>单笔充值限额：12-10000</div>
                <div className='mt20'>
                    <label>充值方式：</label>
                    <img className='wecha-pay' src='https://img.fanwoon.com/wecha_pay.png' title='' alt='' />
                </div>
                
                <div className='agreement-font-box'>
                    <span className='agree-box'>请阅读并同意:</span>
                    <span className='agreements-font' onClick={this.onToggleAgree.bind(this)}>《翻腕充值交易说明条款》</span>
                </div>
                <div className='recharge-btn-box'>
                    <span className="cursors" onClick={this.onBack.bind(this)}>返回</span>
                    <Button className='recharge-btns' onClick={ this.onRecharge.bind(this) }>确认充值</Button>
                </div>
                
                <Modal 
                    visible={rechargeModal} 
                    footer={null}
                    onCancel={ this.onToggleAgree.bind(this) } 
                    maskClosable={false}
                    closable={false}
                    >
                    <div className="recharge-info">
                        <div className="header">
                            <Image className="notice-icon" src="https://img.fanwoon.com/redtishi%402x.png" />
                            <span>翻腕充值交易说明条款</span>
                        </div>
                        <ul className="recharge-content">
                            <li>1. 充值的目的为了享受翻腕平台提供的服务，是用户的自愿行为；</li>
                            <li>2. 您确保充值的金额来源正当、合理、合法，如违反中华人民共和国法律法规，由用户自行承担风险及损失，且本平台有权根据国家的相关规定及本平台的服务协议立即采取下列一个或多个措施:立即停止提供服务;对相关信息予以下线、删除或要求整改;封禁用户账号等。对于用户已支付的服务费用，本平台有权不予退还；</li>
                            <li>3. 充值后，您务必妥善保管账号信息，不得提供给任何第三方使用或从事任何可能使用户名、密码存在泄露危险的行为（包括但不限于在第三方平台授权代理或登录），因此所导致的账户余额被恶意使用或衍生的任何损害，本平台不承担任何责任；</li>
                            <li>4. 充值金额后，您可以随时申请退款，退款将会以银行转账的方式，退回到您申请退款时填写的银行账户中，退款的周期为申请退款当天至30个工作日内完成，最终以银行的实际到账时间为准；</li>
                            <li>5. 充值的金额用于发放给推荐者的内推奖金、平台面试邀约服务费等费用；</li>
                            <li>6. 通过平台进行招聘等服务，且涉及到扣款时，平台会提示您进行确认，一旦您确认扣款，将无法退款，如果因为您的误操作等行为导致的扣款，本平台不承担任何责任；</li>
                        </ul>
                        <div className="btn-box">
                            { 
                                this.state.timer!== 10 && !this.state.isOne  ? <Button className="btn-default" disabled>我已阅读并同意上述内容({this.state.timer}s)</Button> : 
                                <Button onClick={this.onAgree.bind(this)} className="btn-agree">我已阅读并同意上述内容</Button>
                            }
                        </div>
                    </div>
                </Modal>
                <Modal visible={show} footer={null} width="300px" closable={false}>
                    <div className='finish-title'>请用微信扫描下方二维码付款</div>
                    <div>
                        <div>
                            <label className="pay-font">付款方式：</label>
                            <img className='wecha-pay' src='https://img.fanwoon.com/wecha_pay.png' title='' alt='' />
                        </div>
                        <div className="wecha-box">
                            {
                                qrUrl ? (
                                    <QRCode 
                                        size={150}
                                        value={qrUrl}
                                        logo={`https://img.fanwoon.com/new_logo.png`}
                                        logoWidth={50}
                                        logoHeight={50}
                                    />
                                ) : null
                            }
                        </div>
                    </div>
                    <div className="wecha-footer-box">
                        <span className='no-finish-font' onClick={this.onBack.bind(this)}>未完成付款</span>
                        <Button className='finish-btns'  onClick={this.onBack.bind(this)}>已完成付款</Button>
                    </div>
                </Modal>
                {/* <Modal visible={show} footer={null} width="240px" closable={false}>
                    <div className='finish-title'>请于60分钟内完成付款</div>
                    <span className='no-finish-font' onClick={this.onBack.bind(this)}>未完成付款</span>
                    <Button className='finish-btns'  onClick={this.onBack.bind(this)}>已完成付款</Button>
                </Modal> */}
            </div>
        )
    }
}

export default Recharge;